import React, { useEffect, useState } from "react";
import { CO2PlanPerson, CO2PropertyDescription, MergePersons } from "../CO2Plan";
import {
  Grid,
  TextField,
  Typography,
  InputAdornment,
  Zoom,
  SelectChangeEvent,
  styled,
  Alert,
  Button,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { HighlightOff, AddCircle, Merge, PersonAdd } from "@mui/icons-material";
import { Trans, useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import CircularProgressButton from "../../../../components/Base/CircularProgressButton";
import AddPersonToIgnoreListDialog from "./AddPersonToIgnoreListDialog";
import MergeUsersSelect from "./MergeUserSelect";
import { TextFieldConnector } from "../../../../components/Base/FormConnector";
import { ClimatPlanFamily, ClimatPlanUnit, ClimatPlanUser } from "../ClimatePlanPage";

const MemberCandidate = styled(TextField)<{ glowing?: boolean }>(({ theme, glowing }) => ({
  "& .MuiInputBase-input": {
    color: theme.palette.secondary.dark,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.secondary.main,
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.secondary.main,
    borderWidth: 2,
  },
  animation: glowing ? "glow 1s ease-in-out infinite alternate" : "none",
  "@-webkit-keyframes glow": {
    from: {
      boxShadow: "0 0 5px " + theme.palette.secondary.dark,
    },
    to: {
      backgroundColor: theme.palette.secondary.main,
      boxShadow: "0 0 20px " + theme.palette.secondary.light,
      color: theme.palette.secondary.dark,
    },
  },
}));

const MemberBirthYear = styled(InputAdornment)(({ theme }) => ({
  marginRight: -14,
  minWidth: "50%",
  "& .MuiFormLabel-root": {
    background: theme.palette.background.paper,
  },
}));

const StyledButton = styled(Button)(() => ({
  minWidth: 270,
  margin: "20px 5px 0",
}));

type EditPersonsProps = {
  existingPersons: CO2PlanPerson[];
  planPersonsToIgnore: string[];
  isSinglePersonPlan: boolean;
  user: ClimatPlanUser | null;
  showPropertyDescription?: boolean;
  unit: ClimatPlanUnit | null;
  family: ClimatPlanFamily | null;
  children?: React.ReactNode;
  forceUserToMember?: boolean;
  handleSave: (
    personNamesToAdd: CO2PlanPerson[],
    personIdsToDelete: string[],
    personsToRename: CO2PlanPerson[],
    isSinglePersonPlan: boolean,
    planPersonToIgnore: string[],
    personTomerge: MergePersons[],
    propertyDescription?: CO2PropertyDescription
  ) => Promise<void>;
  ignorePlanPersons?: boolean;
};

export type EditablePerson = CO2PlanPerson & {
  IsChanged: boolean;
  IsNew: boolean;
  IsRemoved: boolean;
};

type EditPersonsState = {
  persons: EditablePerson[];
  planPersonToIgnore: string[];
  personsToMerge: MergePersons[];
  newFamilyPersons: EditablePerson[];
  Area: number | null;
  hasAreaError: boolean;
  noPersonsError: boolean;
  showYearError: boolean;
  showNameError: boolean;
  year: number;
  minYear: number;
};

const EditPersons = (props: EditPersonsProps): JSX.Element => {
  const initialState: EditPersonsState = {
    persons: [],
    planPersonToIgnore: [],
    personsToMerge: [],
    newFamilyPersons: [],
    Area: props.unit?.UnitArea ?? null,
    hasAreaError: false,
    noPersonsError: false,
    showYearError: false,
    showNameError: false,
    year: new Date().getFullYear(),
    minYear: new Date().getFullYear() - 120,
  };
  const [addMePerson, setAddMePerson] = useState<EditablePerson | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeletePersonDialogOpen, setIsDeletePersonDialogOpen] = useState(false);
  const [newFamilyPersonsToDelete, setNewFamilyPersonsToDelete] = useState<string[]>([]);
  const { t } = useTranslation("translation");

  const methods = useForm<EditPersonsState>({
    defaultValues: initialState,
  });
  const {
    register,
    unregister,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
    setError,
    clearErrors,
    watch,
    setValue,
  } = methods;

  useEffect(() => {
    const mappedPersons = props.existingPersons.map((person) => {
      return { ...person, IsChanged: false, IsNew: false, IsRemoved: false };
    });
    if (mappedPersons.length === 0) {
      mappedPersons.push({
        Id: props.user?.Id ?? uuidv4(), // some unique value
        Name: props.user?.Name ?? "Person 1",
        IsChanged: false,
        IsNew: true,
        IsRemoved: false,
      });
    }
    const membersCandidates: Array<EditablePerson> = [];
    if (props.family) {
      props.family.Members.forEach((m) => {
        if (
          !mappedPersons.find((p) => p.Id === m.UserId) &&
          !membersCandidates.find((p) => p.Id === m.UserId) &&
          (!props.planPersonsToIgnore || !props.planPersonsToIgnore.find((ignorPersonId) => ignorPersonId === m.UserId))
        )
          membersCandidates.push({
            Id: m.UserId,
            Name: m.Name,
            IsChanged: false,
            IsNew: true,
            IsRemoved: false,
          });
      });
    }

    if (
      props.forceUserToMember &&
      props.user &&
      !mappedPersons.find((p) => p.Id === props.user?.Id) &&
      !membersCandidates.find((p) => p.Id === props.user?.Id) &&
      (!props.planPersonsToIgnore || !props.planPersonsToIgnore.find((ignorPersonId) => ignorPersonId === props.user?.Id))
    ) {
      membersCandidates.push({
        Id: props.user.Id,
        Name: props.user.Name,
        IsChanged: false,
        IsNew: true,
        IsRemoved: false,
      });
    }

    reset({
      ...initialState,
      persons: mappedPersons,
      newFamilyPersons: membersCandidates,
    });
  }, [props.existingPersons, props.family]);

  useEffect(() => {
    register("persons", { required: true });
    register("newFamilyPersons");

    return () => {
      unregister("persons");
      unregister("newFamilyPersons");
    };
  }, []);

  useEffect(() => {
    setValue("planPersonToIgnore", props.planPersonsToIgnore);
  }, [props.planPersonsToIgnore]);

  useEffect(() => {
    if (props.user && !props.isSinglePersonPlan) {
      const isInPlan = getValues().persons.filter((p) => p.Id === props.user?.Id).length > 0;

      const addMePersonItem = {
        Id: props.user.Id,
        Name: props.user.Name,
        IsChanged: false,
        IsNew: true,
        IsRemoved: false,
      };
      setAddMePerson(isInPlan ? null : addMePersonItem);
    }
  }, [props.user]);

  const handleAddFamilyPerson = (newPerson: EditablePerson) => {
    clearErrors("noPersonsError");
    setValue("persons", [...getValues().persons, newPerson]);
    setValue(
      "newFamilyPersons",
      getValues().newFamilyPersons.filter((p) => p.Id !== newPerson.Id)
    );

    if (newPerson.Id == addMePerson?.Id) setAddMePerson(null);
  };

  const addMeToPlan = () => {
    if (addMePerson !== null) {
      setValue("persons", [...getValues().persons, addMePerson]);

      setValue(
        "newFamilyPersons",
        getValues().newFamilyPersons.filter((p) => p.Id !== addMePerson?.Id)
      );

      setAddMePerson(null);
    }
  };

  const handleAddPerson = () => {
    clearErrors("noPersonsError");

    const newPerson: EditablePerson = {
      Id: uuidv4(), // some unique value
      Name: "",
      // `Person ${
      //   getValues().persons.filter((p) => !p.IsRemoved).length + 1
      // }`,
      IsChanged: false,
      IsNew: true,
      IsRemoved: false,
    };
    setValue("persons", [...getValues().persons, newPerson]);
  };

  const addPersonToIgnoreListHandler = (person: EditablePerson) => {
    setIsDeletePersonDialogOpen(true);
    setNewFamilyPersonsToDelete(() => {
      return [...props.planPersonsToIgnore, person.Id];
    });
  };

  const deleteDialogHandler = (confirmed: boolean | undefined) => {
    if (confirmed) {
      setValue("newFamilyPersons", [
        ...getValues().newFamilyPersons.filter(
          (newFamilyPerson) => !newFamilyPersonsToDelete.find((person) => person === newFamilyPerson.Id)
        ),
      ]);
      setValue("planPersonToIgnore", [...getValues().planPersonToIgnore, ...newFamilyPersonsToDelete]);
      setIsDeletePersonDialogOpen(false);
    } else {
      setIsDeletePersonDialogOpen(false);
    }
  };

  const saveHandler = ({ persons, Area, planPersonToIgnore, personsToMerge, year, minYear }: EditPersonsState) => {
    const isInPlan = persons.filter((p) => p.Id === props.user?.Id).length > 0;
    if (props.forceUserToMember && !isInPlan) return;

    const personsToAdd: CO2PlanPerson[] = persons
      .filter((person) => person.IsNew && !person.IsRemoved)
      .map((person) => person);

    const personsToRename: CO2PlanPerson[] = persons.filter(
      (person) => person.IsChanged && !person.IsNew && !person.IsRemoved
    );
    const personIdsToDelete: string[] = persons
      .filter((person) => person.IsRemoved && !person.IsNew)
      .map((person) => person.Id);

    if (props.showPropertyDescription) {
      if (Area == null || Area <= 0 || Area > 10000) {
        return;
      }
    }

    if (persons.filter((p) => !p.IsRemoved).length === 0) {
      setError("noPersonsError", {});
      return;
    }

    if (
      persons.filter((p) => !p.IsRemoved && (!p.BirthYear || p.BirthYear > year || p.BirthYear < minYear)).length > 0 &&
      !props.ignorePlanPersons
    ) {
      setError("showYearError", {});
      return;
    }

    if (persons.filter((p) => !p.IsRemoved && !p.Name).length > 0) {
      setError("showNameError", {});
      return;
    }

    setIsLoading(true);
    props
      .handleSave(
        personsToAdd,
        personIdsToDelete,
        personsToRename,
        props.isSinglePersonPlan,
        planPersonToIgnore,
        personsToMerge,
        {
          Area: Area ?? 0,
          Bathrooms: 0,
          EnergyLabel: "",
          Rooms: 0,
          Toilets: 0,
        }
      )
      .finally(() => setIsLoading(false));
  };

  const mergeUsers = (e: SelectChangeEvent<string>, personId: string | undefined) => {
    if (personId === props.user?.Id) {
      setAddMePerson(null);
    }
    if (personId) {
      setValue("personsToMerge", [
        ...getValues().personsToMerge,
        {
          CurrentPersonId: e.target.value,
          NewPersonId: personId,
        },
      ]);
      setValue("newFamilyPersons", [...getValues().newFamilyPersons.filter((person) => person.Id !== personId)]);
    }
  };

  return (
    <Grid>
      <form onSubmit={handleSubmit(saveHandler)}>
        <Grid container direction="column" spacing={1}>
          {props.showPropertyDescription && (
            <Grid item container direction="column">
              <Grid item>
                <Typography align="center" variant="body1" gutterBottom>
                  {t("ClimatePlan.EditPersonsLabel1") ?? ""}
                </Typography>
                <TextFieldConnector
                  register={register("Area", {
                    required: true,
                    min: 1,
                    max: 1000,
                  })}
                >
                  <TextField
                    variant="outlined"
                    fullWidth
                    placeholder={t("ClimatePlan.LivingArea") ?? ""}
                    type="number"
                    error={!!errors.Area}
                  ></TextField>
                </TextFieldConnector>
                {/* TODO: show other property description fields
                TODO: show unit autocomplete to copy fields from a real unit */}
              </Grid>
            </Grid>
          )}
          <Grid item>
            {props.children && (
              <>
                {props.children}
                <br />
              </>
            )}
            <Typography align="center" variant="body1" paragraph>
              {t("ClimatePlan.EditPersonsLabel2") ?? ""}
            </Typography>
            <Grid item container direction="column" spacing={1}>
              {getValues()
                .persons.filter((person) => !person.IsRemoved)
                .map((person) => (
                  <Grid container my={1} item direction="row" key={person.Id} alignItems="flex-end">
                    <TextField
                      variant="outlined"
                      name="persons"
                      label={t("ClimatePlan.General.Name") ?? ""}
                      error={!!errors.showNameError && !person.Name}
                      fullWidth
                      value={watch().persons.find((item) => item.Id === person.Id)?.Name}
                      onChange={(e) => {
                        const { persons } = getValues();
                        clearErrors("showNameError");
                        const editedPersons = persons.map((item) => {
                          if (item.Id === person.Id) {
                            return {
                              ...item,
                              Name: e.target.value,
                              IsChanged: true,
                            };
                          } else {
                            return item;
                          }
                        });
                        setValue("persons", editedPersons);
                      }}
                      InputProps={{
                        endAdornment: !props.ignorePlanPersons && (
                          <MemberBirthYear position="end">
                            <TextField
                              variant="outlined"
                              name="persons"
                              required
                              value={watch().persons.find((item) => item.Id === person.Id)?.BirthYear}
                              type="number"
                              label={t("ClimatePlan.YearOfBirth") ?? ""}
                              fullWidth
                              error={
                                errors.showYearError &&
                                (!person.BirthYear ||
                                  person.BirthYear > getValues().year ||
                                  person.BirthYear < getValues().minYear)
                              }
                              onChange={(e) => {
                                const { persons } = getValues();
                                const editedPersons = persons.map((item) => {
                                  if (item.Id === person.Id) {
                                    return {
                                      ...item,
                                      BirthYear: parseInt(e.target.value),
                                      IsChanged: true,
                                    };
                                  } else {
                                    return item;
                                  }
                                });
                                setValue("persons", editedPersons);
                              }}
                              InputProps={{
                                sx: {
                                  borderTopLeftRadius: 0,
                                  borderBottomLeftRadius: 0,
                                },
                                inputProps: {
                                  min: getValues().minYear,
                                  max: getValues().year,
                                },
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {(!props.forceUserToMember ||
                                      (props.forceUserToMember && props.user?.Id != person.Id)) && (
                                      <HighlightOff
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          const { persons } = getValues();
                                          const editedPersons = persons.map((item) => {
                                            if (item.Id === person.Id) {
                                              return {
                                                ...item,
                                                IsRemoved: true,
                                              };
                                            } else {
                                              return item;
                                            }
                                          });
                                          setValue("persons", editedPersons);
                                        }}
                                      ></HighlightOff>
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                            ></TextField>
                          </MemberBirthYear>
                        ),
                      }}
                    />
                  </Grid>
                ))}
              {errors.noPersonsError && (
                <Zoom in={Boolean(errors.noPersonsError)}>
                  <Grid item container justifyContent="center">
                    <Typography color="error">{t("ClimatePlan.MustBeAtLeastOneMember") ?? ""}</Typography>
                  </Grid>
                </Zoom>
              )}
              {getValues().newFamilyPersons.length > 0 && (
                <>
                  <Typography align="center" variant="body1" style={{ marginTop: 10 }}>
                    {t("ClimatePlan.EditPersonsNewFamilyMembers") ?? ""}
                  </Typography>
                  {props.forceUserToMember && addMePerson && (
                    <Alert severity="error" sx={{ marginLeft: "10px" }}>
                      <Typography align="center" variant="caption" style={{ marginTop: 10 }}>
                        <Trans
                          i18nKey="ClimatePlan.EditPersonsForceText"
                          components={{
                            addIcon: <AddCircle fontSize="small" color="secondary" />,
                            mergeIcon: <Merge fontSize="small" />,
                          }}
                          values={{
                            userName: addMePerson.Name,
                          }}
                        />
                      </Typography>
                    </Alert>
                  )}
                </>
              )}
              {getValues().newFamilyPersons.map((person) => (
                <Grid container item direction="row" key={person.Id} alignItems="flex-end">
                  <MemberCandidate
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    glowing={props.forceUserToMember && addMePerson?.Id === person.Id}
                    defaultValue={person.Name}
                    onChange={(e) => (person.Name = e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <MergeUsersSelect user={props.user} persons={getValues().persons} mergeHandler={mergeUsers} />
                          <AddCircle
                            style={{ cursor: "pointer" }}
                            color="secondary"
                            onClick={() => handleAddFamilyPerson(person)}
                          ></AddCircle>
                          <HighlightOff
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              addPersonToIgnoreListHandler(person);
                            }}
                          ></HighlightOff>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <AddPersonToIgnoreListDialog open={isDeletePersonDialogOpen} handleClose={deleteDialogHandler} />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item container justifyContent="center">
            <Grid item>
              {addMePerson && (
                <StyledButton
                  variant="outlined"
                  color="secondary"
                  style={{ marginTop: 10 }}
                  onClick={addMeToPlan}
                  startIcon={<PersonAdd color="secondary" />}
                >
                  {t("ClimatePlan.AddMeToMembers") ?? ""}
                  <MergeUsersSelect
                    user={props.user}
                    persons={getValues().persons}
                    mergeHandler={mergeUsers}
                    inButton
                  />
                </StyledButton>
              )}
            </Grid>
            {!props.ignorePlanPersons && (
              <Grid item container justifyContent="center">
                <StyledButton
                  variant="outlined"
                  color="secondary"
                  style={{ marginTop: 10 }}
                  onClick={() => handleAddPerson()}
                >
                  <AddCircle style={{ marginRight: 5 }} />
                  {t("ClimatePlan.EditPersonsAddButton") ?? ""}
                </StyledButton>
              </Grid>
            )}
          </Grid>
          <Grid item container justifyContent="center">
            <CircularProgressButton
              sx={{ minWidth: 270, margin: "20px 5px 20px" }}
              color="info"
              size="large"
              type="submit"
              inProgress={isLoading}
            >
              {t("ClimatePlan.EditPersonsNextButton") ?? ""}
            </CircularProgressButton>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default EditPersons;
