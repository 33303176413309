import React from "react";
import { Container } from "@mui/material";
import BaseDialog from "../BaseDialog";
import { AuthDialogChildrenProps } from "../AuthDialog";
import ForgotPasswordForm from "./ForgotPasswordForm";
import { useTranslation } from "react-i18next";

const ForgotPasswordDialog = ({
  state,
  open,
  closeDialog,
  setStep,
}: AuthDialogChildrenProps) => {
  const { t } = useTranslation("translation");
  return (
    <BaseDialog
      titleComponent={t("Base.Dialog.ForgotPasswordTitle")}
      open={open}
      closeDialog={closeDialog}
    >
      <Container maxWidth="xs">
        <ForgotPasswordForm setStep={setStep} state={state} />
      </Container>
    </BaseDialog>
  );
};

export default ForgotPasswordDialog;
