import React, { SetStateAction, Dispatch, useState, useEffect } from "react";

import LoginDialog from "./LoginDialog/LoginDialog";
import RegisterDialog from "./RegisterDialog/RegisterDialog";
import ForgotPasswordDialog from "./ForgotPasswordDialog/ForgotPasswordDialog";
import AuthModel from "../../definitions/model/Auth";
import User from "../../definitions/model/User";
import { useUser } from "../../actions";
import { RegisterUserContract } from "../../definitions/models";
import { LanguageType } from "../../definitions/Menu";

export const StepArray = ["register", "registerPhone", "login", "forgotPassword", "initial"] as const;
export type Step = typeof StepArray[number];

export interface AuthDialogChildrenProps {
  setStep: (step: Step, state?: AuthModel) => void;
  state: AuthModel;
  closeDialog: () => void;
  open: boolean;
  error: string | null;
  user: User | null;
  showPhoneField?: boolean;
  // showNameField?: boolean;
  showCO2MailField?: boolean;
  onRegistrationComplete?: (unitId: string) => Promise<void>;
  clearUserError: () => void;
  authorize: (username: string, password: string) => Promise<any>;
  registerUser: (register: RegisterUserContract) => Promise<any>;
  getLanguage:()=>LanguageType;
}

type AuthDialogProps = {
  open: boolean;
  closeDialog: () => void;
  step: Step;
  setStep: Dispatch<SetStateAction<Step>>;
  children?: React.ReactNode;
  simpleRegistration?: boolean;
  registrationDestination?: string;
  showNameField?: boolean;
  isTokenError?: boolean;
  onRegistrationComplete?: (unitId: string) => Promise<void>;
  showAlertHandler?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

const AuthDialog = ({ step, setStep, closeDialog, ...other }: AuthDialogProps) => {
  const [userState, { clearUserError, authorize, registerUser, getLanguage }] = useUser();

  const [state, setState] = useState<AuthModel>({
    Email: "",
    Password: "",
    FullName: "",
    PropertyId: "",
  });

  const { simpleRegistration, registrationDestination } = other;
  useEffect(() => {
    // isTokenError used to open modal in case we have user but token expired
    if (other.open && userState.user && !other.isTokenError && !simpleRegistration) {
      closeDialog();
    }
  }, [userState.user, closeDialog, other.open]);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const extendedSetStep = (step: Step, partialState: object = {}) => {
    setStep(step);
    clearUserError();
    setState({
      ...state,
      ...partialState,
    });
  };

  const extendedCloseDialog = () => {
    clearUserError();
    closeDialog();
  };

  const childrenProps: AuthDialogChildrenProps = {
    state: {
      ...state,
      PropertyId: userState.unitId,
      SchoolId: userState.schoolId,
      ClassLabel: userState.classLabel,
    },
    setStep: extendedSetStep,
    closeDialog: extendedCloseDialog,
    clearUserError,
    ...other,
    error: userState.error,
    authorize,
    registerUser,
    getLanguage,
    user: userState.user,
  };
  let componentToRender: null | JSX.Element;
  switch (step) {
    case "login":
      componentToRender = <LoginDialog {...childrenProps} />;
      break;

    case "register":
      componentToRender = <RegisterDialog {...childrenProps} />;
      break;
    case "registerPhone":
      componentToRender = <RegisterDialog showPhoneField={true} {...childrenProps} />;
      break;
    case "forgotPassword":
      componentToRender = <ForgotPasswordDialog {...childrenProps} />;
      break;
    default:
      componentToRender = null;
      break;
  }

  return <>{componentToRender}</>;
};

export default AuthDialog;
