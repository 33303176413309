import { SearchResult } from "../../../definitions/model/SearchResult";
import { TranslationText } from "../../../definitions/Menu";

export type CO2Plan = {
  Id: string;
  PlanPersons: CO2PlanPerson[];
  PlanPersonsToIgnore: string[];
  IsSinglePersonPlan: boolean;
  // Average emission for a household with this number of people
  DefaultCO2Emission: number;
  // Emission that sould be achieved to rich a 70% reduction goal
  GovernmentGoalCO2Emission: number;

  // Emission calculated based on a initial survey
  InitialHouseholdCO2Emission: number | null;

  // Emission calculated based on target survey
  TargetHouseholdCO2Emission: number | null;
  InitialSurvey: CO2EmissionSurvey;
  TargetSurvey: CO2EmissionSurvey;
  InitialSurveyHistory: CO2EmissionSurvey[];
  IsInitialSurveyOutdated: boolean;
  ActionsTimeLine: CO2TimelineAction[];
  IsMultiSelect: boolean;
  CustomQuestions: CustomQuestions[];
  Translations: DynamicTranslation[];
  UnchangeableBasicEmission: number;
};

export type DynamicTranslation = {
  LanguageId: string;
  Text: string;
  KeyPath: string;
};

export enum CO2PlanCustomQuestionsTarget {
  InitialSurvey = 0,
  TargetSurvey = 1,
  MainToolBar = 2,
}

export type CustomQuestions = {
  Target: CO2PlanCustomQuestionsTarget;
  PlaceAfterSourceId: string | null;
  IsFilledIn: boolean;
  Questions: CO2Category[];
};

export type CO2PlanPerson = {
  Id: string;
  Name: string;
  BirthYear?: number;
};

export type CO2EmissionSurvey = {
  Categories: CO2Category[];
  IsFilledIn: boolean;
  Error: string | null;
  MissingSourceGroups: Array<[string, string | null]>; // pairs of group/person
  ActiveGroupIndex: number | undefined; // group that should be shown to user
  HasInvalidInput: boolean;
  Year: number | null;
};

export type CO2Category = {
  Id: string;
  Name: string;
  Code: number;
  CO2Emission: number;
  EmissionSourceGroups: CO2EmissionSourceGroup[];
};

export type CO2EmissionSourceGroup = {
  Id: string;
  Code: number;
  Name: string;
  ShortName: string;
  Question: string;
  IsPerPerson: boolean;
  HouseHoldEmissionSources: CO2EmissionSource[];
  PersonEmissionSources: CO2PersonEmissionSourceGroup[];
  CO2Emission: number;
  // Amount of CO2 produced by an average household
  AverageCO2Emission: number;
  // Maximum ammount of CO2 that allows household to be in "Green zone"
  GoalCO2Emission: number;
  // diffence between initial and target emission
  CO2EmissionSaved: number | null;
  SelectedSourceId: string | null;
  CategoryId: string;
  // Should have value only for future survey
  DueYear: number | null;
  //Every occurance of {%} should be replaced with selected Emission source, option name or option value in UI
  TargetSurveyDisplayPattern: string | null;
  ArticleLink: string | null;
  SponsorSolutions: CO2SponsorSolutionBasic[];
  EconomyItems: CO2EconomyItem[];
  CustomCalculationId: string | null;
  EconomyCost: number | null;
  CustomEconomyCost: number | null;
  IsCustom: boolean;
  IsMultiselect: boolean;
};

export type CO2PersonEmissionSourceGroup = {
  PersonId: string;
  Name: string;
  EmissionSources: CO2EmissionSource[];
  CO2Emission: number;
  // diffence between initial and target emission
  CO2EmissionSaved: number | null;
  SelectedSourceId: string | null;
  // Should have value only for future survey
  DueYear: number | null;
  CustomCalculationId: string | null;
  EconomyCost: number | null;
  CustomEconomyCost: number | null;
};

export type CO2EmissionSource = {
  Id: string;
  Code: number;
  Order: number;
  Name: string;
  // Optional name for a target survey
  TargetName: string | null;
  DescriptionUrl: string | null;
  HasOptions: boolean;
  IsSelected: boolean;
  IsInitiallySelected: boolean | null;
  HasChildSources: boolean;
  SelectedChildSourceId: string | null;
  SelectedOptionId: string | null;
  SelectedInitialOptionId: string | null;
  ThingEmission: boolean;
  Question: string | null;
  Unit: string | null;
  Value: number | null;
  ValueInitial: number | null;
  CombinedMaxAmount: number | null;
  CombinedValue: number | null;
  CombinedValueInitial: number | null;
  MetaValue: string;
  PlanMetaId: string;
  CO2Emission: number;
  // Emission per user input item. Can be used for preview calculations
  CO2EmissionPerThing: number | null;
  PricePerUnit: number | null;
  Options: CO2EmissionSourceOption[];
  ChildSources: CO2EmissionSource[];
};

export type CO2EmissionSourceOption = {
  Id: string;
  Code: number;
  Name: string;
  IsSelected: boolean;
  // Optional name for a target survey
  TargetName: string | null;
  CO2Emission: number;
};

export type CO2TimelineAction = {
  EmissionSourceId: string;
  EmissionSourceGroupId: string;
  EmissionSourceGroupName: string;
  PersonId: string | null;
  PersonName: string | null;
  InitialState: string;
  TargetState: string;
  DueYear: number;
  SavedCO2: number;
  IsFulfilled: boolean;
  // this field is used to keep original grouping after user changed some records
  WasFulfilledWhenLoad: boolean;
};

export type MergePersons = {
  CurrentPersonId: string;
  NewPersonId: string;
};

export type CO2UpdatePlanPersonsRequest = {
  UnitId: string;
  PersonNamesToAdd: CO2PlanPerson[];
  PersonIdsToDelete: string[];
  PersonsToMerge: MergePersons[];
  PersonsToRename: CO2PlanPerson[];
  IsSinglePersonPlan: boolean;
  IgnoreMembersList: string[];
  IsMultiSelect: boolean;
};

export type CO2UpdateTempPlanPersonsRequest = {
  TempPlanId: string;
  PersonNamesToAdd: CO2PlanPerson[];
  PersonIdsToDelete: string[];
  PersonsToRename: CO2PlanPerson[];
  IsSinglePersonPlan: boolean;
};

export type SaveEmissionSource = {
  EmissionSourceId: string | null;
  SelectedOptionId: string | null;
  EmissionSourceGroupId: string;
  PersonId: string | null;
  Value: number | null;
  CombinedValue: number | null;
  DueYear: number | null;
  CustomCalculation: CO2CustomCalculation | null;
  EconomyCost: number | null;
  CustomEconomyCost: number | null;
  MetaValue?: string;
  PlanMetaId?: string;
};

export type MarkTimelineActionAsFulfilledRequest = {
  UnitId: string;
  EmissionSourceGroupId: string;
  PersonId: string | null;
  IsFulfilled: boolean;
};

export type MarkTempTimelineActionAsFulfilledRequest = {
  TempPlanId: string;
  EmissionSourceGroupId: string;
  PersonId: string | null;
  IsFulfilled: boolean;
};

export type CO2UnitAddress = {
  UnitId: string;
  PlanId: string;
  Address: string;
  CommuneName: string;
  CommuneNumber: number;
  PostalCode: number;
  InitialSurveyFilledInPercentage: number;
  InitialHouseholdCO2Emission: number | null;
  TargetSurveyFilledInPercentage: number;
  TargetHouseholdCO2Emission: number | null;
  DefaultEmission: number;
  GovernmentGoalCO2Emission: number;
  Created: string;  
  Updated: string;
};

export type CO2SponsorSolution = {
  Id: string;
  CompanyId: string;
  CompanyName: string;
  CompanyLogoUrl: string;
  CompanyWebSite: string;
  EmissionSourceGroupId: string;
  EmissionSourceChildGroups: string[];
  GroupDescription: CO2EmissionSourceGroupDescription;
  DisplayName: string;
  CO2Emission: number;
  WidgetId: string | null;
};

export type CO2EmissionSourceGroupDescription = {
  Id: string;
  Name: string;
  ShortName: string;
  IsPerPerson: boolean;
  AverageCO2Emission: number;
  GoalCO2Emission: number;
  CategoryId: string;
  ChildSources: Array<EmissionSourceDto>;
};

export type EmissionSourceDto = {
  Id: string;
  Code: number;
  Name: string;
  TargetName: string;
  EmissionSourceGroupId: string | null;
  EmissionSourceHighLevelId: string | null;
  ChildSources: Array<EmissionSourceDto>;
};

export type CO2SponsorSolutionBasic = {
  Id: string;
  DisplayName: string;
  CO2Emission: number;
  WidgetId: string | null;
  CompanyRating: number | null;
  CompanyName: string | null;
  CompanyPhone: string | null;
  CompanyWebSite: string | null;
  CompanyCVR: string | null;
};

export type CO2EconomyItem = {
  Id: string;
  Created: Date;
  Udpated: Date;
  EmissionSourceGroupId: string;
  DisplayName: string;
  ExternalLink: string;
  HideCommunes: number[];
  ShowCommunes: number[];
  LinkClicks: number;
  Links: CO2EconomyLink[];
  GroupDescription: CO2EmissionSourceGroupDescription;
};

export type CO2EconomyLink = {
  SubCategoryId: string;
  SubCategoryName: string;
  Link: string;
  Categories: Array<CO2EconomyLinkCategory>;
};

export type CO2EconomyLinkCategory = {
  CategoryId: string;
  CategoryName: string;
};

export type CO2EconomyItemsResponse = {
  Solutions: SearchResult<CO2EconomyItem>;
  EmissionSourceGroups: CO2EmissionSourceGroupDescription[];
};

export type CO2CustomCalculation = {
  CalculationId: string;
  CO2Emission: number;
};

export type CO2PropertyDescription = {
  Area: number;
  EnergyLabel: string;
  Rooms: number;
  Toilets: number;
  Bathrooms: number;
};

export type CO2RegisterTempPlanResponse = {
  Plan: CO2Plan;
  TempCO2PlanId: string;
};

export enum partsToShow {
  Unknown = 0,
  InitialSurvey = 1,
  InitialResults = 2,
  InitialResultsFinish = 3,
  TargetSurvey = 4,
  TargetResults = 5,
  TargetResultsFinish = 6,
  ActionsTimeline = 7,
  GreenGuidesConscent = 8,
  ExtraStep = 9,
  ToolBarQuestions = 10,
}

export enum BarometerToShow {
  InitialSurvey = 1,
  TargetSurvey = 2,
}

export enum ChartToShow {
  InitialSurvey = 1,
  TargetSurvey = 2,
}

export enum ProgressBarColors {
  VeryBad = "#ff867e",
  Bad = "#feba27",
  Good = "#fff148",
  VeryGood = "#a3f070",
}

export enum CO2PlanMetaCategoryType {
  Personal = 0,
  Household = 1,
}

export type CO2PlanMeta = {
  Id: string;
  Created: Date;
  Updated: Date;
  DisplayName: string;
  InitialSurvey: boolean;
  TargetSurvey: boolean;
  MainToolBar: boolean;
  Companies: string[];
  Communes: number[];
  Questions: MetaQuestions[];
  TargetCategory: CO2PlanMetaCategoryType;
  PlaceAfterSourceId: string;
};

export type MetaQuestions = {
  Id: string;
  Order: number;
  Text: TranslationText[];
  Answers: MetaAnswer[];
  IsMultiselect: boolean;
};

export type MetaAnswer = {
  Id: string;
  Order: number;
  Text: TranslationText[];
  UserValue: boolean;
  UserValueLabel: TranslationText[];
};

export type HistoricalEmissionRecord = {
  year: number;
  yearLabel: string;
  emissions: number;
  currentUserEmission: number;
};