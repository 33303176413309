import { useState, useEffect } from "react";
import { Grid, Link, TextField, Button, Typography, LinearProgress, Box, Zoom, styled } from "@mui/material";
import { emailRegexp } from "../../../services/validation.service";
import DataPoliticDialog from "../DataPoliticDialog";
import AuthModel from "../../../definitions/model/Auth";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import PasswordField from "../../Base/PasswordField";
import User from "../../../definitions/model/User";
import { Step } from "../AuthDialog";
import { RegisterUserContract } from "../../../definitions/models";
import { TextFieldConnector } from "../../Base/FormConnector";
import { LanguageType } from "../../../definitions/Menu";

const LinearProgressStyled = styled(LinearProgress)(() => ({
  "&.MuiLinearProgress-colorSecondary": {
    background: "#66000090",
  },
  "&.MuiLinearProgress-barColorSecondary": {
    background: "#660000",
  },
}));

type PasswordSafetyProgressProps = {
  password: string | undefined;
};

const PasswordSefetyProgress = ({ password }: PasswordSafetyProgressProps) => {
  const { t } = useTranslation("translation");
  let progress = 0;
  if (password) {
    if (password.length >= 6) progress += 31;
    if (password.match(/[A-Z!@#$%^&*]/)) progress += 19;
    if (password.match(/[a-z]/)) progress += 19;
    if (password.match(/\d/)) progress += 20;
    if (password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/)) progress += 20;
  }
  return (
    <Box width="100%" pb={1}>
      <LinearProgressStyled
        variant="determinate"
        color={progress < 50 ? "secondary" : "primary"}
        value={progress > 100 ? 100 : progress}
      />
      <Typography variant="body2">{t("Authorization.Errors.NecessaryQuantityOfCharacters")}</Typography>
    </Box>
  );
};

type RegisterFormData = {
  Email?: string;
  Name?: string;
  Password?: string;
  FKey?: string;
  Phone?: string;
  UnitId?: string;
  SchoolId?: string;
  ClassLabel?: string;
  Language: LanguageType;
};

type RegisterFormProps = {
  state: AuthModel;
  error: string | null;
  user: User | null;
  showPhoneField?: boolean;
  showCO2MailField?: boolean;
  registerUser: (userData: RegisterUserContract) => Promise<User | undefined>;
  setStep: (step: Step, state?: AuthModel) => void;
  onRegistrationComplete?: (unitId: string) => Promise<void>;
  closeDialog: () => void;
  clearUserError: () => void;
  getLanguage:()=>LanguageType;
};

const RegisterForm = (props: RegisterFormProps): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation("translation");
  const [openPolicy, setOpenPolicy] = useState(false);
  const { state } = props;
  const [error, setAnError] = useState(props.error);
  useEffect(() => {
    setAnError(props.error);
  }, [props.error]);
  const { registerUser, clearUserError } = props;
  const [processing, setProcessing] = useState(false);

  const methods = useForm<RegisterFormData>({
    defaultValues: {
      Email: state.Email,
      Password: state.Password,
      // undefined should be treated as false to make checkbox working
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    formState,
    getValues,
    watch,
  } = methods;

  const submit = async (data: RegisterFormData) => {
    try {
      await handler(data);
    } catch (error) {
      setAnError((error as Error).message);
    }
  };

  const handler = async (data: RegisterFormData) => {
    setProcessing(true);

    try {
      const user = await registerUser({
        Name: data.Name || "",
        Email: data.Email || "",
        Password: data.Password || "",
        UnitId: props.state.PropertyId || data.UnitId || null,
        SchoolId: props.state.SchoolId || data.SchoolId,
        ClassLabel: props.state.ClassLabel || data.ClassLabel,
        Language: props.getLanguage()
      }).then((res) => {
        if (res) clearUserError();
        return res;
      });

      if (user) navigate("/co2calculator");
    } catch {
      setProcessing(false);
      return;
    }
    setProcessing(false);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(submit)}>
          <Grid container spacing={2} direction="column">
            <Grid item container>
              <TextFieldConnector register={register("Name", { required: false, minLength: 3 })}>
                <TextField
                  variant="outlined"
                  fullWidth
                  type="text"
                  size="small"
                  label={t("Authorization.Forms.Name")}
                  error={!!errors.Name}
                />
              </TextFieldConnector>
            </Grid>

            <Grid item container>
              <TextFieldConnector
                register={register("Email", {
                  required: true,
                  pattern: emailRegexp,
                })}
              >
                <TextField
                  variant="outlined"
                  fullWidth
                  type="email"
                  size="small"
                  label={t("Authorization.Forms.EnterName")}
                  error={!!errors.Email}
                />
              </TextFieldConnector>
            </Grid>
            {error && (
              <Zoom in={!!error}>
                <Grid item container justifyContent="center">
                  <Typography color="error">{error}</Typography>
                </Grid>
              </Zoom>
            )}
            <Grid item container>
              <TextFieldConnector
                register={register("Password", {
                  required: true,
                  minLength: 6,
                })}
              >
                <PasswordField
                  variant="outlined"
                  fullWidth
                  size="small"
                  label={t("Authorization.Forms.Password")}
                  error={!!errors.Password}
                />
              </TextFieldConnector>
            </Grid>
            <Grid item container>
              <PasswordSefetyProgress password={watch().Password} />
            </Grid>
            {/*{isHouseTaken && (*/}
            {/*    <Fade in={isHouseTaken}>*/}
            {/*        <Grid item container justifyContent="center">*/}
            {/*            <Alert severity="warning">*/}
            {/*                {t("Authorization.Forms.AlertText1")}*/}
            {/*                <br />*/}
            {/*                {t("Authorization.Forms.AlertText2")}*/}
            {/*            </Alert>*/}
            {/*        </Grid>*/}
            {/*    </Fade>*/}
            {/*)}*/}
            <Grid container justifyContent="center">
              <Button
                sx={{
                  minWidth: "200px",
                  marginTop: "20px",
                  "& span": {
                    justifyContent: "center",
                  },
                }}
                variant="contained"
                color="success"
                disabled={formState.isSubmitting || processing}
                type="submit"
              >
                {props.showCO2MailField && t("General.Buttons.CreateAndNavigateCo2")}
                {!props.showCO2MailField && t("General.Buttons.Create")}
              </Button>
            </Grid>
            <Grid container item justifyContent="center" alignItems="center">
              <Grid item>
                <Link onClick={() => props.setStep("login", getValues())}>
                  <Typography variant="body2">{t("Authorization.Buttons.HaveAnAccount")}</Typography>
                </Link>
              </Grid>
            </Grid>
          </Grid>

          <DataPoliticDialog open={openPolicy} closeDialog={() => setOpenPolicy(false)} />
        </form>
      </FormProvider>
      {processing && <LinearProgress style={{ marginTop: 10 }} />}
    </>
  );
};

export default RegisterForm;
