import { operationFailedActionGeneral } from ".";
import { AutocompleteConstants } from "../constants/autocomplete.constants";
import { AppAction } from "../definitions/Action";
import AutocompleteService from "../services/autocomplete.service";
import { ThunkDispatch } from "redux-thunk";
import { ApplicationState } from "../reducers/store";
import { AnyAction } from "redux";

const operationFailedAction = (payload: unknown): AppAction =>
  operationFailedActionGeneral(payload, AutocompleteConstants.AUTOCOMPLETE_OPERATION_FAILED);

export const getUnits =
  (
    filter: string,
    skip: number,
    take: number,
    sortField: string,
    descending: boolean,
    startFromBuildings: boolean,
    signal?: AbortSignal
  ) =>
  async (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
    try {
      dispatch({ type: AutocompleteConstants.GET_UNITS });
      const result = await AutocompleteService.getUnits(
        filter,
        skip,
        take,
        sortField,
        descending,
        startFromBuildings,
        signal
      );
      dispatch({
        type: AutocompleteConstants.GET_UNITS_SUCCESS,
        payload: result,
      });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const getSchools =
  (
    filter: string,
    skip: number,
    take: number,
    sortField: string,
    descending: boolean,
    commune: number,
    signal?: AbortSignal
  ) =>
  async (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
    try {
      //I don't think we need these action types for the autocomplete unless we store the result locally
      //dispatch({ type: AutocompleteConstants.GET_UNITS });
      const result = await AutocompleteService.getSchools(filter, skip, take, sortField, descending, commune, signal);
      // dispatch({
      //   type: AutocompleteConstants.GET_UNITS_SUCCESS,
      //   payload: result,
      // });

      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };
