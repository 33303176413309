export const UserConstants = {
  USER_OPERATION_FAILED: "USER_OPERATION_FAILED",
  REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USERS_REGISTER_FAILURE",
  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",
  LOGOUT: "USERS_LOGOUT",
  TRIGGER_LOGOUT: "USERS_TRIGGER_LOGOUT",
  START_SIGNALR: "START_SIGNALR",
  START_SIGNALR_SUCCEEDED: "START_SIGNALR_SUCCEEDED",
  STOP_SIGNALR: "STOP_SIGNALR",
  STOP_SIGNALR_SUCCEEDED: "STOP_SIGNALR_SUCCEEDED",
  CHANGE_USER_PASSWORD: "CHANGE_USER_PASSWORD",
  CHANGE_USER_PASSWORD_SUCCEEDED: "CHANGE_USER_PASSWORD_SUCCEEDED",
  UPDATE_USER_PROFILE: "UPDATE_USER_PROFILE",
  UPDATE_USER_PROFILE_SUCCEEDED: "UPDATE_USER_PROFILE_SUCCEEDED",
  CHANGE_USER_CURRENT_HOUSE_SUCCEEDED: "CHANGE_USER_CURRENT_HOUSE_SUCCEEDED",
  SET_PWA_OPTIONS: "SET_PWA_OPTIONS",

  OPEN_CREATE_DIALOG: "OPEN_CREATE_DIALOG",
  OPEN_CREATE_DIALOG_SUCCEEDED: "OPEN_CREATE_DIALOG_SUCCEEDED",

  OPEN_LOGIN_DIALOG: "OPEN_LOGIN_DIALOG",
  OPEN_LOGIN_DIALOG_SUCCEEDED: "OPEN_LOGIN_DIALOG_SUCCEEDED",

  SET_REG_DATA: "SET_REG_DATA",
  REMOVE_USER: "REMOVE_USER",
  REMOVE_USER_SUCCEEDED: "REMOVE_USER_SUCCEEDED",

  SET_CLIMATE_PLAN_DATA: "SET_CLIMATE_PLAN_DATA",
  SEND_EMAIL_SHORTCUT: "SEND_EMAIL_SHORTCUT",
  SEND_EMAIL_SHORTCUT_SUCCEEDED: "SEND_EMAIL_SHORTCUT_SUCCEEDED",

  SET_LANGUAGE: "SET_LANGUAGE",
  GET_LANGUAGE: "GET_LANGUAGE",

  MOVE_OUT_UNIT_SUCCESS: "MOVE_OUT_UNIT_SUCCESS",
  MOVE_IN_UNIT_SUCCESS: "MOVE_IN_UNIT_SUCCESS",

  UPDATE_USER_LANGUAGE: "UPDATE_USER_LANGUAGE",
  UPDATE_USER_LANGUAGE_SUCCEEDED: "UPDATE_USER_LANGUAGE_SUCCEEDED",
};
