import store from "../reducers/store";
import { headers } from "./http.service";

const ApiService = {
  post: async <T, P = any>(
    url: string,
    item: P,
    overrideHeaders?: any
  ): Promise<T> => {
    return await apiRequest(url, {
      method: "POST",
      mode: "cors",
      headers: overrideHeaders || (await headers()),
      body: JSON.stringify(item),
    });
  },

  put: async <T, P = any>(
    url: string,
    item: P,
    overrideHeaders?: any
  ): Promise<T> => {
    return await apiRequest(url, {
      method: "PUT",
      mode: "cors",
      headers: overrideHeaders || (await headers()),
      body: JSON.stringify(item),
    });
  },

  get: async <T>(url: string, overrideHeaders?: any): Promise<T> => {
    return await apiRequest(url, {
      method: "GET",
      mode: "cors",
      headers: overrideHeaders || (await headers()),
    });
  },

  delete: async <T>(url: string, overrideHeaders?: any): Promise<T> => {
    return await apiRequest(url, {
      method: "DELETE",
      mode: "cors",
      headers: overrideHeaders || (await headers()),
    });
  },
};

const apiRequest = async <T>(url: string, config: RequestInit): Promise<T> => {
  if(url.startsWith("/")) url = url.substring(1);
  url = `${process.env.REACT_APP_API}/${url}`;

  config.credentials = "include";
  const response = await fetch(url, config);
  if (response.status !== 200 && response.status !== 204) {
    let statusText = "";
    try {
      statusText = await response.text();
    } catch {
      //do not throw an exception
    }

    //we should show an error message when we get an unauthorized error, except when we get it during authorization
    if (response.status === 401 && url.indexOf("/api/account/logout") === -1 ) {
     store.dispatch({
        type: "USERS_TRIGGER_LOGOUT" ,
      });
    }
    const error: ApiError = { status: response.status, statusText: statusText };
    throw error;
  }
  if (response.status === 204) return Promise.resolve({} as T);
  const result = await response.json();
  return result;
};

export type ApiError = {
  status: number,
  statusText: string
};

export const abortedApiRequest = async <T>(
  url: string,
  signal: AbortSignal | null
): Promise<T> => {
  return await apiRequest(url, {
    method: "GET",
    mode: "cors",
    headers: await headers(),
    signal: signal,
  });
};

export default ApiService;