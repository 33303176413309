import { DataGrid, DataGridProps, GridCellModes, GridCellModesModel, GridCellParams } from "@mui/x-data-grid";
import React from "react";
import { styled } from "@mui/material";

function DataGridWithSingleClickCellEdit(props: DataGridProps) {
  const [cellModesModel, setCellModesModel] = React.useState<GridCellModesModel>({});

  const handleCellClick = React.useCallback((params: GridCellParams) => {
    if (params.isEditable) {
      setCellModesModel((prevModel) => {
        return {
          // Revert the mode of the other cells from other rows
          ...Object.keys(prevModel).reduce(
            (acc, id) => ({
              ...acc,
              [id]: Object.keys(prevModel[id]).reduce(
                (acc2, field) => ({
                  ...acc2,
                  [field]: { mode: GridCellModes.View },
                }),
                {}
              ),
            }),
            {}
          ),
          [params.id]: {
            // Revert the mode of other cells in the same row
            ...Object.keys(prevModel[params.id] || {}).reduce(
              (acc, field) => ({
                ...acc,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
            [params.field]: { mode: GridCellModes.Edit },
          },
        };
      });
    }
  }, []);

  const handleCellModesModelChange = React.useCallback(
    (newModel: GridCellModesModel) => setCellModesModel(newModel),
    []
  );

  return (
    <DataGrid
      cellModesModel={cellModesModel}
      onCellModesModelChange={handleCellModesModelChange}
      onCellClick={handleCellClick}
      {...props}
    />
  );
}

const StyledOneClickDataGrid = styled(DataGridWithSingleClickCellEdit)(({ theme }) => ({
  background: theme.palette.background.paper,
  margin: 0,
  border: `1px solid ${theme.palette.divider}`,
  "& .MuiDataGrid-cell, .MuiDataGrid-columnHeaders": {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  "& .MuiDataGrid-cell, .MuiDataGrid-columnHeader": {
    padding: "0 4px",
  },
  "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
    display: "none",
  },
}));

export default StyledOneClickDataGrid;
