import React, { useContext, useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  Typography,
  Grid,
  Button,
  useMediaQuery,
  useTheme,
  styled,
  Card,
  CardContent,
} from "@mui/material";
import Alert from "@mui/lab/Alert/Alert";

import { CO2Category, CO2TimelineAction } from "../../CO2Plan";
import CO2ActionsChartComponent from "./CO2ActionsChartComponent";
import CO2TimelineActionComponent from "./CO2TimelineActionComponent";
import { AccountCircle } from "@mui/icons-material";
import { UserConstants } from "../../../../../constants";
import { convertToCurency } from "../../../../../services/converter.service";
import ButtonsComponent from "../ButtonsComponent";
import { useTranslation } from "react-i18next";
import { CO2Context } from "../CO2ContextWrapper";
import { GetColor, GetIcon } from "../../co2-helpers";

type CO2ActionsTimelineProps = {
  timeline: CO2TimelineAction[];
  categories: CO2Category[];
  backToSurvey: () => void;
  showTargetResults: () => void;
  showSendCredentialsMessage: () => void;
};

const CO2ActionsTimelineComponent = (
  props: CO2ActionsTimelineProps
): JSX.Element => {
  const dispatch = useDispatch();
  const { user } = useContext(CO2Context);
  const theme = useTheme();
  const { t } = useTranslation("translation");
  const isSm = useMediaQuery(theme.breakpoints.down("md"));
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down("lg"));
  
  const createClick = () => {
    dispatch({ type: UserConstants.OPEN_CREATE_DIALOG });
  };

  const emissionSourceGroups = useMemo(
    () => props.categories.flatMap((i) => i.EmissionSourceGroups),
    []
  );

  return (
    <>
      <Grid item direction="column" container xs={12} md={10} lg={9} alignItems="center">
      {isMd && (
        <StyledButton
          variant="outlined"
          onClick={() => window.print()}
          size="small"
        >
          {t("ClimatePlan.Buttons.PrintPage")}
        </StyledButton>
      )}
        <Grid item container direction="column">
          <Card square={false}>
          <CardContent style={{ padding: isXs ? "10px" : "20px 20px 10px" }}>
          <Grid container justifyContent={isSm ? "center" : "space-between"}>
            <Grid
              item
              container
              justifyContent="flex-start"
              xs={12}
              md={props.timeline.length ? 5 : 12}
              direction="column"
              spacing={2}
            >
              <Grid item>
                <Typography variant="h3">
                  {t("ClimatePlan.General.My")}
                </Typography>
                <Typography variant="h3" color="secondary">
                  {" "}
                  {t("ClimatePlan.Timeline.ToDo")}
                </Typography>
              </Grid>
              <Grid item>
                <Typography paragraph variant="h6" align={"left"}>
                  {t("ClimatePlan.Timeline.Paragraph2span")} &#10003;{" "}
                  {t("ClimatePlan.Timeline.Paragraph2")}{" "}
                  <span style={{ fontFamily: "CircularXX-Bold" }}>
                    {convertToCurency(
                      props.timeline
                        .map((t) => t.SavedCO2)
                        .reduce((a, b) => a + b, 0),
                      "."
                    )}{" "}
                    kg
                  </span>
                  .{" "}
                </Typography>
              </Grid>
            </Grid>
            {!user && (
              <>
                <Grid
                  item
                  container
                  justifyContent={isSm ? "center" : "flex-start"}
                >
                  <Typography variant="body1" align={"left"}>
                    <Alert icon={<AccountCircle />} severity="success">
                      {t("ClimatePlan.Timeline.Alert1")}
                    </Alert>
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent={isSm ? "center" : "flex-start"}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={createClick}
                  >
                    {t("ClimatePlan.General.SavePlan")}
                  </Button>
                </Grid>
              </>
            )}
            {!!props.timeline.length && (
              <Grid
                item
                container
                direction="column"
                xs={12}
                md={5}
                justifyContent="center"
                alignContent="center"
                spacing={1}
              >
                <Grid
                  item
                  container
                  justifyContent={isSm ? "center" : "flex-start"}
                >
                  <CO2ActionsChartComponent timeline={props.timeline} />
                </Grid>
              </Grid>
            )}
          </Grid>
          </CardContent>
          </Card>

          {!!props.timeline.length && (
            <>
              <Grid container justifyContent="center" mt={2} mb={1}>
                <Grid item container direction="column" spacing={2}>
                  {props.timeline
                    .sort(
                      (a, b) =>
                        +a.WasFulfilledWhenLoad - +b.WasFulfilledWhenLoad
                    )
                    .map((item, index) => {
                      const emissionSourceGroup = emissionSourceGroups.find(
                        (i) => i.Id === item.EmissionSourceGroupId
                      );
                      return (
                        <CO2TimelineActionComponent
                          key={index}
                          action={item}
                          icon={{
                              background: GetColor(emissionSourceGroup?.ShortName??""),
                              src: GetIcon(emissionSourceGroup?.ShortName??""),
                            }}
                        />
                      );
                    })}
                </Grid>
              </Grid>
            </>
          )}
          {user && (
            <>
              {!props.timeline.length && (
                <Grid item container direction="column">
                  <Typography variant="h6" align={"left"}>
                    {t("ClimatePlan.Timeline.Paragraph3")}
                  </Typography>
                  <Grid item container justifyContent="center">
                    <Button
                      sx={{ whiteSpace: "nowrap" }}
                      variant="contained"
                      color="info"
                      style={{ width: 142, margin: 20 }}
                      onClick={() => props.backToSurvey()}
                    >
                      {t("ClimatePlan.General.Change")}
                    </Button>
                  </Grid>
                </Grid>
              )}
            </>
          )}
          <ButtonsComponent
            back={() => props.showTargetResults()}
            forth={() => props.showSendCredentialsMessage()}
          />
        </Grid>
      </Grid>
    </>
  );
};

const StyledButton = styled(Button)(({ theme }) => ({
  maxWidth: "150px",
  marginLeft: "auto",
  marginBottom: 8,
  "@media print": {
    display: "none",
  },
}));

export default CO2ActionsTimelineComponent;
