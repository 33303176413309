import { useDispatch } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { ApplicationState } from "../reducers/store";
import { ApiError } from "../services/api.service";

export * from "./user.actions";
export * from "./autocomplete.actions";

export const useAppDispatch = () =>
  useDispatch<ThunkDispatch<ApplicationState, any, AnyAction>>();

export const operationFailedActionGeneral = (
  payload: unknown,
  type: string
): AnyAction => {
  let message = String(payload);
  if (payload instanceof Error) message = (payload as Error).message;

  try {
    message = (payload as ApiError).statusText;
  } catch {
    //
  }

  return {
    type: type,
    payload: message,
  };
};
