import React from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { HistoricalEmissionRecord } from "../CO2Plan";
import { convertToCurency } from "../../../../services/converter.service";
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

type CO2EmissionHistoryChartProps = {
  emissionsHistory: HistoricalEmissionRecord[];
};

const CO2EmissionHistoryChartComponent = (props: CO2EmissionHistoryChartProps): JSX.Element => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  const toolTipFormater = (value: string | number | (string | number)[]) => {
    return [`${convertToCurency(Number(value))} kg.`];
  };

  return (
    <Grid item container justifyContent="center">
        <Grid item style={{ width: isXs ? "100%" : "50%" }}>
          <ResponsiveContainer height={isXs ? 200 : 300} width="100%">
            <BarChart data={props.emissionsHistory}>
              <XAxis dataKey="year" />
              <YAxis />
              <Bar dataKey="emissions" fill="#8884d8" />
              <Tooltip cursor={{ fill: "transparent" }} formatter={toolTipFormater as any} />
            </BarChart>
          </ResponsiveContainer>
        </Grid>
    </Grid>
  );
};

export default CO2EmissionHistoryChartComponent;
