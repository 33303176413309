import { useEffect } from "react";
import { Grid, styled, Typography } from "@mui/material";
import { connect, ConnectedProps } from "react-redux";
import { ApplicationState } from "../../../reducers/store";
import { AppThunkDispatch } from "../../../definitions/Action";
import { getTotalCO2PlansCount } from "../../ClimatePlan/shared/co2plan.actions";
import { useTranslation } from "react-i18next";

const CircleGrid = styled(Grid)(({ theme }) => ({
  position: "absolute",
  backgroundColor: theme.palette.secondary.main,
  width: 70,
  height: 70,
  borderRadius: "50%",
  boxSizing: "border-box",
  display: "flex",
  justifyContent: "center",
  top: 70,
  [theme.breakpoints.up("md")]: {
    width: 100,
    height: 100,
    padding: 10,
  },
}));

const LabelText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  fontSize: 12,
  lineHeight: 1.2,
  [theme.breakpoints.up("md")]: {
    fontSize: 14,
  },
}));

const UsersCount = styled("span")(({ theme }) => ({
  fontWeight: "bold",
  display: "block",
  height: 18,
  [theme.breakpoints.up("md")]: {
    fontSize: 22,
    height: 26,
  },
}));

type CircleLabelProps = {
  isGrapesComponent?: boolean;
};

const CircleLabel = ({
  totalCO2Plans,
  getTotalCO2PlansCount,
}: CircleLabelConnectedProps & CircleLabelProps): JSX.Element => {
  const { t } = useTranslation("translation");

  useEffect(() => {
    if (!totalCO2Plans) {
      getTotalCO2PlansCount();
    }
  }, [getTotalCO2PlansCount, totalCO2Plans]);

  return (
    <CircleGrid container alignItems="center">
      <LabelText align="center" variant="subtitle2">
        <UsersCount>{totalCO2Plans.toLocaleString("da-DK")}</UsersCount>
        {t("StartPage.CreatedPlans")}
      </LabelText>
    </CircleGrid>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  totalCO2Plans: state.co2.totalCO2Plans,
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  getTotalCO2PlansCount: () => dispatch(getTotalCO2PlansCount()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type CircleLabelConnectedProps = ConnectedProps<typeof connector>;

export default connector(CircleLabel);
