import React, { useState, useEffect, useReducer } from "react";
import { useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Typography,
  MenuItem,
  Popper,
  ClickAwayListener,
  Paper,
  MenuList,
  useMediaQuery,
  Icon,
  useTheme,
  Skeleton,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import AuthDialog, { Step, StepArray } from "../Dialogs/AuthDialog";
import HamburgerMenu from "./HamburgerMenu";
import UserAvatar from "./UserAvatar";
import { useTranslation } from "react-i18next";
import { UserConstants } from "../../constants/user.constants";
import { UnitDto } from "../../definitions/models";
import { useUser } from "../../actions";
import LogoImg from "../../assets/img/logo.png";
import { UserRole } from "../../definitions/model/User";
import { LinkComponentProps, LoginButton, SquaredButton } from "./Buttons";
import LanguageChange from "./LanguageChange";
import { useCO2 } from "../../pages/ClimatePlan/shared/co2plan.actions";

const TopBarGridContainer = styled(Grid)({
  paddingLeft: 16,
  paddingRight: 16,
  minHeight: 50,
  position: "relative",
  zIndex: 100,
});

const NewLogoIcon = styled(Icon)(({ theme }) => ({
  display: "flex",
  width: "auto",
  borderRadius: theme.shape.borderRadius,
  "&:hover": {
    cursor: "pointer",
  },
  "& img": {
    height: "inherit",
  },
}));

const SmallUserAvatar = styled(UserAvatar)({
  width: 30,
  height: 30,
});

export const StyledMenuButton = styled(Button)({
  height: 30,
  marginRight: 10,
});

const StyledMenuItem = styled(MenuItem)<LinkComponentProps>({
  padding: "2px 10px",
  minHeight: "auto",
});

export type TopBarProps = {
  hideAuth?: boolean;
  hideButtons?: boolean;
  openCreateDialog?: boolean;
  unit?: UnitDto;
  authDestination?: string;
  unauthorizedError?: boolean;
  children?: React.ReactNode;
  onRegistrationComplete?: (unitId: string) => Promise<void>;
};

type TopBarState = {
  openAuth: boolean;
  showJoinFamilyDialog: boolean;
  phoneNotifications: boolean | undefined;
  registrationUnitId: string | undefined;
  registrationWithName: boolean | undefined;
  authDestination: string;
};

const initialState: TopBarState = {
  openAuth: false,
  showJoinFamilyDialog: false,
  phoneNotifications: undefined,
  registrationUnitId: undefined,
  registrationWithName: undefined,
  authDestination: "",
};

const reducer = (state: TopBarState, action: { type: string; payload?: any }) => {
  return { ...state, [action.type]: action.payload };
};

const TopBar = (props: TopBarProps) => {
  const { t } = useTranslation("translation");
  const navigate = useNavigate();
  const [step, setStep] = useState<Step>("initial");
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const globalDispatch = useDispatch();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [{ user, loading, triggerLogout, openLoginDialog }, actions] = useUser();
  const [climatePlanState] = useCO2();

  const craftsmensLoginUrl: string = process.env.REACT_APP_SERVICEBOOK_WEB_URL || "";

  useEffect(() => {
    if (triggerLogout) logOut();

    return () => {};
  }, [triggerLogout]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has("flogin") && params.get("flogin")) setTimeout(() => showAuth("login"), 1500);

    const showRegistration = params.has("freg") ? params.get("freg") : null;
    if (showRegistration) {
      if (params.has("regUnitId"))
        dispatch({
          type: "registrationUnitId",
          payload: params.get("regUnitId") || undefined,
        });
      if (params.has("regWithName"))
        dispatch({
          type: "registrationWithName",
          payload: JSON.parse(params.get("regWithName") || "false"),
        });

      const step: Step = StepArray.includes(showRegistration as Step) ? (showRegistration as Step) : "register";
      setTimeout(() => showAuth(step), 1500);
    }
  }, []);

  useEffect(() => {
    if (props.openCreateDialog) {
      if (props.unit) dispatch({ type: "registrationUnitId", payload: props.unit.Id });
      dispatch({ type: "authDestination", payload: "unitprofile" });
      showAuth("register");
      globalDispatch({ type: UserConstants.OPEN_CREATE_DIALOG_SUCCEEDED });
    }
  }, [props.openCreateDialog]);

  useEffect(() => {
    if (openLoginDialog) {
      showAuth("login");
      if (props.authDestination) dispatch({ type: "authDestination", payload: props.authDestination });
      globalDispatch({ type: UserConstants.OPEN_LOGIN_DIALOG_SUCCEEDED });
    }
  }, [openLoginDialog]);

  const showAuth = (step: Step) => {
    setStep(step);
    dispatch({ type: "openAuth", payload: true });
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const goToProfile = () => {
    setAnchorEl(null);
    navigate("/profile");
  };

  const logOut = () => {
    setAnchorEl(null);
    actions.logout();
    navigate("/");
  };

  const goToMainPage = () => {
    if (user === null) {
      navigate("/");
      return;
    }

    //needs to add PlanId to user if Plan exist but user's PlanId is empty
    if (climatePlanState.co2Plan?.Id && user.PlanId !== climatePlanState.co2Plan?.Id) {
      actions.setClimatePlanData(climatePlanState.co2Plan?.Id);
    }

    navigate("/co2calculator");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showAlertHandler = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    // globalDispatch({
    //   type: UserConstants.OPEN_LOGIN_DIALOG,
    //   payload: window.location.href,
    // });
  };

  return (
    <>
      <TopBarGridContainer container justifyContent="space-between" alignItems="center">
        <Grid item>
          <NewLogoIcon fontSize="large" onClick={goToMainPage}>
            <img src={LogoImg} alt="logo" />
          </NewLogoIcon>
        </Grid>
        <Grid item justifyContent="flex-end" display="flex">
          <Grid container justifyContent="flex-end" alignItems="center" gap={"10px"}>
            {!isXs && loading && !user && <Skeleton variant="rectangular" width="100%" height={30} animation="wave" />}
            {!isXs && (
              <>
                {!user && !props.hideButtons && !loading && (
                  <>
                    {!props.hideAuth && (
                      <LoginButton onClick={() => showAuth("register")} variant="outlined" sx={{ marginRight: 1 }}>
                        <Typography variant="body1">{t("General.Buttons.Create")}</Typography>
                      </LoginButton>
                    )}
                  </>
                )}
              </>
            )}
            {props.children}
            {!isXs && !loading && (
              <>
                {!user && !props.hideButtons && (
                  <LoginButton onClick={() => showAuth("login")} color="secondary" variant="contained">
                    <Typography color="inherit" variant="body1">
                      {t("Menu.HomeownerLogin")}
                    </Typography>
                  </LoginButton>
                )}
                {user && (
                  <>
                    <SquaredButton
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
                    >
                      <SmallUserAvatar variant="rounded" user={user}></SmallUserAvatar>
                      <Grid item container direction="column" alignItems="flex-end">
                        {/* {props.unit && (
                            <Typography variant="body2">
                              {UnitHelper.GetFullAddress(props.unit.Address)}
                            </Typography>
                          )} */}
                      </Grid>
                    </SquaredButton>
                    <Popper open={Boolean(anchorEl) || false} anchorEl={anchorEl} disablePortal placement="bottom-end">
                      <Paper style={{ zIndex: 2 }}>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList autoFocusItem={Boolean(anchorEl)} style={{ padding: 0 }}>
                            <StyledMenuItem onClick={goToProfile}>{t("Menu.Profil")}</StyledMenuItem>
                            {user?.UserRole === UserRole.Admin && (
                              <StyledMenuItem component={Link} to="/admin">
                                <Typography variant="body1">{t("Menu.Admin")}</Typography>
                              </StyledMenuItem>
                            )}
                            {user?.UserRole === UserRole.Manager && (
                              <StyledMenuItem component={Link} to="/statistics">
                                <Typography variant="body1">{t("Menu.Statistics")}</Typography>
                              </StyledMenuItem>
                            )}
                            <StyledMenuItem onClick={logOut}>{t("Menu.LogOut")}</StyledMenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Popper>
                  </>
                )}
              </>
            )}
            {isXs && (
              <HamburgerMenu
                hideAuth={props.hideAuth}
                hideButtons={props.hideButtons}
                craftsmensLoginUrl={craftsmensLoginUrl}
                registerClick={() => showAuth("register")}
                loginClick={() => showAuth("login")}
                openCreateDialog={props.openCreateDialog}
              />
            )}
            <LanguageChange />
          </Grid>
        </Grid>
      </TopBarGridContainer>
      <AuthDialog
        setStep={setStep}
        step={step}
        open={state.openAuth}
        closeDialog={() => {
          dispatch({ type: "openAuth", payload: false });
          if (props.unauthorizedError) {
            window.location.reload();
          }
        }}
        showAlertHandler={showAlertHandler}
        simpleRegistration={!!state.registrationUnitId}
        isTokenError={props.unauthorizedError}
        registrationDestination={state.authDestination ?? undefined}
        showNameField={true}
        //showNameField={state.registrationWithName}
        onRegistrationComplete={props.onRegistrationComplete}
      />
    </>
  );
};

export default TopBar;
