import React, { useContext, useEffect, useMemo, useState } from "react";
import { Typography, Grid, Button, useMediaQuery, Card, CardContent, useTheme, Alert, Box } from "@mui/material";
import { GiGreenhouse } from "react-icons/gi";
import { useTranslation } from "react-i18next";
import CircularProgressButton from "../../../../components/Base/CircularProgressButton";
import { PwaDialog } from "./PwaDialog";
import { ClimatPlanUser, ClimatPlanWidgetModel } from "../ClimatePlanPage";
import { CO2Context } from "./CO2ContextWrapper";
import { Logout } from "@mui/icons-material";
import { useUser } from "../../../../actions/user.actions";

type CO2InstallAppComponentProps = {
  user: ClimatPlanUser | null;
  showActionsTimeLine: () => void;
};

const checkIsiOS = () => {
  return /(Mac|iPhone|iPod|iPad|iPad Simulator|iPhone Simulator|iPod Simulator)/i.test(navigator.platform);
};

const isInstalled = () => {
  const navigator = window.navigator as any;
  // For iOS
  if (navigator?.standalone) return true;
  // For Android
  if (window.matchMedia("(display-mode: standalone)").matches) return true;
  // If neither is true, it's not installed
  return false;
};

const CO2InstallAppComponent: React.FC<CO2InstallAppComponentProps> = (props: CO2InstallAppComponentProps) => {
  const [finishDialogOpen, setFinishDialogOpen] = useState(false);
  const [iosDialogOpened, setIosDialogOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation("translation");

  const { widgets, renderWidget, WidgetPage, onExitActions, renderUserNotificationAlert, sendShortcutHandler } =
    useContext(CO2Context);

  const widgetsToUse = useMemo(() => {
    return widgets?.filter((c) => c.Pages.includes(WidgetPage));
  }, [widgets]);

  const [targetWidgets, setTargetWidgets] = useState<ClimatPlanWidgetModel[]>([]);
  const [showButton, setShowButton] = useState(true);
  const [{ pwaOptions }] = useUser();

  const handleSendShortcut = async () => {
    setLoading(true);
    if (sendShortcutHandler) {
      await sendShortcutHandler();
    } else {
      await handlePwaInstall();
    }
    setLoading(false);
  };

  const handlePwaInstall = async () => {
    if (pwaOptions) {
      pwaOptions.prompt();
      const { outcome } = await pwaOptions.userChoice;
      if (outcome === "accepted") {
        setFinishDialogOpen(true);
      }
      return;
    }
    if (checkIsiOS()) {
      setIosDialogOpened(true);
    } else {
      setFinishDialogOpen(true);
    }
  };

  useEffect(() => {
    if (widgetsToUse) {
      const widgetsToShow = widgetsToUse
        .filter(
          (widget) =>
            widget.Position === 4 && // 4 is a strema position
            widget.Tag.includes("EBoxMessage")
        )
        .slice(0, 1);

      setTargetWidgets(widgetsToShow);

      if (widgetsToShow[0]?.Communes.length > 0) setShowButton(false);
    }
  }, [widgets]);

  const logOut = async () => {
    onExitActions();
  };

  return (
    <Grid item container direction="column" mb={2}>
      <Card variant="outlined" square={false}>
        <CardContent>
          <Grid container spacing={1} justifyContent={isSm ? "center" : "space-between"}>
            <Grid item container xs={12} direction="column" alignItems="center">
              <Box pb={2}>{targetWidgets[0] && renderWidget && renderWidget(targetWidgets[0], false)}</Box>
              {!targetWidgets[0] && (
                <>
                  <Typography variant="subtitle1">
                    {t("ClimatePlan.Dear")} {props.user && props.user.Name}
                  </Typography>
                  <Typography variant="body1" paragraph>
                    {t("ClimatePlan.ThankYouForEfforts")}
                  </Typography>
                  <Typography variant="body1" paragraph>
                    {t("ClimatePlan.LittleActionWillHaveHugeEffect")}
                  </Typography>
                  <Typography variant="body1" paragraph>
                    {t("ClimatePlan.ClickForShortcutToClimatePlan")}
                  </Typography>
                  <Grid container justifyContent="center">
                    {" "}
                    <Grid item>
                      <GiGreenhouse fontSize={50} color="primary" style={{ marginBottom: 20 }} />
                    </Grid>
                  </Grid>
                </>
              )}

              {renderUserNotificationAlert && (
                <Grid item style={{ marginBottom: 15 }}>
                  {renderUserNotificationAlert()}
                </Grid>
              )}
              {finishDialogOpen && (
                <Grid item style={{ marginBottom: 15 }}>
                  <Alert onClose={() => setFinishDialogOpen(false)}>{t("ClimatePlan.ShortcutHasBeenSent")}</Alert>
                </Grid>
              )}
              <Grid item container spacing={1} justifyContent="center">
                <Grid item>
                  <Button
                    style={{ minWidth: 118 }}
                    variant="contained"
                    color="success"
                    onClick={() => props.showActionsTimeLine()}
                  >
                    {t("ClimatePlan.Buttons.Back")}
                  </Button>
                </Grid>
                {showButton && !isInstalled() && (
                  <Grid item>
                    <CircularProgressButton
                      color="success"
                      style={{ minWidth: 118 }}
                      variant={finishDialogOpen ? "outlined" : "contained"}
                      onClick={handleSendShortcut}
                      inProgress={loading}
                    >
                      {t("ClimatePlan.SendShortcut")}
                    </CircularProgressButton>
                    <PwaDialog open={iosDialogOpened} handleClose={() => setIosDialogOpened(false)} />
                  </Grid>
                )}

                <Grid item>
                  <Button
                    style={{ minWidth: 118 }}
                    variant="contained"
                    color="success"
                    onClick={logOut}
                    startIcon={<Logout />}
                  >
                    {t("ClimatePlan.Buttons.Exit")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};
export default CO2InstallAppComponent;
