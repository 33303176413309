import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
  Link,
  Typography,
  Divider,
  useTheme, styled,
} from "@mui/material";
import { Rating } from "@mui/material";
import { CO2SponsorSolutionBasic } from "../../CO2Plan";
import { useTranslation } from "react-i18next";
import { ClimatPlanWidgetModel } from "../../ClimatePlanPage";
import { CO2Context } from "../CO2ContextWrapper";

type CO2TargetSponsorSolutionsProps = {
  groupId: string;
  personId: string | null;
  selectedSponsorSolutionId: string | null;
  sponsorSolutions: CO2SponsorSolutionBasic[];
};

const CO2TargetSponsorSolutionsComponent = (
  props: CO2TargetSponsorSolutionsProps
): JSX.Element => {
  const context = useContext(CO2Context);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { t } = useTranslation("translation");

  const handleSelectionChange = (solutionId: string) => {
    context.selectSponsorSolution(props.groupId, props.personId, solutionId);
  };

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      {!!props.sponsorSolutions.length && (
        <Grid
          container
          item
          direction="row"
          justifyContent={"center"}
          alignItems={"center"}
          my={2}
        >
          <Grid container>
            <FormControl style={{ width: "100%" }}>
              <InputLabel
                id={"sponsors-solutions-" + props.groupId + props.personId}
              >
                {t("ClimatePlan.TargetSurvey.GiveMoreRecommendations")} (
                {props.sponsorSolutions.length})
              </InputLabel>
              <Select
                MenuProps={{
                  PaperProps: {
                    sx: {
                      maxHeight: "250px",
                    },
                  }}}
                fullWidth
                labelId={"sponsors-solutions-" + props.groupId + props.personId}
                value={props.selectedSponsorSolutionId ?? ""}
                style={{
                  backgroundColor: "transparent",
                }}
                onChange={(e) =>
                  handleSelectionChange(e.target.value as string)
                }
                onOpen={() => setIsDropdownOpen(true)}
                onClose={() => setIsDropdownOpen(false)}
              >
                {props.sponsorSolutions.map((solution) => (
                  <StyledMenuItem value={solution.Id} key={solution.Id} sx={{maxWidth: 500}}>
                    <SponsoredSolutionDescription
                      solution={solution}
                      showDetails={isDropdownOpen}
                    />
                  </StyledMenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default CO2TargetSponsorSolutionsComponent;


const StyledMenuItem = styled(MenuItem)({
    paddingLeft: 10,
    paddingRight: 10,
});

type SponsoredSolutionDescriptionProps = {
  solution: CO2SponsorSolutionBasic;
  showDetails: boolean;
};
const SponsoredSolutionDescription = ({
  solution,
  showDetails,
}: SponsoredSolutionDescriptionProps): JSX.Element => {
  const { widgets, renderWidget, WidgetPage } = useContext(CO2Context);

  const widgetsToUse = useMemo(() => {
    return widgets?.filter((c) => c.Pages.includes(WidgetPage));
  }, [widgets]);
  const { t } = useTranslation("translation");
  const [targetWidget, setTargetWidget] = useState<ClimatPlanWidgetModel | null>(null);

  useEffect(() => {
    if (widgetsToUse) {
      const widgetsToShow = widgetsToUse.filter(
        (widget) => widget.Id === solution.WidgetId
      );
      if (widgetsToShow.length > 0) {
        setTargetWidget(widgetsToShow[0]);
      }
    }
  }, [widgets]);

  return (
    <Grid container direction="column">
        <Typography variant="body1">{solution.DisplayName}</Typography>
      {showDetails && (
        <>
            <Typography variant="body2">
              {t("ClimatePlan.TargetSurvey.Sponsors")}
            </Typography>

          {!!solution.CompanyName && (
            <Grid>
              <Typography variant="body2">{solution.CompanyName} </Typography>
              {!!solution.CompanyRating && (
                <Rating
                  value={solution.CompanyRating}
                  readOnly={true}
                  size="small"
                  style={{ marginLeft: 5 }}
                />
              )}
            </Grid>
          )}
          {!!solution.CompanyWebSite && (
              <Typography variant="body2">
                <Link
                  href={
                    solution.CompanyWebSite.toLocaleLowerCase().startsWith(
                      "http"
                    )
                      ? solution.CompanyWebSite
                      : "https://" + solution.CompanyWebSite
                  }
                  target="_blank"
                  onClick={(event: any) => event.stopPropagation()}
                >
                  {solution.CompanyWebSite}
                </Link>
              </Typography>
          )}
          {!!solution.CompanyPhone && (
              <Typography variant="body2">{solution.CompanyPhone}</Typography>
   
          )}
          {!!solution.CompanyCVR && (
              <Typography variant="body2">
                CVR: {solution.CompanyCVR}
              </Typography>
          )}
          {!!targetWidget && (
            <Grid sx={{maxWidth: "100%"}} onClick ={(event: any) => event.stopPropagation()}>
              {renderWidget && renderWidget(targetWidget, false)}
            </Grid>
          )}
          <Divider />
        </>
      )}
    </Grid>
  );
};
