import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { TextField, Grid, LinearProgress, styled, GridProps, Paper, FormControlLabel, Checkbox } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SchoolDto, UnitDto } from "../../../definitions/models";
import { TextFieldConnector } from "../../../components/Base/FormConnector";
import SchoolAutocomplete from "../../../components/Autocomplete/SchoolAutocomplete";
import UnitAutocomplete from "../../../components/Autocomplete/UnitAutocomplete";
import { AutocompleteUnitItem } from "../../../definitions/model/AutocompleteItem";

type DisabledChildrensProps = GridProps & {
  loading: boolean;
};

const DisabledChildrensGrid = styled(Grid, {
  shouldForwardProp: (propName) => propName !== "loading",
})<DisabledChildrensProps>(({ theme, loading }) => ({
  paddingTop: "15px",
  "& button, & button:hover": {
    cursor: loading ? "not-allowed" : "pointer",
    backgroundColor: loading ? theme.palette.grey[100] : theme.palette.primary,
  },
}));

export type AutocompleteStyles = {
  color: string;
  background: string;
};

type RegistrationFormProps = {
  setValueCallback?(
    unit: UnitDto | null,
    school: SchoolDto,
    email: string,
    classLabel?: string,
    name?: string,
    manager?: boolean
  ): void;
  children?: React.ReactNode;
  loading?: boolean;
  variant?: "outlined" | "filled" | "standard";
  color?: "primary" | "secondary" | "success" | "error" | "info" | "warning";
  unitValue?: UnitDto;
  schoolValue?: SchoolDto;
  classLabel?: string | null;
  withClassLabel?: boolean;
  name?: string;
  email: string;
  showManagerCheckbox: boolean;
};

type FormData = {
  unit?: UnitDto;
  school: SchoolDto;
  ClassLabel?: string;
  email: string;
  name?: string;
  manager: boolean;
};

const RegistrationForm = (props: RegistrationFormProps): JSX.Element => {
  const { t } = useTranslation("translation");

  const {
    handleSubmit,
    setValue,
    register,
    unregister,
    formState: { errors },
    clearErrors,
  } = useForm<FormData>();

  const submit = (data: FormData) => {
    if (props.setValueCallback) {
      props.setValueCallback(data.unit ?? null, data.school, data.email, data.ClassLabel, data.name, data.manager);
    }
  };

  useEffect(() => {
    if (props.unitValue) {
      setValue("unit", props.unitValue);
    }

    if (props.schoolValue) {
      setValue("school", props.schoolValue);
    }

    setValue("ClassLabel", props.classLabel || "");
    setValue("name", props.name || "");
    setValue("email", props.email || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.unitValue, props.schoolValue, props.classLabel]);

  useEffect(() => {
    register("school", { required: true });
    register("unit", { required: false });

    return () => {
      unregister("school");
      unregister("unit");
    };
  }, [register, unregister]);

  const OnUnitSelected = (event: React.ChangeEvent<{}>, val: string | AutocompleteUnitItem) => {
    if (typeof val !== "string") {
      clearErrors();
      setValue("unit", val.Unit);
    }
  };

  const OnSchoolSelected = (event: React.ChangeEvent<{}>, val: string | SchoolDto) => {
    if (typeof val !== "string") {
      clearErrors();
      setValue("school", val);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(submit)}>
        <TextFieldConnector register={register("email", { required: true })}>
          <TextField
            variant="outlined"
            fullWidth
            size="medium"
            placeholder={t("UserProfile.EmailAddress")}
            error={!!errors.email}
            sx={{ mb: 1 }}
          />
        </TextFieldConnector>
        <UnitAutocomplete
          initialValue={
            props.unitValue
              ? ({
                  Type: 2,
                  Text: props.unitValue.Address,
                  CursorPosition: props.unitValue.Address?.length,
                  Unit: props.unitValue,
                } as AutocompleteUnitItem)
              : undefined
          }
          onUnitSelected={OnUnitSelected}
          variant={props.variant ? props.variant : "outlined"}
          error={!!errors.school}
          paperComponent={CustomPaper}
        />
        <SchoolAutocomplete
          initialSchoolValue={props.schoolValue}
          OnSchoolSelected={OnSchoolSelected}
          variant={props.variant ? props.variant : "outlined"}
          error={!!errors.school}
          paperComponent={CustomPaper}
          commune={0}
        />
        {props.withClassLabel && (
          <TextFieldConnector register={register("ClassLabel", { required: false })}>
            <TextField
              variant="outlined"
              fullWidth
              size="medium"
              placeholder={t("UserProfile.ClassLabel")}
              sx={{ mb: 1 }}
            />
          </TextFieldConnector>
        )}
        {/* <TextFieldConnector register={register("name", { required: false })}>
          <TextField variant="outlined" fullWidth size="medium" placeholder={t("UserProfile.Name")} sx={{ mb: 1 }} />
        </TextFieldConnector> */}
        {props.showManagerCheckbox && (
          <FormControlLabel
            control={
              <TextFieldConnector register={register("manager", { required: false })}>
                <StyledCheckbox />
              </TextFieldConnector>
            }
            label={t("CO2Calculator.IAmTeacher")}
          />
        )}
        <DisabledChildrensGrid loading={false} item>
          {props.children}
        </DisabledChildrensGrid>
        {props.loading && <LinearProgress color="secondary" sx={{ marginTop: 1 }} />}
      </form>
    </>
  );
};

export default RegistrationForm;

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  "&.MuiButtonBase-root.MuiCheckbox-root": {
    color: theme.palette.success.contrastText,
  },
}));

//need to styled Paper component of Autocomplete
const CustomPaper = styled(Paper)(({ theme }) => ({
  marginRight: "-1px",
  marginLeft: "-1px",
}));
