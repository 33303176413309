import React, { useContext } from "react";
import { Grid, Typography } from "@mui/material";
import {
  CO2PersonEmissionSourceGroup,
  CO2SponsorSolutionBasic,
} from "../../CO2Plan";
import CO2TargetEmissionSourcesWrapper from "./CO2TargetEmissionSourcesWrapper";
import { CO2Context } from "../CO2ContextWrapper";

type CO2TargetPersonProps = {
  person: CO2PersonEmissionSourceGroup;
  groupId: string;
  displayTemplate: string | null;
  averageCO2Emission: number;
  goalCO2Emission: number;
  sponsorSolutions: CO2SponsorSolutionBasic[];
};

const CO2TargetPersonComponent = (props: CO2TargetPersonProps): JSX.Element => {
  const context = useContext(CO2Context);
  
  return (
    <Grid
      item
      container
      direction="column"
      xs={12}
      sm={10}
      md={7}
    >
      <Grid item>
      <Typography variant="h6" align="center">
        {context.IsSinglePersonPlan ? "" : props.person.Name}
      </Typography>
      </Grid>
      <Grid item container justifyContent="center">
      <CO2TargetEmissionSourcesWrapper
        groupId={props.groupId}
        displayTemplate={props.displayTemplate}
        highLevelSources={props.person.EmissionSources}
        personId={props.person.PersonId}
        CO2EmissionSaved={props.person.CO2EmissionSaved ?? 0}
        CO2Emission={props.person.CO2Emission}
        dueYear={props.person.DueYear}
        averageCO2Emission={props.averageCO2Emission}
        goalCO2Emission={props.goalCO2Emission}
        sponsorSolutions={props.sponsorSolutions}
        selectedSponsorSolutionId={props.person.CustomCalculationId}
      />
      </Grid>
    </Grid>
  );
};

export default CO2TargetPersonComponent;
