import { Grid, Typography, Link } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";


const WrapperGrid = styled(Grid)(({ theme}) => ({
  padding: "20px 0",
  background: theme.palette.background.default,
  height: 85,
}));

const FooterLink = styled(Link)(({ theme }) => ({
  color: "inherit",
  fontSize: 13,
  cursor: "pointer",
}));


type FooterProps = {
  transparent?: boolean;
};

const Footer = (props: FooterProps) => {
  const { t } = useTranslation("translation");

  return (
    <Grid
      container
      sx={{
        position: "relative",
        zIndex:999,
      }}
    >
      <WrapperGrid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="body2">
            <FooterLink href="https://voresklimaplan.dk/about-our-climate" target="_blank" underline="hover">
              {t("Footer.AboutClimatePlan")}
            </FooterLink>
            </Typography>
            </Grid>
      </WrapperGrid>
    </Grid>
  );
};

export default Footer;
