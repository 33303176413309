import React, { useEffect, useState } from "react";
import { CO2TimelineAction, ProgressBarColors } from "../../CO2Plan";
import { Grid, Typography, styled } from "@mui/material";
import CO2BorderLinearProgress from "../CO2BorderLinearProgress";
import _ from "lodash";
import { useTranslation } from "react-i18next";

type CO2ActionsChartProps = {
  timeline: CO2TimelineAction[];
};

type ChartRecord = {
  personId: string;
  personName: string;
  fulfilledActionsPercentage: number;
  notFulfilledActionsPercentage: number;
};

const CO2ActionsChartComponent = (props: CO2ActionsChartProps): JSX.Element => {
  const [records, setRecords] = useState<ChartRecord[]>([]);
  const { t } = useTranslation("translation");

  useEffect(() => {
    const grouppedActions = _.groupBy(props.timeline, "PersonId");
    const records = _.map(grouppedActions).map((actions) => {
      const totalActions = actions.length;
      const fulfilledActions = actions.filter((act) => act.IsFulfilled).length;
      const fulfilledActionsPercentage =
        (100 * fulfilledActions) / totalActions;
      return {
        personId: actions[0].PersonId,
        personName:
          actions[0].PersonName ||
          t("ClimatePlan.PersonsChart.Household").charAt(0).toUpperCase() +
            t("ClimatePlan.PersonsChart.Household").slice(1),
        fulfilledActionsPercentage: fulfilledActionsPercentage,
        notFulfilledActionsPercentage: 100 - fulfilledActionsPercentage,
      } as ChartRecord;
    });
    setRecords(records);
  }, [props.timeline]);

  const getColor = (record: ChartRecord) => {
    switch (true) {
      case record.fulfilledActionsPercentage >= 75:
        return ProgressBarColors.VeryGood;
      case record.fulfilledActionsPercentage >= 50:
        return ProgressBarColors.Good;
      case record.fulfilledActionsPercentage >= 25:
        return ProgressBarColors.Bad;
      default:
        return ProgressBarColors.VeryBad;
    }
  };

  return (
    <StyledGrid container direction="column" spacing={2}>
      {records.map((record, i) => (
        <Grid item container direction="column" key={record.personId +i}>
          <Typography variant="body1">{record.personName}</Typography>
          <CO2BorderLinearProgress
            value={record.fulfilledActionsPercentage}
            barColor={getColor(record)}
            title={record.personName}
          />
        </Grid>
      ))}
    </StyledGrid>
  );
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  "@media print": {
    transform: "translateX(15px)",
  },
}));
export default CO2ActionsChartComponent;
