import { Autocomplete, TextField } from "@mui/material";
import React, { useReducer, useRef } from "react";
import { useTranslation } from "react-i18next";
import { SchoolDto } from "../../definitions/models";
import { debounce } from "lodash";
import { useAutocompleteActions } from "../../helpers/hooks";

type SchoolAutocompleteProps = {
  initialSchoolValue?: SchoolDto;
  OnSchoolSelected: (event: React.ChangeEvent<{}>, val: string | SchoolDto) => void;
  variant: "standard" | "filled" | "outlined";
  paperComponent?: any;
  error?: boolean;
  commune: number;
};

type SchoolAutocompleteState = {
  options: SchoolDto[];
  loading: boolean;
  open: boolean;
  error: string;
  value: SchoolDto | undefined;
};

const initialState: SchoolAutocompleteState = {
  options: [],
  loading: false,
  open: false,
  error: "",
  value: undefined

};

const reducer = (state: SchoolAutocompleteState, action: { type: keyof SchoolAutocompleteState; payload: any }) => {
  return { ...state, [action.type]: action.payload };
};

const SchoolAutocomplete = (props: SchoolAutocompleteProps) => {
  const { t } = useTranslation();
  const { getSchools } = useAutocompleteActions();

  const [state, dispatch] = useReducer(reducer, initialState);

  const controllerRef = useRef<AbortController | null>(null);
  const fetchOptionsDebounced = debounce(async (val: string) => await fetchOptions(val), 400);

  const fetchOptions = async (val: string) => {
    if (val.length <= 3) {
      dispatch({ type: "options", payload: [] });
      return;
    }

    try {
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
      controllerRef.current = new AbortController();

      dispatch({ type: "loading", payload: true });
      const schools = await getSchools(val, 0, 10, "", false, props.commune, controllerRef.current.signal);
      if (schools?.length) {
        dispatch({ type: "options", payload: schools });
      }

      controllerRef.current = null;
    } catch (e) {
      dispatch({ type: "error", payload: true });
    } finally {
      dispatch({ type: "loading", payload: false });
    }
  };

  return (
    <Autocomplete
      //freeSolo
      PaperComponent={props.paperComponent}
      options={state.options}
      style={{ marginBottom: "10px" }}
      forcePopupIcon={false}
      disableClearable
      value={state.value || props.initialSchoolValue}
      // ? `${props.initialSchoolValue.Name}, ${props.initialSchoolValue.Address}, ${props.initialSchoolValue.City}` : ""}
      loading={state.loading}
      onChange={props.OnSchoolSelected}
      loadingText={t("Autocomplete.Loading")}
      noOptionsText={t("General.Errors.AddressNotFound")}
      getOptionLabel={(o) => {
        if (typeof o !== "string") {
          if (!o.Id) {
            return "";
          }
          return `${o.Name}, ${o.Address}`;
        }

        return o;
      }}
      renderInput={(params) => (
        <TextField
          placeholder={t("Autocomplete.PlaceholderSchool")}
          onChange={(e) => {
            fetchOptionsDebounced(e.target.value);
          }}
          error={!!props.error || !!state.error}
          variant={props.variant ? props.variant : "outlined"}
          helperText={state.error}
          {...params}
        />
      )}
    />
  );
};

export default SchoolAutocomplete;
