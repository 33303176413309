import { Button, styled } from "@mui/material";


type LoginButtonProps = {
  target?: string;
  rel?: string;
};

export type LinkComponentProps = {
  component?: React.ElementType;
  to?: string;
};


export const SquaredButton = styled(Button)<LinkComponentProps>(({ theme }) => ({
  //borderRadius: 0,
  padding: 1,
  minWidth: "auto",
}));

export const LoginButton = styled(SquaredButton)<LoginButtonProps>(({ theme }) => ({
    height: 38,
    padding: "0 40px",
    [theme.breakpoints.down("xs")]: {
      height: 38,
      padding: "0 20px",
      "& p": {
        fontSize: 10,
      },
    },
  }));
  