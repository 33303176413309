import { LanguageType } from "./../definitions/Menu";
import User, { UserProfileDTO } from "../definitions/model/User";
import { RegisterUserContract } from "../definitions/models";
import ApiService from "./api.service";
import { isLanguageType } from "../helpers/helpers";

const UserService = {
  logout: async () => {
    try {
      await ApiService.post<{ Id: string; RefreshToken: string; AccessToken: string }>("api/account/logout", null);
    } catch (error) {}
    localStorage.removeItem("user");
  },
  register: async (userData: RegisterUserContract): Promise<User> => {
    var auth = await ApiService.post<User>("api/account/register", userData); //<{ Id: string; RefreshToken: string; AccessToken: string }>
    handleUser(auth);
    const user = await ApiService.get<User>("/api/account");
    return handleUser(user);
  },
  login: async (username: string, password: string) => {
    localStorage.removeItem("user");
    var auth = await ApiService.post<User>("api/account/login", {
      Email: username,
      Password: password,
    });
    handleUser(auth);
    const user = await ApiService.get<User>("/api/account");
    return handleUser(user);
  },
  getProfile: async () => {
    const user = await ApiService.get<User>("/api/account");
    return handleUser(user);
  },
  changePassword: async (OldPassword: string, NewPassword: string) => {
    return await ApiService.post("/api/account/password-change", {
      OldPassword,
      NewPassword,
    });
  },
  updateUserProfile: async (userDto: UserProfileDTO) => {
    await ApiService.put<User>("/api/account", userDto);
    const user = await ApiService.get<User>("/api/account");
    return handleUser(user);
  },
  removeUser: async (): Promise<any> => {
    return await ApiService.delete("/api/account");
  },
  sendShortcutEmail: async (): Promise<any> => {
    return await ApiService.post("/api/co2plan/shortcut", null);
  },
  setLanguage: (language: LanguageType): void => {
    localStorage.setItem("language", language);
  },
  getLanguage: (): LanguageType => {
    const lang = localStorage.getItem("language");
    return isLanguageType(lang) ? (lang as LanguageType) : "da";
  },

  updateUserLanguage: async (language: LanguageType): Promise<User> => {
    await ApiService.put<User>("/api/account/updatelanguage", { Language: language });
    const user = await ApiService.get<User>("/api/account");
    return handleUser(user);
  },

  updateLocalStorageUser: (user: User): void => {
    localStorage.setItem(
      "user",
      JSON.stringify({
        ...user,
      })
    );
  },
};

function handleUser(user: User) {
  const localStorageUser = localStorage.getItem("user");
  const localStorageLang = isLanguageType(localStorage.getItem("language")) ? localStorage.getItem("language") : "da";

  //add AccessToken from local storage User if current user do not have it
  if (localStorageUser && user && !user.AccessToken) {
    const parsedUser = JSON.parse(localStorageUser) as User;
    user.AccessToken = parsedUser.AccessToken;
  }

  // login successful if there's a jwt token in the response
  if (user && user.AccessToken) {
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    localStorage.setItem("user", JSON.stringify(user));
    //for keep system language after logout
    localStorage.setItem("language", isLanguageType(user.Language) ? user.Language : localStorageLang!);
  }

  return user;
}

export default UserService;
