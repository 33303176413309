import { AppActionThunk } from "../definitions/Action";
import { UserConstants } from "../constants/user.constants";
import UserService from "../services/user.service";
import { operationFailedActionGeneral } from ".";
import { ThunkDispatch } from "redux-thunk";
import { ApplicationState } from "../reducers/store";
import { AnyAction } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { RegisterUserContract } from "../definitions/models";
import { UserProfileDTO } from "../definitions/model/User";
import { LanguageType } from "../definitions/Menu";

const operationFailedAction = (payload: unknown) =>
  operationFailedActionGeneral(payload, UserConstants.USER_OPERATION_FAILED);

const logout = (): AppActionThunk => async (dispatch) => {
  try {
    dispatch({ type: UserConstants.STOP_SIGNALR });
    dispatch({ type: UserConstants.LOGOUT });
    UserService.logout();
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const clearUserError = (): AppActionThunk => (dispatch) => {
  dispatch({ type: UserConstants.USER_OPERATION_FAILED, payload: null });
};

export const getUserOneFamily =
  (unitId: string) => async (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
    //
  };

const authorize =
  (username: string, password: string) => async (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
    try {
      dispatch({ type: UserConstants.LOGIN_REQUEST });
      if (!password) {
        password = username;
      }

      const user = await UserService.login(username, password);
      dispatch({ type: UserConstants.LOGIN_SUCCESS, payload: user });
      return user;
    } catch (error) {
      dispatch({ type: UserConstants.LOGIN_FAILURE });
      dispatch(operationFailedAction(error));

      throw error;
    }
  };

const authorizeLocal = () => async (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
  try {
    dispatch({ type: UserConstants.LOGIN_REQUEST });
    const user = await UserService.getProfile();
    dispatch({ type: UserConstants.LOGIN_SUCCESS, payload: user });
    return true;
  } catch (error) {
    dispatch({ type: UserConstants.LOGIN_FAILURE });
    dispatch(operationFailedAction(error));
    return false;
  }
};

const register =
  (userData: RegisterUserContract) => async (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
    try {
      dispatch({ type: UserConstants.REGISTER_REQUEST });
      const result = await UserService.register(userData);
      dispatch({ type: UserConstants.REGISTER_SUCCESS, payload: result });
      return result;
    } catch (error) {
      dispatch({ type: UserConstants.REGISTER_FAILURE, payload: error });
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

const tryRegister =
  (data: RegisterUserContract) => async (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
    try {
      await dispatch(register(data));
    } catch (error: any) {
      dispatch(operationFailedAction(error));
      throw error;
    }
  };

const changePassword =
  (OldPassword: string, NewPassword: string) => async (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
    dispatch({ type: UserConstants.CHANGE_USER_PASSWORD });
    try {
      await UserService.changePassword(OldPassword, NewPassword);
      dispatch({ type: UserConstants.CHANGE_USER_PASSWORD_SUCCEEDED });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

const updateUserProfile =
  (profileDto: UserProfileDTO) => async (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
    dispatch({ type: UserConstants.UPDATE_USER_PROFILE });
    try {
      const updatedUser = await UserService.updateUserProfile(profileDto);

      dispatch({
        type: UserConstants.UPDATE_USER_PROFILE_SUCCEEDED,
        payload: updatedUser,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

const setRegistrationData =
  (unitId: string | null, schoolId: string, email: string, classLabel?: string) =>
  (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
    dispatch({ type: UserConstants.SET_REG_DATA, payload: { unitId, schoolId, email, classLabel } });
  };

export const setPwaOptions = (pwaOptions: any) => (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
  dispatch({ type: UserConstants.SET_PWA_OPTIONS, payload: pwaOptions });
};

export const removeUser = (): AppActionThunk<Promise<any>> => async (dispatch) => {
  dispatch({ type: UserConstants.REMOVE_USER });
  try {
    await UserService.removeUser();
    dispatch({ type: UserConstants.REMOVE_USER_SUCCEEDED });
    dispatch({ type: UserConstants.TRIGGER_LOGOUT });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

export const sendShortcutEmail = (): AppActionThunk<Promise<any>> => async (dispatch) => {
  try {
    dispatch({ type: UserConstants.SEND_EMAIL_SHORTCUT });
    await UserService.sendShortcutEmail();
    dispatch({ type: UserConstants.SEND_EMAIL_SHORTCUT_SUCCEEDED });
  } catch (error) {
    dispatch(operationFailedAction(error));
  }
};

const setClimatePlanData = (planId: string) => (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
  dispatch({ type: UserConstants.SET_CLIMATE_PLAN_DATA, payload: planId });
};

export const setLanguage =
  (language: LanguageType): AppActionThunk<void> =>
  (dispatch) => {
    try {
      UserService.setLanguage(language);
      dispatch({
        type: UserConstants.SET_LANGUAGE,
        payload: language,
      });
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

export const getLanguage = (): AppActionThunk<LanguageType> => (dispatch) => {
  const lang = UserService.getLanguage();

  dispatch({
    type: UserConstants.GET_LANGUAGE,
    payload: lang,
  });
  return lang;
};

export const updateUserLanguage =
  (lang: LanguageType) => async (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
    dispatch({ type: UserConstants.UPDATE_USER_LANGUAGE });
    try {
      const result = await UserService.updateUserLanguage(lang);
      dispatch({
        type: UserConstants.UPDATE_USER_LANGUAGE_SUCCEEDED,
        payload: result,
      });
      return result;
    } catch (error) {
      dispatch(operationFailedAction(error));
    }
  };

const openLoginDialog = () => (dispatch: ThunkDispatch<ApplicationState, any, AnyAction>) => {
  dispatch({ type: UserConstants.OPEN_LOGIN_DIALOG });
};

const useUserState = () => useSelector((state: ApplicationState) => state.user);

const useUserActions = () => {
  const dispatch = useDispatch<ThunkDispatch<ApplicationState, any, AnyAction>>();
  return {
    clearUserError: () => dispatch(clearUserError()),
    authorize: (username: string, password: string) => dispatch(authorize(username, password)),
    registerUser: (userData: RegisterUserContract) => dispatch(register(userData)),
    logout: () => dispatch(logout()),
    getUserOneFamily: (unitId: string) => dispatch(getUserOneFamily(unitId)),
    setRegistrationData: (unitId: string | null, schoolId: string, email: string, classLabel?: string) =>
      dispatch(setRegistrationData(unitId, schoolId, email, classLabel)),
    authorizeLocal: () => dispatch(authorizeLocal()),
    changePassword: (oldPassword: string, newPassword: string) => dispatch(changePassword(oldPassword, newPassword)),
    updateUserProfile: (profileDto: UserProfileDTO) => dispatch(updateUserProfile(profileDto)),
    setPwaOptions: (pwaOptions: any) => dispatch(setPwaOptions(pwaOptions)),
    removeUser: () => dispatch(removeUser()),
    sendShortcutEmail: () => dispatch(sendShortcutEmail()),
    setClimatePlanData: (planId: string) => dispatch(setClimatePlanData(planId)),
    tryRegister: (data: RegisterUserContract) => dispatch(tryRegister(data)),
    setLanguage: (language: LanguageType) => dispatch(setLanguage(language)),
    getLanguage: () => dispatch(getLanguage()),
    updateUserLanguage: (lang: LanguageType) => dispatch(updateUserLanguage(lang)),
    openLoginDialog: () => dispatch(openLoginDialog()),
  };
};

export const useUser = (): [ReturnType<typeof useUserState>, ReturnType<typeof useUserActions>] => {
  const state = useUserState();
  const actions = useUserActions();

  return [state, actions];
};
