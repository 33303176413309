import React from "react";
import { CO2EmissionSource, CO2SponsorSolutionBasic } from "../../CO2Plan";
import CO2TargetEmissionSourcesComponent from "./CO2TargetEmissionSourcesComponent";
import CO2TargetSponsorSolutionsComponent from "./CO2TargetSponsorSolutionsComponent";
import CO2TargetEmissionSourcesProgress from "./CO2TargetEmissionSourcesProgress";
import { Grid } from "@mui/material";

type CO2TargetEmissionSourcesWrapperProps = {
  groupId: string;
  displayTemplate: string | null;
  highLevelSources: CO2EmissionSource[];
  personId: string | null;
  CO2EmissionSaved: number;
  CO2Emission: number;
  dueYear: number | null;
  averageCO2Emission: number;
  goalCO2Emission: number;
  sponsorSolutions: CO2SponsorSolutionBasic[];
  selectedSponsorSolutionId: string | null;
};

const CO2TargetEmissionSourcesWrapper = (props: CO2TargetEmissionSourcesWrapperProps): JSX.Element => {
  return (
    <Grid item spacing={2} container xs={12} sm={10} md={9} style={{ maxWidth: 430 }}>
      <CO2TargetEmissionSourcesProgress
        CO2EmissionSaved={props.CO2EmissionSaved}
        CO2Emission={props.CO2Emission}
        averageCO2Emission={props.averageCO2Emission}
        goalCO2Emission={props.goalCO2Emission}
      />
      <CO2TargetEmissionSourcesComponent
        highLevelSources={props.highLevelSources}
        personId={props.personId}
        displayTemplate={props.displayTemplate}
      />
      {props.sponsorSolutions && (
        <CO2TargetSponsorSolutionsComponent
          groupId={props.groupId}
          selectedSponsorSolutionId={props.selectedSponsorSolutionId}
          personId={props.personId}
          sponsorSolutions={props.sponsorSolutions}
        />
      )}

      {/* <Grid item container direction="column">
          {!!props.CO2EmissionSaved && (
            <>
              <Grid
                container
                spacing={1}
                alignItems="center"
                style={{ marginTop: 5 }}
                justifyContent="space-between"
              >
                <Grid item xs={3}>
                  <Typography variant="body1">Opfyldt i</Typography>
                </Grid>
                <Grid item xs={9}>
                  <Select
                    native
                    fullWidth
                    defaultValue={props.dueYear ?? new Date().getFullYear()}
                    onChange={(event) =>
                      handleDueYearChange(event.target.value as number)
                    }
                  >
                    {Array.from(
                      { length: 20 },
                      (_, i) => i + new Date().getFullYear()
                    ).map((year) => (
                      <option value={year} key={year}>
                        {year}
                      </option>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </>
          )}

        </Grid> */}
    </Grid>
  );
};

export default CO2TargetEmissionSourcesWrapper;
