import React, { useState } from "react";
import { Drawer, Grid, Typography, IconButton } from "@mui/material";
import { Menu } from "@mui/icons-material";

import { useNavigate } from "react-router";
import { useUser } from "../../actions/user.actions";
import { TopBarProps } from "./TopBar";
import UserAvatar from "./UserAvatar";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { UserRole } from "../../definitions/model/User";
import { LoginButton } from "./Buttons";
import { OffIcon } from "../Icons/CheckboxIcons";

const DrawerHeader = styled(Grid)(({ theme }) => ({
  padding: "7px 22px",
}));

const DrawerProfile = styled(Grid)(({ theme }) => ({
  background: theme.palette.background.default,
  padding: "15px 22px 20px 22px",
  flex: 1,
  justifyContent: "center"
}));

const ProfileImg = styled(UserAvatar)(({ theme }) => ({
  width: 60,
  height: 60,
  fontSize: 30,
  borderRadius: "50%",
  margin: "8px 0 16px",
}));


type HamburgerProps = {
  isAdmin?: boolean;
  craftsmensLoginUrl?: string;
  registerClick?: () => void;
  loginClick?: () => void;
};

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: "100vw",
    backgroundColor: theme.palette.background,
    color: theme.palette.primary.contrastText,
  },
}));

const HamburgerMenu = (props: HamburgerProps & TopBarProps): JSX.Element => {
  const [{ user }, actions] = useUser();

  const navigate = useNavigate();
  const { t } = useTranslation("translation");
  const [open, setOpen] = useState(false);
  const closeDrawer = () => setOpen(false);
  const openDrawer = () => setOpen(true);

  const logOut = () => {
    actions.logout();
    navigate("/");
  };

  return (
    <>
      <Grid container alignItems="center" sx={{ width: "auto" }}>
        <IconButton size="small" onClick={openDrawer}>
          <Menu color={props.isAdmin ? "inherit" : "primary"} />
        </IconButton>
        <StyledDrawer anchor="right" open={open} onClose={closeDrawer}>
          <DrawerHeader container justifyContent="space-between" alignItems="center">
            <Typography>{t("Menu.KlimatPlan")}</Typography>
            <OffIcon fontSize="large" spacing={2} onClick={closeDrawer} />
          </DrawerHeader>
          <DrawerProfile container direction="column" alignItems="center" onClick={() => user && closeDrawer()}>
            {user ? (
              <>
                <Typography variant="body2">{t("Menu.SignedIn")}</Typography>
                <Typography variant="body2">{user?.Name}</Typography>
                <ProfileImg user={user}></ProfileImg>
                <LoginButton fullWidth color="primary"  variant="contained" onClick={() => navigate("/profile")}>
                  {t("Menu.EditProfile")}
                </LoginButton>
                {user?.UserRole === UserRole.Admin && (
                  <LoginButton fullWidth color="primary"  variant="contained" sx={{ my: 1}}  onClick={() => navigate("/admin")}>
                    {t("Menu.Admin")}
                  </LoginButton>
                )}
                {user?.UserRole === UserRole.Manager && (
                  <LoginButton fullWidth color="primary" variant="contained" sx={{ my: 1}}  onClick={() => navigate("/statistics")}>
                    {t("Menu.Statistics")}
                  </LoginButton>
                )}
                <LoginButton fullWidth color="secondary" variant="contained" sx={{ my: 1}}  onClick={logOut}>
                  {t("Menu.LogOut")}
                </LoginButton>
              </>
            ) : (
              <>
                <LoginButton fullWidth onClick={props.registerClick} variant="outlined" sx={{ my: 1}} >
                  <Typography variant="body1">{t("General.Buttons.Create")}</Typography>
                </LoginButton>
                <LoginButton fullWidth onClick={props.loginClick} color="secondary" variant="contained">
                  <Typography color="inherit" variant="body1">
                    {t("Menu.HomeownerLogin")}
                  </Typography>
                </LoginButton>
              </>
            )}
          </DrawerProfile>
        </StyledDrawer>
      </Grid>
    </>
  );
};

export default HamburgerMenu;
