import React, { useContext } from "react";
import { TextField, Grid, Typography, Paper } from "@mui/material";
import { CO2EmissionSource } from "../../CO2Plan";
import { CO2Context } from "../CO2ContextWrapper";
import { useTranslation } from "react-i18next";

type CO2ValueSourceComponentProps = {
  source: CO2EmissionSource;
  sources: CO2EmissionSource[];
  personId: string | null;
};

const CO2ValueComponent = (props: CO2ValueSourceComponentProps) => {
  const { source, sources, personId } = props;
  const context = useContext(CO2Context);
  const { t } = useTranslation("translation");

  const handleValueChange = (val: number) => {
    sources.forEach((s) => {
      context.addValueSource(s.Id, personId, val, s.CombinedValue, false);
    });
  };

  return (
    <>
      {!!source?.Question && (
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Typography my={3} align="center" variant="h6">
          {t(source.Question)}
          </Typography>
          {/*source with value:*/}
          <TextField
            type="number"
            variant="outlined"
            size="small"
            style={{ marginLeft: 10, width: 80 }}
            defaultValue={source.Value}
            onChange={(event) => {
              const val =
                parseInt(event.target.value) < 0 ? "0" : event.target.value;
              handleValueChange(parseInt(val) || 0);
            }}
          ></TextField>
        </Grid>
      )}
    </>
  );
};

export default CO2ValueComponent;
