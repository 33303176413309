import React, { useState, useContext, useEffect } from "react";
import { Typography, Grid } from "@mui/material";
import { CO2EmissionSource, CO2EmissionSourceOption } from "../../CO2Plan";
import { CO2Context } from "../CO2ContextWrapper";
import { useTranslation } from "react-i18next";
import { StyledListItem } from "../StyledListItem";

type CO2EmissionSourceWithOptionsComponentProps = {
  source: CO2EmissionSource;
  personId: string | null;
};

const CO2EmissionSourceOptionsComponent = (props: CO2EmissionSourceWithOptionsComponentProps) => {
  const { source, personId } = props;
  const context = useContext(CO2Context);
  const [optionsExpanded, expandOptions] = useState(source.IsSelected);
  const { t } = useTranslation("translation");

  useEffect(() => {
    expandOptions(source.IsSelected);
  }, [source]);

  return (
    <>
      {/*source with options:*/}
      {source.HasOptions && source.Options && optionsExpanded && (
        <>
          {source.Question && (
            <Grid item margin="0 auto" width="100%">
              <Typography
                my={3}
                align="center"
                variant="h4"
                sx={{
                  margin: "40px 30px 30px",
                }}
              >
                {t(source.Question)}
              </Typography>
            </Grid>
          )}
            <Grid item container direction="row" spacing={2}>
              {source.Options.map((option: CO2EmissionSourceOption) => (
                <Grid key={option.Id} item xs={12} sm={6}>
                  <StyledListItem
                    key={option.Id}
                    selected={option.Id === source.SelectedInitialOptionId || option.Id === source.SelectedOptionId}
                    onClick={() => context.addOptionSource(source.Id, personId, option.Id)}
                  >
                    <Typography color="inherit" variant="body1">
                      {" "}
                      {t(option.Name)}{" "}
                    </Typography>
                  </StyledListItem>
                </Grid>
              ))}
            </Grid>
        </>
      )}
    </>
  );
};

export default CO2EmissionSourceOptionsComponent;

