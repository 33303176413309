import { PaletteColorOptions, PaletteColor, responsiveFontSizes } from "@mui/material/styles";
import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
  interface PaletteOptions {
    facebook?: PaletteColorOptions;
    green?: PaletteColorOptions;
    yellow?: PaletteColorOptions;
  }
  interface Palette {
    facebook: PaletteColor;
    green: PaletteColor;
    yellow: PaletteColor;
    [key: string]: any;
  }
}

declare module "@mui/material/styles/createPalette" {
  interface TypeBackground {
    gray?: string;
    grayPaper?: string;
    lightPaper?: string;
  }
}

export type colorType = "primary" | "secondary" | "info" | "green" | "yellow" | "facebook" | null | undefined;

export const getColorByType = (type: colorType): PaletteColor => (type ? theme.palette[type] : theme.palette.primary);

// declare module "@mui/styles/defaultTheme" {
//   // eslint-disable-next-line @typescript-eslint/no-empty-interface
//   interface DefaultTheme extends Theme {}
// }

/**
 * DO NOT IMPORT IT DIRECTLY EXCEPT OF TO THE THEME PROVIDER IN APP.TSX
 */
const climateTheme = createTheme({
  shape: {
    borderRadius: 10,
  },
  palette: {
    primary: {
      main: "#cdcbed",
      dark: "#353d1c",
      contrastText: "#141344",
    },
    secondary: {
      main: "#cebaff",
      light: "#efe9ff",
      dark: "#9480c7",
      contrastText: "#fff",
    },
    background: {
      default: "#f8f7ff",
      gray: "#efefef",
      paper: "#ffffff",
      grayPaper: "#e7e8fd",
      lightPaper: "#f8f7ff",
    },

    divider: "rgba(255, 255, 255, 0.38)",
    info: {
      main: "#a0ed6e",
    },
    success: {
      main: "#a0ed6e",
      contrastText: "#fff",
      light: "#32c11140",
    },
    facebook: {
      main: "#3b5a99",
      dark: "#00316a",
      light: "#6d87ca",
      contrastText: "#fff",
    },
    green: {
      main: "#1c4b15",
      light: "#49773e",
      dark: "#002300",
      contrastText: "#fff",
    },
    yellow: {
      main: "#a17f34",
      contrastText: "#fff",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: "'CircularXX-Book', sans-serif",
    allVariants: {
      fontFamily: "CircularXX-Book",
      color: "#000000",
    },
    h1: {
      fontSize: 28,
      padding: "20px 0",
      color: "#000000",
    },
    h2: {
      fontSize: 26,
      fontFamily: "CircularXX-Bold",
      lineHeight: 1.5,
      padding: "10px 0",
    },
    h3: {
      padding: "10px 0",
      fontSize: 24,
      lineHeight: 1.5,
      fontFamily: "CircularXX-Bold",
    },
    h4: {
      padding: "10px 0",
      fontSize: 22,
      lineHeight: 1.5,
    },
    h5: {
      padding: "10px 0",
      fontFamily: "CircularXX-Bold",
      fontSize: 20,
      lineHeight: 1.5,
    },
    h6: {
      padding: "10px 0",
      fontSize: 16,
      lineHeight: 1.5,
      fontFamily: "CircularXX-Bold",
    },
    body1: {
      fontSize: 14,
      lineHeight: 1.5,
      padding: "2px 0",
    },
    body2: {
      fontSize: 14,
      padding: "3px 0",
    },
    subtitle1: {
      fontSize: 14,
      lineHeight: 1.5,
      padding: "2px 0",
      fontFamily: "CircularXX-Bold",
    },
    subtitle2: {
      fontSize: 13,
      lineHeight: 1.3,
      padding: "3px 0",
      fontFamily: "CircularXX-Bold",
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
          padding: 0,
          "&:before": {
            borderBottom: "unset !important",
          },
        },
        input: {
          borderBottom: "1px solid rgba(135, 135, 135, 0.38)",
          "&.Mui-disabled": {
            WebkitTextFillColor: "rgba(135, 135, 135, 0.38)",
          },
          "&:-webkit-autofill": {
            backgroundColor: "transparent !important",
            webkitBoxShadow: "white  1000px transparent inset",
            transition: "background-color 5000s ease-in-out 0s",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          maxWidth: 210,
          minWidth: 80,
          borderRadius: 10,
          textTransform: "none",
          fontSize: 14,
          fontFamily: "CircularXX-Book",
          "&.Mui-disabled": {
            opacity: 0.4,
          },
        },
        contained: {
          color: "#141344",
        },
      },
      defaultProps: {
        disableElevation: true,
        size: "large",
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "inherit",
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "none",
        color: "secondary",
      },
      styleOverrides: {
        root: {
          cursor: "pointer",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: "1px solid rgba(135, 135, 135, 0.38)",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          borderBottom: "none",
          borderRadius: 10,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          color: "#000",
        },
      },
      defaultProps: {
        elevation: 0,
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: "standard",
        disableUnderline: true,
      },
      styleOverrides: {
        select: {
          borderBottom: "1px solid rgba(135, 135, 135, 0.38)",
          "&.Mui-disabled": {
            WebkitTextFillColor: "rgba(135, 135, 135, 0.38)",
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "standard",
      },
    },
    MuiPaper: {
      styleOverrides: {
        outlined: {
          border: "1px solid transparent",
        },
        root: {
          borderRadius: 10,
        },
      },
      defaultProps: {
        variant: "outlined",
      },
    },

    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          ".MuiAlert-icon": {
            color: "inherit",
          },
        },
        standardInfo: {
          backgroundColor: "#a0ed6e",
        },
        standardSuccess: {
          backgroundColor: "#a0ed6e",
        },
        standardError: {
          backgroundColor: "#fa414185",
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

export const theme = responsiveFontSizes(climateTheme);
