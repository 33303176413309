import React, { useReducer, useRef, useState } from "react";
import { useAutocompleteActions } from "../../helpers/hooks";
import { useTranslation } from "react-i18next";
import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import { AutocompleteUnitItem, AutocompleteResultType } from "../../definitions/model/AutocompleteItem";
import { ArrowDownward } from "@mui/icons-material";

type UnitAutocompleteProps = {
  initialValue?: AutocompleteUnitItem;
  onUnitSelected: (event: React.ChangeEvent<{}>, val: string | AutocompleteUnitItem) => void;
  variant: "standard" | "filled" | "outlined";
  paperComponent?: any;
  error?: boolean;
};

type UnitAutocompleteState = {
  options: AutocompleteUnitItem[];
  loading: boolean;
  open: boolean;
  error: string;
  value: AutocompleteUnitItem | undefined;
  searchTargetType?: AutocompleteResultType;
};

const initialState: UnitAutocompleteState = {
  options: [],
  loading: false,
  open: false,
  error: "",
  value: undefined,
};

const reducer = (state: UnitAutocompleteState, action: { type: keyof UnitAutocompleteState; payload: any }) => {
  return { ...state, [action.type]: action.payload };
};

const UnitAutocomplete = (props: UnitAutocompleteProps) => {
  const { t } = useTranslation();
  const { getUnits } = useAutocompleteActions();
  const [open, setOpen] = useState(false);
  const targetType = AutocompleteResultType.House;
  const [state, dispatch] = useReducer(reducer, initialState);
  const controllerRef = useRef<AbortController | null>(null);

  function OnAutocompleteChange(
    // eslint-disable-next-line @typescript-eslint/ban-types
    event: React.ChangeEvent<{}>,
    val: string
  ) {
    if (state.options.length > 0 && state.options.some((o) => o.Text === val)) {
      const currentItem = state.options.find((o) => o.Text === val);
      if (!currentItem) return;
      if (currentItem.Type === targetType) {
        dispatch({ type: "value", payload: currentItem });
        setOpen(false);
      } else {
        fetchOptions(currentItem?.Text, true);
        setOpen(true);
      }
    } else {
      fetchOptions(val, false);
      setOpen(true);
    }
  }

  const fetchOptions = async (val: string, startFromBuildings: boolean = false) => {
    if (val.length <= 3) {
      dispatch({ type: "options", payload: [] });
      dispatch({ type: "value", payload: undefined });
      return;
    }

    try {
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
      controllerRef.current = new AbortController();

      dispatch({ type: "loading", payload: true });

      const options = await getUnits(val, 0, 10, "", false, startFromBuildings, controllerRef.current.signal);
      if (options) {
        dispatch({ type: "options", payload: options });
      }

      controllerRef.current = null;
    } catch (e) {
      dispatch({ type: "error", payload: true });
    } finally {
      dispatch({ type: "loading", payload: false });
    }
  };

  const RenderOption = (props: any, option: AutocompleteUnitItem | string) => {
    if (typeof option === "string") {
      return;
    }

    return (
      <Grid container direction="row" {...props}>
        <Grid item xs={11}>
          <Typography noWrap>{option.Text}</Typography>
        </Grid>
        <Grid item xs={1}>
          {option.Type !== targetType && <ArrowDownward color="action" fontSize="small" />}
        </Grid>
      </Grid>
    );
  };

  return (
    <Autocomplete
      PaperComponent={props.paperComponent}
      options={state.options}
      style={{ marginBottom: "10px" }}
      forcePopupIcon={false}
      disableClearable
      value={state.value}
      defaultValue={props.initialValue}
      loading={state.loading}
      onChange={props.onUnitSelected}
      onInputChange={OnAutocompleteChange}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      filterOptions={(x) => x}
      loadingText={t("Autocomplete.Loading")}
      noOptionsText={t("General.Errors.AddressNotFound")}
      renderOption={RenderOption}
      disableCloseOnSelect={true}
      open={open}
      freeSolo
      getOptionLabel={(o) => {
        if (typeof o !== "string") {
          return `${o.Text}`;
        }

        return o;
      }}
      renderInput={(params) => (
        <TextField
          placeholder={t("Autocomplete.PlaceholderUnit")}
          error={!!props.error || !!state.error}
          variant={props.variant ? props.variant : "outlined"}
          helperText={state.error}
          {...params}
        />
      )}
    />
  );
};

export default UnitAutocomplete;
