import { AutocompleteUnitItem } from "../definitions/model/AutocompleteItem";
import { SchoolDto } from "../definitions/models";
import { abortedApiRequest } from "./api.service";

const AutocompleteService = {
  getUnits: async (
    filter: string,
    skip: number,
    take: number,
    sortField: string,
    descending: boolean,
    startFromBuildings: boolean,
    signal?: AbortSignal
  ): Promise<Array<AutocompleteUnitItem>> => {
    const url = `/autocomplete/units?filter=${filter}&skip=${skip}&take=${take}&sortField=${sortField}&descending=${descending}&startFromBuildings=${startFromBuildings}`;
    const result = await abortedApiRequest<AutocompleteUnitItem[]>(url, signal || null);
    return result;
  },
  getSchools: async (
    filter: string,
    skip: number,
    take: number,
    sortField: string,
    descending: boolean,
    commune: number,
    signal?: AbortSignal
  ): Promise<Array<SchoolDto>> => {
    let url = `/autocomplete/schools?filter=${filter}&skip=${skip}&take=${take}&sortField=${sortField}&descending=${descending}`;
    if (commune !== 0) {
      url += "&CommuneNumber=" + commune;
    }
    const result = await abortedApiRequest<SchoolDto[]>(url, signal || null);
    return result;
  },
};

export default AutocompleteService;
