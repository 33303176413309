export const handleResponse = <T>(response: Response): Promise<T> =>
  new Promise((resolve, reject) => {
    if (response.ok) {
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        response.json().then(resolve);
      }
      // else {
      //   resolve();
      // }
    } else {
      response.text().then(reject);
    }
  });

export const handleError = (error: any) => {
  return Promise.reject(error && error.message);
};

let refreshTokenPromise: any;

export const headers = async () => {
  const headerOptions: any = {
    "Content-Type": "application/json",
  };

  const user = localStorage.getItem("user");
  if (user !== null) {
    const parsedUser: { AccessToken: string } = JSON.parse(user);
    let accessToken = "";
    let expDate = new Date(Date.now() - 10000);
    if (parsedUser) {
      if (parsedUser.AccessToken){
      accessToken = parsedUser.AccessToken;
      const base64Url = accessToken.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
  
      const decoded = JSON.parse(jsonPayload);
      expDate = new Date(decoded.exp * 1000);
    };

      if (expDate < new Date(Date.now() + 5 * 60000)) {
        if (!refreshTokenPromise) {
          refreshTokenPromise = refreshToken()
            .then((r) => {
              refreshTokenPromise = null; // clear state
              return r; // resolve with the new token
            })
            .catch(() => localStorage.removeItem("user"));
        }

        let refreshResponse = await refreshTokenPromise;

        if (refreshResponse.status === 200) {
          const newUser = await refreshResponse.json();
          accessToken = newUser.AccessToken;
          localStorage.setItem("user", JSON.stringify(newUser));
        }
      }

      headerOptions["Authorization"] = `Bearer ${accessToken}`;
    }
  }

  return headerOptions;
};

const refreshToken = (): Promise<Response> => {
  return fetch(process.env.REACT_APP_API + "/api/account/refresh", {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  });
};
