import React from "react";
import BaseDialog, { BaseDialogProps } from "./BaseDialog";
import Policy from "../../pages/Contact/components/Policy";

const DataPoliticDialog = ({
  closeDialog,
  open,
  ...other
}: BaseDialogProps): JSX.Element => {
  return (
    <BaseDialog
      open={open}
      closeDialog={closeDialog}
      titleComponent="Brugervilkår, Privatlivspolitik & Cookies"
      {...other}
    >
      <Policy />
    </BaseDialog>
  );
};

export default DataPoliticDialog;
