import React, { useState, useEffect, useContext } from "react";
import {
  Typography,
  Grid,
  useMediaQuery,
  Card,
  CardContent,
  useTheme,
  FormControlLabel,
  Switch,
  styled,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import { CO2EmissionSurvey, CO2Plan, HistoricalEmissionRecord } from "../../CO2Plan";
import CO2EmissionChartComponent, { GroupToShow } from "../CO2EmissionChartComponent";
import { convertToCurency } from "../../../../../services/converter.service";
import CO2PersonsChartComponent from "../CO2PersonsChartComponent";
import { Trans, useTranslation } from "react-i18next";
import BarometerKlimaplan from "../BarometerKlimaplan";
import ButtonsComponent from "../ButtonsComponent";
import CO2TargetResultsFinishComponent from "./CO2TargetResultsFinishComponent";
import CO2TargetSurveyEconomyTable from "./CO2TargetSurveyEconomyTable";
import { ClimatPlanUser } from "../../ClimatePlanPage";
import {
  getBarometerValue,
  getCurrenUserEmission,
  getHouseBarometerValue,
  getSurveyPersonsEmission,
} from "../../co2-helpers";
import CO2EmissionHistoryChartComponent from "../CO2EmissionHistoryChartComponent";
import { CO2Context } from "../CO2ContextWrapper";

type CO2TargetSurveyResultProps = {
  co2Plan: CO2Plan;
  showActionsTimeLine: () => void;
  showTargetSurvey: () => void;
  showPersonalChart: boolean;
  user: ClimatPlanUser | null;
  persons: GroupToShow[];
  initialUserEmission: number;
  targetUserEmission: number;
  initialHouseholdEmission: number;
  targetHouseholdEmission: number;
  showEconomyItems: boolean;
};

const CO2TargetSurveyResultComponent = (props: CO2TargetSurveyResultProps) => {
  const { t } = useTranslation("translation");
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("md"));
  const isMd = useMediaQuery(theme.breakpoints.down("lg"));
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const context = useContext(CO2Context);
  const [showBarometer, setShowBarometer] = useState(true);
  const [showHouseBarometer, setShowHouseBarometer] = useState(true);
  const [showChart, setShowChart] = useState(true);

  const handleBarometerChange = () => {
    setShowBarometer((prev) => !prev);
  };

  const handleHouseBarometerChange = () => {
    setShowHouseBarometer((prev) => !prev);
  };
  const handleChartChange = () => {
    setShowChart((prev) => !prev);
  };

  const [initialHouseholdEmission, setInitialHouseholdEmission] = useState(0);
  const [targetHouseholdEmission, setTargetHouseholdEmission] = useState(0);

  const [historicalHouseholdEmissions, setHistoricalHouseholdEmissions] = useState<HistoricalEmissionRecord[]>([]);
  const [selectedHistoricalYearPerson, setSelectedHistoricalYearPerson] = useState<number>(new Date().getFullYear());
  const [selectedHistoricalYearHousehold, setSelectedHistoricalYearHousehold] = useState<number>(
    new Date().getFullYear()
  );
  const [selectedHistoricalYearChart, setSelectedHistoricalYearChart] = useState<number>(new Date().getFullYear());

  useEffect(() => {
    const initialEmission = getEmisison(props.co2Plan.InitialSurvey);
    setInitialHouseholdEmission(initialEmission + props.co2Plan.UnchangeableBasicEmission);
  }, [props.co2Plan.InitialSurvey]);

  useEffect(() => {
    const targetEmission = getEmisison(props.co2Plan.TargetSurvey);
    setTargetHouseholdEmission(targetEmission + props.co2Plan.UnchangeableBasicEmission);
  }, [props.co2Plan.TargetSurvey]);

  useEffect(() => {
    if (props.co2Plan.InitialSurveyHistory?.length > 0) {
      const historicalEmissions = props.co2Plan.InitialSurveyHistory.map((survey) => {
        const newPersons = getSurveyPersonsEmission(survey, props.co2Plan.UnchangeableBasicEmission);
        const currentPersonEmission = getCurrenUserEmission(newPersons, props.user);

        return {
          yearLabel: survey.Year?.toString() || "",
          year: survey.Year || 0,
          emissions:
            survey.Categories.map((cat) => cat.CO2Emission).reduce((a, b) => a + b) +
            props.co2Plan.UnchangeableBasicEmission,
          currentUserEmission: currentPersonEmission,
        };
      });

      // add the current year
      historicalEmissions.push({
        yearLabel: t("ClimatePlan.TargetSurveyResult.Latest"),
        year: new Date().getFullYear(),
        emissions: initialHouseholdEmission,
        currentUserEmission: props.initialUserEmission,
      });
      setHistoricalHouseholdEmissions(historicalEmissions);
    }
  }, [props.co2Plan, initialHouseholdEmission]);

  const getEmisison = (survey: CO2EmissionSurvey) => {
    return survey.Categories.map((cat) => cat.CO2Emission).reduce((a, b) => a + b);
  };

  const getUserEmissionToUse = () => {
    if (showBarometer) {
      return props.targetUserEmission;
    }

    const currentYear = new Date().getFullYear();
    if (historicalHouseholdEmissions.length == 0 || selectedHistoricalYearPerson == currentYear) {
      return props.initialUserEmission;
    }
    const historicalRecord = historicalHouseholdEmissions.find((x) => x.year == selectedHistoricalYearPerson);
    return historicalRecord?.currentUserEmission ?? 0;
  };

  const getHouseholdEmissionToUse = () => {
    if (showHouseBarometer) {
      return targetHouseholdEmission;
    }
    const currentYear = new Date().getFullYear();
    if (historicalHouseholdEmissions.length == 0 || selectedHistoricalYearHousehold == currentYear) {
      return initialHouseholdEmission;
    }
    const historicalRecord = historicalHouseholdEmissions.find((x) => x.year == selectedHistoricalYearHousehold);
    return historicalRecord?.emissions ?? 0;
  };

  //showChart ? props.co2Plan.TargetSurvey : props.co2Plan.InitialSurvey
  const getSurveyForSurveyChart = () => {
    if (showChart) {
      return props.co2Plan.TargetSurvey;
    }
    const currentYear = new Date().getFullYear();
    if (historicalHouseholdEmissions.length == 0 || selectedHistoricalYearChart == currentYear) {
      return props.co2Plan.InitialSurvey;
    }
    const survey = props.co2Plan.InitialSurveyHistory.find((x) => x.Year == selectedHistoricalYearChart)!;
    return survey;
  };

  return (
    <Grid container lg={10} item direction={"column"}>
      {isMd && (
        <StyledButton variant="outlined" onClick={() => window.print()} size="small">
          {t("ClimatePlan.Buttons.PrintPage")}
        </StyledButton>
      )}
      <Grid item container direction={isSm ? "column" : "row"}>
        <Grid item style={{ flex: 1 }} xs={12} sm={6} container direction="column">
          <StyledCard variant="outlined" square={false}>
            <StyledCardContent>
              <Grid container spacing={1}>
                <Grid item container direction="column">
                  <Grid container justifyContent="space-between">
                    <Grid item xs={4}>
                      <Typography variant="h3">{t("ClimatePlan.General.My")}</Typography>
                      <Typography variant="h3" color="secondary">
                        {t("ClimatePlan.General.Status")}
                      </Typography>
                    </Grid>
                    <Grid item container xs={3} direction="column" alignItems="flex-end">
                      <Grid item style={{ position: "relative", right: -12 }}>
                        <StyledFormControlLabel
                          value="bottom"
                          control={
                            <Switch
                              size="medium"
                              color="secondary"
                              onChange={handleBarometerChange}
                              checked={showBarometer}
                            />
                          }
                          label={
                            showBarometer
                              ? t("ClimatePlan.TargetSurveyResult.Now")
                              : t("ClimatePlan.TargetSurveyResult.Before")
                          }
                          labelPlacement="bottom"
                        />
                      </Grid>
                      {!showBarometer && historicalHouseholdEmissions.length > 0 && (
                        <Grid item sx={{ position: "relative", height: 0 }}>
                          <Select
                            value={selectedHistoricalYearPerson}
                            onChange={(e) => setSelectedHistoricalYearPerson(+e.target.value)}
                          >
                            {historicalHouseholdEmissions.map((record) => (
                              <MenuItem value={record.year} key={record.year}>
                                {record.yearLabel}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>

                  <Grid item container justifyContent="center">
                    <Grid item my={1}>
                      <Grid
                        item
                        container
                        direction={isSm ? "row" : "column"}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <BarometerKlimaplan
                          rotation={getBarometerValue(
                            context.defaultCO2Emission,
                            context.governmentGoalCO2Emission,
                            getUserEmissionToUse(),
                            props.persons.length
                          )}
                          size="big"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item my={3}>
                    <Typography variant="body1">
                      <Trans components={{ sub: <sub /> }} i18nKey="ClimatePlan.YearlyEmissionPersonalKlimaplan" />{" "}
                      {convertToCurency(getUserEmissionToUse(), ".")}{" "}
                      {t("ClimatePlan.TargetSurveyResult.UnitOfMeasurementKg")}.
                    </Typography>
                    <Typography variant="body1">
                      <Trans
                        components={{ sub: <sub /> }}
                        i18nKey="ClimatePlan.InitialSurvey.YearlyGovernmentGoalInitial"
                      />{" "}
                      {convertToCurency(context.governmentGoalCO2Emission / props.persons.length, ".")}{" "}
                      {t("ClimatePlan.TargetSurveyResult.UnitOfMeasurementKg")}.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </StyledCardContent>
          </StyledCard>
          {!props.showPersonalChart && isSm ? null : (
            <StyledCard variant="outlined" square={false} style={{ flex: 1 }}>
              <StyledCardContent>
                <Grid container spacing={1}>
                  <CO2PersonsChartComponent persons={props.persons} showLegend showDescription />
                </Grid>
              </StyledCardContent>
            </StyledCard>
          )}
        </Grid>

        {/* the second diagram start */}
        <Grid item xs={12} sm={6} style={{ flex: 1 }} container direction="column">
          <StyledCard variant="outlined" square={false} style={{ height: "100%" }}>
            <StyledCardContent>
              <Grid container spacing={1}>
                <Grid item container direction="column">
                  <Grid container justifyContent="space-between">
                    <Grid item xs={8} container direction="column">
                      <Typography variant="h3">{t("ClimatePlan.General.MyHousehold")}</Typography>
                      <Typography variant="h3" color="secondary">
                        {t("ClimatePlan.General.HouseholdEmission")}
                      </Typography>
                    </Grid>
                    <Grid xs={3} item container direction="column" alignItems="flex-end">
                      <Grid item style={{ position: "relative", right: -12 }}>
                        <StyledFormControlLabel
                          value="bottom"
                          control={
                            <Switch
                              size="medium"
                              color="secondary"
                              onChange={handleHouseBarometerChange}
                              checked={showHouseBarometer}
                            />
                          }
                          label={
                            showHouseBarometer
                              ? t("ClimatePlan.TargetSurveyResult.Now")
                              : t("ClimatePlan.TargetSurveyResult.Before")
                          }
                          labelPlacement="bottom"
                        />
                      </Grid>
                      <Grid item sx={{ position: "relative", height: 0 }}>
                        {!showHouseBarometer && historicalHouseholdEmissions.length > 0 && (
                          <Select
                            label="Year"
                            value={selectedHistoricalYearHousehold}
                            onChange={(e) => setSelectedHistoricalYearHousehold(+e.target.value)}
                          >
                            {historicalHouseholdEmissions.map((record) => (
                              <MenuItem value={record.year} key={record.year}>
                                {record.yearLabel}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    my={1}
                    item
                    container
                    direction={isSm ? "row" : "column"}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <BarometerKlimaplan
                      rotation={getHouseBarometerValue(
                        context.defaultCO2Emission,
                        context.governmentGoalCO2Emission,
                        getHouseholdEmissionToUse()
                      )}
                      size="big"
                    />
                  </Grid>
                  <Grid item my={3}>
                    <Typography variant="body1">
                      <Trans components={{ sub: <sub /> }} i18nKey="ClimatePlan.YearlyEmissionHouseholdKlimaplan" />{" "}
                      {convertToCurency(getHouseholdEmissionToUse(), ".")}{" "}
                      {t("ClimatePlan.TargetSurveyResult.UnitOfMeasurementKg")}.
                    </Typography>
                    <Typography variant="body1">
                      <Trans
                        components={{ sub: <sub /> }}
                        i18nKey="ClimatePlan.InitialSurvey.YearlyHouseholdGovernmentGoalInitial"
                      />{" "}
                      {convertToCurency(context.governmentGoalCO2Emission, ".")}{" "}
                      {t("ClimatePlan.TargetSurveyResult.UnitOfMeasurementKg")}.
                    </Typography>
                  </Grid>
                  <Grid container direction="column" alignItems="flex-end">
                    <Grid item style={{ position: "relative", right: -12 }}>
                      <StyledFormControlLabel
                        value="bottom"
                        control={
                          <Switch size="medium" color="secondary" onChange={handleChartChange} checked={showChart} />
                        }
                        label={
                          showChart
                            ? t("ClimatePlan.TargetSurveyResult.Now")
                            : t("ClimatePlan.TargetSurveyResult.Before")
                        }
                        labelPlacement="bottom"
                      />
                    </Grid>
                    {!showChart && historicalHouseholdEmissions.length > 0 && (
                      <Grid item sx={{ position: "relative", height: 0 }}>
                        <Select
                          value={selectedHistoricalYearChart}
                          onChange={(e) => setSelectedHistoricalYearChart(+e.target.value)}
                        >
                          {historicalHouseholdEmissions.map((record) => (
                            <MenuItem value={record.year} key={record.year}>
                              {record.yearLabel}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item container justifyContent="center">
                    <CO2EmissionChartComponent
                      survey={getSurveyForSurveyChart()}
                      unchangeableBasicEmission={context.unchangeableBasicEmission}
                      animate={false}
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      onAnimationEnd={() => {}}
                      showLegend
                    ></CO2EmissionChartComponent>
                  </Grid>
                </Grid>{" "}
              </Grid>
            </StyledCardContent>
          </StyledCard>
        </Grid>
        {/* the second diagram end */}
      </Grid>
      {/* historical emissions chart start */}
      {historicalHouseholdEmissions.length > 0 && (
        <Grid item>
          <StyledCard variant="outlined" square={false} style={{ height: "100%" }}>
            <StyledCardContent>
              <Grid item container direction="column">
                <Typography paragraph variant="h3">
                  {t("ClimatePlan.TargetSurveyResult.EmissionsOverYears")}
                </Typography>
                <Grid item container justifyContent="center">
                  <CO2EmissionHistoryChartComponent emissionsHistory={historicalHouseholdEmissions} />
                </Grid>
              </Grid>
            </StyledCardContent>
          </StyledCard>
        </Grid>
      )}
      {/* historical emissions chart end */}
      {props.showEconomyItems && (
        <Grid item mx={isXs ? 0 : 1}>
          <CO2TargetSurveyEconomyTable
            initialGroups={props.co2Plan.InitialSurvey.Categories.flatMap((cat) => cat.EmissionSourceGroups)}
            targetGroups={props.co2Plan.TargetSurvey.Categories.flatMap((cat) => cat.EmissionSourceGroups)}
          ></CO2TargetSurveyEconomyTable>
        </Grid>
      )}
      <CO2TargetResultsFinishComponent
        initialHouseholdEmission={props.co2Plan.InitialHouseholdCO2Emission}
        targetHouseholdEmission={props.co2Plan.TargetHouseholdCO2Emission}
      />
      <ButtonsComponent
        back={() => {
          props.showTargetSurvey();
        }}
        forth={() => {
          props.showActionsTimeLine();
        }}
      />
    </Grid>
  );
};

export default CO2TargetSurveyResultComponent;

const StyledButton = styled(Button)(() => ({
  maxWidth: "150px",
  marginLeft: "auto",
  marginBottom: 8,
  marginRight: 8,
  "@media print": {
    display: "none",
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  margin: "0 8px 16px",
  [theme.breakpoints.down("sm")]: {
    margin: "8px",
  },
  [theme.breakpoints.only("xs")]: {
    margin: "8px 0",
  },
}));

const StyledCardContent = styled(CardContent)({
  padding: 20,
});

const StyledFormControlLabel = styled(FormControlLabel)({
  marginRight: 0,
  position: "relative",
  zIndex: 10,
});
