import React, { useState, useEffect, useContext } from "react";
import { Typography, Grid, useMediaQuery, Card, CardContent, useTheme, styled } from "@mui/material";
import { CO2EmissionSurvey } from "../../CO2Plan";
import CO2EmissionChartComponent, { GroupToShow } from "../CO2EmissionChartComponent";
import { convertToCurency } from "../../../../../services/converter.service";
import CO2PersonsChartComponent from "../CO2PersonsChartComponent";
import { Trans, useTranslation } from "react-i18next";
import ButtonsComponent from "../ButtonsComponent";
import BarometerKlimaplan from "../BarometerKlimaplan";
import CO2InitialResultsFinishComponent from "./CO2InitialResultsFinishComponent";
import { ClimatPlanUser } from "../../ClimatePlanPage";
import { getBarometerValue, getHouseBarometerValue } from "../../co2-helpers";
import { CO2Context } from "../CO2ContextWrapper";

type CO2InitialSurveyResultProps = {
  survey: CO2EmissionSurvey;
  showInitialSurvey: () => void;
  startTargetSurvey: () => void;
  showPersonalChart: boolean;
  user: ClimatPlanUser | null;
  persons: GroupToShow[];
  initialUserEmission: number;
};

const CO2InitialSurveyResultComponent = (props: CO2InitialSurveyResultProps): JSX.Element => {
  const { t } = useTranslation("translation");
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("md"));
  const context = useContext(CO2Context);
  const [usersEmission, setUsersEmission] = useState(0);

  useEffect(() => {
    const emission = props.survey.Categories.map((cat) => cat.CO2Emission).reduce((a, b) => a + b);
    setUsersEmission(emission + context.unchangeableBasicEmission);
  }, [props.survey]);

  return (
    <Grid container lg={10} item direction={"column"}>
      <CO2InitialResultsFinishComponent forth={() => props.startTargetSurvey()} emission={usersEmission} />
      <Grid item container direction={isSm ? "column" : "row"}>
        <Grid item style={{ flex: 1 }} xs={12} sm={6} container direction="column">
          <StyledCard variant="outlined" square={false}>
            <StyledCardContent>
              <Grid container spacing={1}>
                <Grid item container direction="column" spacing={2}>
                  <Grid item container direction="column">
                    <Typography variant="h3">{t("ClimatePlan.General.My")}</Typography>
                    <Typography variant="h3" color="secondary">
                      {t("ClimatePlan.General.Status")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid
                      item
                      container
                      direction={isSm ? "row" : "column"}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <BarometerKlimaplan
                        rotation={getBarometerValue(
                          context.defaultCO2Emission,
                          context.governmentGoalCO2Emission,
                          props.initialUserEmission,
                          props.persons.length
                        )}
                        size="big"
                      />
                    </Grid>
                  </Grid>
                  <Grid item my={2}>
                    <Typography variant="body1">
                      <Trans components={{ sub: <sub /> }} i18nKey="ClimatePlan.YearlyEmissionPersonalKlimaplan" />{" "}
                      {convertToCurency(props.initialUserEmission, ".")} kg.
                    </Typography>
                    <Typography variant="body1">
                      <Trans
                        components={{ sub: <sub /> }}
                        i18nKey="ClimatePlan.InitialSurvey.YearlyGovernmentGoalInitial"
                      />{" "}
                      {convertToCurency(context.governmentGoalCO2Emission / props.persons.length, ".")} kg.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </StyledCardContent>
          </StyledCard>
          {!props.showPersonalChart && isSm ? null : (
            <StyledCard variant="outlined" square={false}>
              <StyledCardContent>
                <Grid container spacing={1}>
                  <CO2PersonsChartComponent persons={props.persons} showLegend showDescription />
                </Grid>
              </StyledCardContent>
            </StyledCard>
          )}
        </Grid>

        {/* the second diagram start */}
        <Grid item xs={12} sm={6} style={{ flex: 1 }} container>
          {" "}
          <StyledCard variant="outlined" square={false} style={{ width: "100%" }}>
            <StyledCardContent>
              <Grid container>
                <Grid item container direction="column" spacing={2}>
                  <Grid item>
                    <Typography variant="h3">{t("ClimatePlan.General.MyHousehold")}</Typography>
                    <Typography variant="h3" color="secondary">
                      {t("ClimatePlan.General.HouseholdEmission")}
                    </Typography>
                  </Grid>
                  <Grid item container direction={isSm ? "row" : "column"} alignItems="center" justifyContent="center">
                    <BarometerKlimaplan
                      rotation={getHouseBarometerValue(
                        context.defaultCO2Emission,
                        context.governmentGoalCO2Emission,
                        usersEmission
                      )}
                      size="big"
                    />
                  </Grid>
                  <Grid item my={2}>
                    <Typography variant="body1">
                      <Trans components={{ sub: <sub /> }} i18nKey="ClimatePlan.YearlyEmissionHouseholdKlimaplan" />{" "}
                      {convertToCurency(usersEmission, ".")} kg.
                    </Typography>
                    <Typography variant="body1">
                      <Trans
                        components={{ sub: <sub /> }}
                        i18nKey="ClimatePlan.InitialSurvey.YearlyHouseholdGovernmentGoalInitial"
                      />{" "}
                      {convertToCurency(context.governmentGoalCO2Emission, ".")} kg.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container direction="column">
                  <CO2EmissionChartComponent
                    unchangeableBasicEmission={context.unchangeableBasicEmission}
                    survey={props.survey}
                    animate={false}
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onAnimationEnd={() => {}}
                  ></CO2EmissionChartComponent>
                </Grid>{" "}
              </Grid>
            </StyledCardContent>
          </StyledCard>
        </Grid>
        {/* the second diagram end */}
      </Grid>

      <ButtonsComponent back={() => props.showInitialSurvey()} forth={() => props.startTargetSurvey()} />
    </Grid>
  );
};

export default CO2InitialSurveyResultComponent;

const StyledCard = styled(Card)(({ theme }) => ({
  margin: "0 8px 16px",
  [theme.breakpoints.down("sm")]: {
    margin: "8px",
  },
  [theme.breakpoints.only("xs")]: {
    margin: "8px 0",
  },
}));

const StyledCardContent = styled(CardContent)({
  padding: 20,
});
