import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useTheme, Theme } from "@mui/material/styles";
import { useMediaQuery, Grid, styled } from "@mui/material";
import { menuItems, MenuItem } from "./sideMenuItems";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { partsToShow } from "../../CO2Plan";
import { useTranslation } from "react-i18next";
import { AiOutlineInfoCircle } from "react-icons/ai";
import LogoutIcon from "@mui/icons-material/Logout";
import PrintIcon from "@mui/icons-material/Print";
import CheckLogoBlue from "../img/check-logo-blue.png";
import CheckLogo from "../img/check-logo.png";

const drawerWidth = 220;

const RootContainer = styled("div")(() => ({
  display: "flex",
  width: "100%",
}));

const DrawerContainer = styled("nav")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    width: drawerWidth,
    flexShrink: 0,
  },
  [theme.breakpoints.down("lg")]: {
    display: "none",
  },
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    border: "none",
    top: 0,
    position: "relative",
  },
}));

const Content = styled("main")(({ theme }) => ({
  flexGrow: 1,
  padding: "16px 12px",
  [theme.breakpoints.only("xs")]: {
    padding: "12px 0",
  },
}));

const StyledCheck = styled("img")(() => ({
  width: 32,
}));

const LogoText = styled(Typography)(() => ({
  fontSize: "19px",
  lineHeight: 0.8,
  padding: 0,
}));

interface SideMenuProps {
  children: React.ReactNode;
  openFamilyDialog: () => void;
  currentPage: partsToShow;
  enabledParts: partsToShow[];
  handlePartToShowChange: (value: partsToShow | null) => void;
  showMembersButton: boolean;
  showPrint: boolean;
  historyNavigate: (route: string) => void;
  renderTopBar?: () => JSX.Element;
  ignorePlanPersons?: boolean;
}

export default function SideMenu(props: SideMenuProps) {
  const theme = useTheme();
  const { t } = useTranslation("translation");
  const { historyNavigate, renderTopBar } = props;
  const [climateTheme, setClimateTheme] = useState(false);

  useEffect(() => {
    setClimateTheme((theme as any)["name"] === "communeTheme" || (theme as any)["name"] === "commercialTheme");
  }, [theme]);
  const isMd = useMediaQuery(theme.breakpoints.down("lg"));

  const drawer = (
    <Grid sx={{ height: "100%" }} container direction="column" justifyContent="space-between">
      <Grid item container direction="column" sx={{ flexWrap: "nowrap" }}>
        <Grid item px={2} paddingTop={1}>
          {climateTheme && (theme as any)["name"] === "commercialTheme" ? (
            <Grid container justifyContent="row" item sx={{ flexWrap: "nowrap" }}>
              <StyledCheck src={CheckLogoBlue} alt="Logo" />
              <Grid item container direction="column" sx={{ width: "auto" }} justifyContent={"center"}>
                <LogoText align="left">{t("General.Logo.Our")}</LogoText>
                <LogoText color="secondary">{t("General.Logo.Climateplan")}</LogoText>
              </Grid>
            </Grid>
          ) : (
            <Grid container justifyContent="row" item sx={{ flexWrap: "nowrap" }}>
              <StyledCheck src={CheckLogo} alt="Logo" />
              <Grid item container direction="column" sx={{ width: "auto" }} justifyContent={"center"}>
                <LogoText align="left">{t("General.Logo.Our")}</LogoText>
                <LogoText color="secondary">{t("General.Logo.Climateplan")}</LogoText>
              </Grid>
            </Grid>
          )}
        </Grid>
        <StyledList>
          {menuItems.map((item: MenuItem) => (
            <StyledListItem
              key={item.partsToShow}
              onClick={() => props.handlePartToShowChange(item.partsToShow)}
              selected={item.partsToShow === props.currentPage}
              disabled={props.enabledParts.indexOf(item.partsToShow) === -1}
            >
              <StyledListItemIcon>{item.icon}</StyledListItemIcon>
              <ListItemText primary={t(item.key || "")} />
            </StyledListItem>
          ))}
          <StyledListItem onClick={() => historyNavigate("/validation")}>
            <StyledListItemIcon>
              <AiOutlineInfoCircle fontSize={30} />
            </StyledListItemIcon>
            <ListItemText primary={t("ClimatePlan.SideMenu.AboutClimatePlan")} />
          </StyledListItem>
          {props.currentPage !== partsToShow.InitialSurvey && props.currentPage !== partsToShow.ToolBarQuestions && (
            <StyledListItem
              onClick={() => props.handlePartToShowChange(partsToShow.ExtraStep)}
              selected={partsToShow.ExtraStep === props.currentPage}
            >
              <StyledListItemIcon>
                <LogoutIcon sx={{ fontSize: 30 }} />
              </StyledListItemIcon>
              <ListItemText primary={t("ClimatePlan.Buttons.Exit")} />
            </StyledListItem>
          )}
        </StyledList>
      </Grid>
      {props.showMembersButton && !props.ignorePlanPersons && (
        <Grid item>
          <StyledList>
            <StyledListItem onClick={() => props.openFamilyDialog()}>
              <StyledListItemIcon>
                {" "}
                <AiOutlineUsergroupAdd fontSize={30} />
              </StyledListItemIcon>
              <ListItemText primary={t("ClimatePlan.General.Family")} />
            </StyledListItem>{" "}
          </StyledList>
        </Grid>
      )}
      {props.showPrint && (
        <Grid item>
          <StyledList>
            <StyledListItem onClick={() => window.print()}>
              <StyledListItemIcon>
                {" "}
                <PrintIcon />
              </StyledListItemIcon>
              <ListItemText primary={t("ClimatePlan.Buttons.PrintPage")} />
            </StyledListItem>{" "}
          </StyledList>
        </Grid>
      )}
    </Grid>
  );

  return (
    <Grid container direction="column">
      {renderTopBar && renderTopBar()}
      <RootContainer style={{ flexDirection: isMd ? "column-reverse" : "row" }}>
        {isMd && (
          <StyledAppBar
            color="transparent"
            position="sticky"
            sx={{ zIndex: (theme: Theme) => theme.zIndex.drawer + 1 }}
          >
            <StyledToolbar>
              <Grid container justifyContent="space-between" flexWrap="nowrap">
                {menuItems.map((item: MenuItem) => (
                  <Grid key={item.key} item xs={2} container justifyContent="center" alignItems="center">
                    <MobileListItem
                      key={item.key}
                      onClick={() => props.handlePartToShowChange(item.partsToShow)}
                      selected={item.partsToShow === props.currentPage}
                      disabled={props.enabledParts.indexOf(item.partsToShow) === -1}
                    >
                      <Grid item>{item.icon}</Grid>
                      <Grid item>
                        <Typography color="inherit" style={{ fontSize: 10, whiteSpace: "nowrap" }} whiteSpace="nowrap">
                          {t(item.mobileName || "")}
                        </Typography>
                      </Grid>
                    </MobileListItem>
                  </Grid>
                ))}
                <Grid key="info" item xs={2} container justifyContent="center" alignItems="center">
                  {props.currentPage === partsToShow.InitialSurvey ||
                  props.currentPage === partsToShow.ToolBarQuestions ? (
                    <MobileListItem
                      key="info"
                      onClick={() => historyNavigate("/validation")}
                      disabled={props.enabledParts.indexOf(partsToShow.InitialResults) === -1}
                    >
                      <Grid item>
                        <AiOutlineInfoCircle fontSize={30} />
                      </Grid>
                      <Grid item>
                        <Typography color="inherit" style={{ fontSize: 10 }} whiteSpace="nowrap">
                          {t("ClimatePlan.General.AboutUs")}
                        </Typography>
                      </Grid>
                    </MobileListItem>
                  ) : (
                    <MobileListItem
                      key="exit"
                      onClick={() => props.handlePartToShowChange(partsToShow.ExtraStep)}
                      selected={partsToShow.ExtraStep === props.currentPage}
                    >
                      <Grid item>
                        <LogoutIcon sx={{ fontSize: 30 }} />
                      </Grid>
                      <Grid item>
                        <Typography color="inherit" style={{ fontSize: 10 }} whiteSpace="nowrap">
                          {t("ClimatePlan.Buttons.Exit")}
                        </Typography>
                      </Grid>
                    </MobileListItem>
                  )}
                </Grid>
              </Grid>
            </StyledToolbar>
          </StyledAppBar>
        )}

        <DrawerContainer>
          <Hidden smDown implementation="css">
            <StyledDrawer
              PaperProps={{ sx: { backgroundColor: theme.palette.background.default } }}
              variant="permanent"
              open
            >
              {drawer}
            </StyledDrawer>
          </Hidden>
        </DrawerContainer>
        <Content>
          {/* <div className={classes.toolbar}>
          <TopBar />
        </div> */}
          {props.children}
        </Content>
      </RootContainer>
    </Grid>
  );
}
const StyledDrawer = styled(Drawer)(() => ({
  height: "100vh",
  position: "fixed",
  top: 0,
  zIndex: 100,
}));
const StyledList = styled(List)({
  padding: "16px 12px",
  height: "100%",
  "@media print": {
    display: "none",
  },
});

const StyledListItem = styled(ListItemButton)(({ theme }) => ({
  padding: 12,
  margin: "4px 0",
  border: "none",
  "&.Mui-selected": {
    backgroundColor: theme.palette.background.paper,
  },
}));

const MobileListItem = styled(ListItemButton)(({ theme }) => ({
  flexDirection: "column",
  padding: 5,
  maxWidth: 60,
  maxHeight: 60,
  minWidth: 40,
  borderRadius: 10,
  "&:hover, &:focus, &:active": {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    "&.MuiListItemButton-root.Mui-selected": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      "&:hover, &:focus, &:active": {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
      },
      "& > div > svg": {
        color: theme.palette.secondary.contrastText,
      },
    },
  },
  "&.MuiListItemButton-root.Mui-selected": {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: 30,
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  padding: 8,
  marginRight: 12,
}));

const StyledAppBar = styled(AppBar)({
  bottom: 0,
  top: "auto",
  boxShadow:
    "0px 2px 4px -1px rgb(255 255 255 / 20%), 0px 4px 5px 0px rgb(255 255 255 / 14%), 0px 1px 10px 0px rgb(255 255 255 / 12%)",
  "@media print": {
    display: "none",
  },
});

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: 70,
  backgroundColor: theme.palette.background.default,
}));
