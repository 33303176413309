import React, { useEffect, useMemo, useState } from "react";
import { Grid, Paper, useMediaQuery, useTheme } from "@mui/material";
import { CO2EmissionSourceGroup, CO2EmissionSurvey, CO2PersonEmissionSourceGroup, CO2PlanPerson } from "../../CO2Plan";
import CO2TargetPersonComponent from "./CO2TargetPersonComponent";
import CO2TargetEmissionSourcesWrapper from "./CO2TargetEmissionSourcesWrapper";
import CO2TargetESGWidgetsComponent from "./CO2TargetESGWidgetsComponent";
import CO2TargetSurveyEconomyTable from "./CO2TargetSurveyEconomyTable";
import { useTranslation } from "react-i18next";

type CO2TargetESGProps = {
  initialSurvey: CO2EmissionSurvey;
  group: CO2EmissionSourceGroup;
  absoluteIndex: number;
  showEconomyItems: boolean;
  planPersons: CO2PlanPerson[];
};

// ESG means Emission Source Group
const CO2TargetESGComponent = (props: CO2TargetESGProps): JSX.Element => {
  const [currentInitialGroup, setCurrentInitialGroup] = useState<CO2EmissionSourceGroup | null>(null);
  const { t } = useTranslation("translation");
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const flattenedGroups = props.initialSurvey.Categories.flatMap((cat) => cat.EmissionSourceGroups);
    const activeGroup = flattenedGroups[props.absoluteIndex];
    setCurrentInitialGroup(activeGroup);
  }, [props.initialSurvey, props.group]);

  const getPersonGroup = (personId: string, group: CO2EmissionSourceGroup): CO2EmissionSourceGroup[] => {
    return [
      {
        ...group,
        PersonEmissionSources: group.PersonEmissionSources.filter((p) => p.PersonId === personId),
      },
    ];
  };

  const personEmissionSourcesToRender = useMemo(() => {
    const personEmissionSources = props.group.PersonEmissionSources.filter((person) => {
      const currentPerson = props.planPersons.find((listPerson) => {
        return listPerson.Id === person.PersonId;
      });

      if (currentPerson?.BirthYear) {
        const currentYear = new Date().getFullYear();
        if (currentYear - currentPerson.BirthYear <= 6) return false;
      }
      return true;
    });

    // to get personEmissionSources sorted by date of creation of planPerson
    // we need to sort personEmissionSources in the same order as the planPersons
    // because planPersons have been gotten from DB sorted by creation date.    
    const sortedPersonEmissionSources = props.planPersons.slice(0).reverse().map((p)=> {
      const currentEmission = personEmissionSources.find(x=>x.PersonId === p.Id)
      if (currentEmission ) return currentEmission;
    });   
   
    return sortedPersonEmissionSources.filter(Boolean) as CO2PersonEmissionSourceGroup[];

  }, [props.group.PersonEmissionSources, props.planPersons]);

  return (
    <Grid item container direction="column" alignItems="center" justifyContent="center" style={{ flexWrap: "nowrap" }}>
      {props.group.IsPerPerson &&
        personEmissionSourcesToRender.map((person) => (
          <Grid item container key={props.group.Id + person.PersonId}>
            <Paper sx={{ padding: isXs ? "40px 8px" : "40px 80px", marginBottom: "16px", minWidth: "100%" }}>
              <Grid container direction="column" alignItems="center">
                <CO2TargetPersonComponent
                  person={person}
                  groupId={props.group.Id}
                  displayTemplate={
                    props.group.TargetSurveyDisplayPattern ? t(props.group.TargetSurveyDisplayPattern) : null
                  }
                  averageCO2Emission={Math.round(
                    props.group.AverageCO2Emission / props.group.PersonEmissionSources.length
                  )}
                  goalCO2Emission={Math.round(props.group.GoalCO2Emission / props.group.PersonEmissionSources.length)}
                  sponsorSolutions={props.group.SponsorSolutions}
                ></CO2TargetPersonComponent>
                {props.showEconomyItems && currentInitialGroup && (
                  <Grid item container style={{ maxWidth: 415, marginBottom: 20 }}>
                    <CO2TargetSurveyEconomyTable
                      targetGroups={getPersonGroup(person.PersonId, props.group)}
                      initialGroups={getPersonGroup(person.PersonId, currentInitialGroup)}
                      editable
                      key={"eco_" + props.group.Id + person.PersonId}
                    ></CO2TargetSurveyEconomyTable>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>
        ))}
      {!props.group.IsPerPerson && (
        <Grid item container>
          <Paper sx={{ padding: isXs ? "40px 8px" : "40px 80px", marginBottom: "16px", minWidth: "100%" }}>
            <Grid container direction="column" alignItems="center">
              <CO2TargetEmissionSourcesWrapper
                groupId={props.group.Id}
                highLevelSources={props.group.HouseHoldEmissionSources}
                personId={null}
                displayTemplate={
                  props.group.TargetSurveyDisplayPattern ? t(props.group.TargetSurveyDisplayPattern) : null
                }
                CO2EmissionSaved={props.group.CO2EmissionSaved ?? 0}
                CO2Emission={props.group.CO2Emission}
                dueYear={props.group.DueYear}
                averageCO2Emission={props.group.AverageCO2Emission}
                goalCO2Emission={props.group.GoalCO2Emission}
                sponsorSolutions={props.group.SponsorSolutions}
                selectedSponsorSolutionId={props.group.CustomCalculationId}
              />
              {props.showEconomyItems && currentInitialGroup && (
                <Grid item container style={{ maxWidth: 415, marginBottom: 20 }}>
                  <CO2TargetSurveyEconomyTable
                    targetGroups={[props.group]}
                    initialGroups={[currentInitialGroup]}
                    editable
                  ></CO2TargetSurveyEconomyTable>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
      )}
      <CO2TargetESGWidgetsComponent group={props.group} />
    </Grid>
  );
};

export default CO2TargetESGComponent;
