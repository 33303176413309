import React, { useContext } from "react";
import {
  Typography,
  Grid,
  Checkbox,
  Card,
  CardContent,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import EastIcon from "@mui/icons-material/East";
import { CO2TimelineAction } from "../../CO2Plan";
import { convertToCurency } from "../../../../../services/converter.service";
import { CO2Context } from "../CO2ContextWrapper";
import { useCO2 } from "../../co2plan.actions";

type CO2TimelineActionProps = {
  action: CO2TimelineAction;
  icon: IconType;
};

type IconType = {
  src: string;
  background: string;
};

const CO2TimelineActionComponent = ({ action, icon }: CO2TimelineActionProps): JSX.Element => {
  const { t } = useTranslation("translation");
  const context = useContext(CO2Context);
  const [ state ] = useCO2();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = React.useState(false);

  const handleClick = () => {
    setLoading(true);
    context
      .markTimelineActionAsFulfilled(
        action.EmissionSourceGroupId,
        action.EmissionSourceId,
        action.PersonId,
        !action.IsFulfilled,
        context.unit?.Id,
        state.tempCO2PlanId
      )
      .finally(() => setLoading(false));
  };

  //need for translate multi element string
  const stringSplitTranslation = (multiString: string): string => {
    const modifiedArray = multiString.split(" / ").map((element) => {
      return t(element);
    });
    return modifiedArray.join(" / ");
  };

  return (
    <Grid item container>
      <Card variant="elevation" elevation={0} square={false} style={{ height: "100%", width: "100%" }}>
        <CardContent style={{ padding: isXs ? "10px" : "20px 20px 10px"}}>
          <Grid container wrap="nowrap">
            <Grid
              container
              item
              alignItems="flex-start"
              justifyContent={isXs ? "space-between" : "flex-start"}
              wrap="nowrap"
              md={6}
              sm={5}
            >
              <img
                src={icon.src}
                style={{
                  width: 35,
                  height: 35,
                  backgroundColor: `${icon.background}`,
                  borderRadius: 10,
                  marginRight: isXs ? 10 : 20,
                }}
                alt=""
              />
              <Typography variant="body2">
                <Typography display="inline" variant="subtitle1" component="span">
                  {action.PersonName
                    ? action.PersonName + " - "
                    : t("ClimatePlan.PersonsChart.Household").charAt(0).toUpperCase() +
                      t("ClimatePlan.PersonsChart.Household").slice(1) +
                      " -"}{" "}
                </Typography>
                {stringSplitTranslation(action.InitialState)}
              </Typography>
            </Grid>
            <Grid container item justifyContent="center" alignItems="center" md={1} sm={2}>
              <EastIcon color="primary" />
            </Grid>

            <Grid container item alignItems="flex-start" justifyContent="space-between" wrap="nowrap" md={6} sm={5}>
              <Typography variant="body2">{stringSplitTranslation(action.TargetState)} </Typography>
              <Checkbox
                disableRipple
                style={{ marginLeft: 20, padding: "5px 0 0" }}
                icon={
                  loading ? (
                    <CircularProgress color="secondary" />
                  ) : (
                    <CheckboxIcon  />
                  )
                }
                checkedIcon={
                  loading ? (
                    <CircularProgress color="secondary" />
                  ) : (
                    <CheckedIconChecked />
                  )
                }
                checked={action.IsFulfilled}
                disabled={loading}
                onClick={handleClick}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" mt={isXs ? 1 : 2}>
            <Typography variant="subtitle1">
              {t("ClimatePlan.TimelineItemSaving")} {convertToCurency(action.SavedCO2)} kg CO<sub>2</sub>e
            </Typography>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default CO2TimelineActionComponent;

const CheckboxIcon = styled("div")(({theme}) => ({
  width: 30,
  height: 30,
  position: "relative",
  borderRadius: 8,
  border: "1px solid" + " " + theme.palette.secondary.main,
}));

const CheckedIconChecked = styled(CheckboxIcon)(({ theme }) => ({
  "&::before, &::after": {
    position: "absolute",
    content: "''",
    background: theme.palette.primary.contrastText,
    right: 19,
    bottom: 5,
    width: 3,
    height: 12,
    transform: "skew(25deg)",
  },
  "&::after": {
    height: 26,
    transform: "skew(-25deg)",
    left: 17,
  },
}));
