import React from "react";
import "./App.css";
import { Provider } from "react-redux";
import store from "./reducers/store";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { Alert, Button, CssBaseline } from "@mui/material";
import { theme } from "./constants/theme";
import SAlert, { AlertProvider } from "./components/Base/SchoolsAlert";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { useServiceWorker } from "./helpers/useServiceWorker";
import { useTranslation } from "react-i18next";

function App() {
  const { t } = useTranslation("translation");
  const { waitingWorker, showReload, reloadPage } = useServiceWorker();

  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AlertProvider>
            <ErrorBoundary>
              <BrowserRouter>
                <Routes />
              </BrowserRouter>
            </ErrorBoundary>
            <SAlert />
          </AlertProvider>
          {showReload && waitingWorker && (
            <Alert
              severity="info"
              sx={{
                position: "fixed",
                bottom: 10,
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 1000,
                width: { xs: "95%", sm: 400 },
              }}
              action={
                <Button color="inherit" size="small" variant={"outlined"} onClick={reloadPage}>
                  {t("General.Buttons.Update")}
                </Button>
              }
            >
              {t("General.PwaAlert.Message")}
            </Alert>
          )}
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  );
}

export default App;
