import React, { ReactComponentElement } from "react";
import {
  Dialog,
  Grid,
  LinearProgress,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MuiDialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    color: theme.palette.primary.dark,
    borderRadius: 0,
    paddingBottom: 50,
    [theme.breakpoints.up("md")]: {
      width: "auto",
      minWidth: 500,
    },
  },
}));

const OffIcon = styled(CloseIcon)(({ theme }) => ({
  width: 22,
  height: 22,
  color: theme.palette.grey[400],
  margin: theme.spacing(1),
  "&:hover": {
    cursor: "pointer",
  },
}));

const StyledTitle = styled(MuiDialogTitle)(() => ({
  "&.MuiDialogTitle-root": {
    textAlign: "center",
    paddingTop: 4,
  },
}));

const StyledActions = styled(MuiDialogActions)(() => ({
  "&.MuiDialogActions-root": {
    justifyContent: "center",
  },
}));

export interface BaseDialogProps {
  titleComponent?: ReactComponentElement<any> | string;
  contentComponent?: ReactComponentElement<any> | string;
  actionsComponent?: ReactComponentElement<any> | string;
  closeDialog:
    | (() => void)
    | ((event: React.MouseEvent<Element, MouseEvent>) => void);
  open: boolean;
  children?: React.ReactNode;
  loading?: boolean;
  hideClose?: boolean;
}

const BaseDialog = ({
  titleComponent,
  actionsComponent,
  closeDialog,
  children,
  loading,
  open,
  hideClose,
  ...other
}: BaseDialogProps): JSX.Element => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <StyledDialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="md"
      onClose={closeDialog}
      open={open || false}
      {...other}
    >
      {loading && <LinearProgress />}
      <Grid
        container
        // marginTop={!isMobile ? "0" : "20px"}
        justifyContent="flex-end"
      >
        {!hideClose && (
          <OffIcon fontSize="large" spacing={2} onClick={closeDialog} />
        )}
      </Grid>
      {titleComponent && <StyledTitle>{titleComponent}</StyledTitle>}
      {children && (
        <MuiDialogContent style={{ paddingTop: 20 }}>
          {children}
        </MuiDialogContent>
      )}
      {actionsComponent && <StyledActions>{actionsComponent}</StyledActions>}
    </StyledDialog>
  );
};

export default BaseDialog;
