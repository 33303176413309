import { Reducer } from "react";
import { AppAction } from "../definitions/Action";
import User from "../definitions/model/User";
import { UserConstants } from "../constants/user.constants";
import { CO2PlanActionTypes } from "../pages/ClimatePlan/shared/co2plan.actiontypes";

export interface UserStoreState {
  loading: boolean;
  user: User | null;
  error: string | null;
  unitId: string | undefined;
  schoolId: string | undefined;
  classLabel: string | undefined;
  email: string | undefined;
  triggerLogout: boolean;
  openLoginDialog: boolean;
  pwaOptions: any;
}

const initialState: UserStoreState = {
  user: null,
  loading: false,
  error: null,
  schoolId: undefined,
  unitId: undefined,
  classLabel: undefined,
  triggerLogout: false,
  openLoginDialog: false,
  pwaOptions: null,
  email: undefined,
};

const userReducer: Reducer<UserStoreState, AppAction> = (
  state: UserStoreState = initialState,
  action: AppAction
): UserStoreState => {
  switch (action.type) {
    case UserConstants.LOGIN_REQUEST:
    case UserConstants.REGISTER_REQUEST:
      return { ...state, loading: true };
    case UserConstants.REGISTER_SUCCESS:
    case UserConstants.LOGIN_SUCCESS:
    case UserConstants.UPDATE_USER_PROFILE_SUCCEEDED:
    case UserConstants.UPDATE_USER_LANGUAGE_SUCCEEDED:
      return { ...state, user: action.payload, loading: false };
    case UserConstants.SET_REG_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case UserConstants.TRIGGER_LOGOUT:
      return {
        ...state,
        triggerLogout: true,
      };
    case UserConstants.LOGOUT:
      return { ...initialState };
    case UserConstants.SET_PWA_OPTIONS:
      return {
        ...state,
        pwaOptions: action.payload,
      };
    case UserConstants.OPEN_LOGIN_DIALOG:
      return {
        ...state,
        openLoginDialog: true,
      };
    case UserConstants.OPEN_LOGIN_DIALOG_SUCCEEDED:
      return {
        ...state,
        openLoginDialog: false,
      };
    case UserConstants.USER_OPERATION_FAILED:
      return { ...state, error: action.payload, loading: false };

    case UserConstants.SET_CLIMATE_PLAN_DATA:
      return {
        ...state,
        user: { ...state.user!, PlanId: action.payload },
      };
    case CO2PlanActionTypes.SET_CO2_PLAN:
      return {
        ...state,
        user: { ...state.user!, PlanId: action.payload.Id },
      };
    default:
      return state;
  }
};

export default userReducer;
