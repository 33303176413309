import { AnyAction } from "redux";
import { AdminConstants } from "../constants/admin.constants";
import { SearchResult } from "../definitions/model/SearchResult";
import User from "../definitions/model/User";

export interface AdminStoreState {
  loading: boolean;
  users: SearchResult<User>;
  error: string | null;
  plans: User[];
}

const initialState: AdminStoreState = {
  users: {
    Results: [],
    Count: 0
  },
  loading: false,
  error: null,
  plans: [],
};

const userReducer = (state: AdminStoreState = initialState, action: AnyAction ) => {
  switch (action.type) {
// case AdminConstants.ADMIN_GET_USER: 
    case AdminConstants.ADMIN_GET_USERS: 
    case AdminConstants.ADMIN_ADD_ROLE: 
    case AdminConstants.ADMIN_REMOVE_ROLE: 
    case AdminConstants.ADMIN_REMOVE_USER: 
    return { ...state, loading: true }
    case AdminConstants.ADMIN_GET_USERS_SUCCEEDED: 
      return {...state, users: action.payload, loading: false }
    case AdminConstants.ADMIN_REMOVE_USER_SUCCEEDED: 
    case AdminConstants.ADMIN_ROLE_OPERATION_SUCCEEDED: 
        return {...state, loading: false }
   default:
      return state;
  }
};

export default userReducer;

