import React, { useMemo } from "react";
import { StepIconProps } from "@mui/material/StepIcon";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector from "@mui/material/StepConnector";
import { Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import CarIcon from "./img/klima-icons/Transport-Icon.svg";
import ElectricityIcon from "./img/klima-icons/Appliances-Icon.svg";
import FoodIcon from "./img/klima-icons/Food-Icon.svg";
import HomeIcon from "./img/klima-icons/Home-Icon.svg";
import MobileIcon from "./img/klima-icons/Mobile-Icon.svg";
import ShoppingIcon from "./img/klima-icons/Clothing-Icon.svg";
import VacationIcon from "./img/klima-icons/Travel-Icon.svg";
import QuestionIcon from "./img/klima-icons/Question-Icon.svg";
import "swiper/css";
import { CO2EmissionSourceGroup } from "../CO2Plan";

const StickyContainer = styled(Grid)(({ theme }) => ({
  position: "-webkit-sticky",
  top: 0,
  zIndex: 2,
  backgroundColor: theme.palette.background.paper
}));

const StyledStepIcon = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  zIndex: 1,
  color: "#fff",
  width: 32,
  height: 32,
  [theme.breakpoints.up("md")]: {
    width: 45,
    height: 45,
  },
  display: "flex",
  borderRadius: 10,
  overflow: "hidden",
  justifyContent: "center",
  alignItems: "center",
  opacity: 0.4,
  ...(ownerState.active && {
    opacity: 1,
    width: 44,
    height: 44,
    [theme.breakpoints.up("md")]: {
      width: 50,
      height: 50,
    },
  }),
}));

type CO2StepperProps = {
  activeStep: number;
  withStyles?: string;
  steppedGroups: CO2EmissionSourceGroup[];
  onActiveStepChanged?: (newActiveStep: number) => void;
};

const icons: { [index: string]: React.ReactElement } = {
  "c4f20596-724d-43f6-a709-e821dbc19999": (
    <img src={QuestionIcon} style={{ backgroundColor: "#569790" }} key="0" alt="" />
  ),
  "54aa5670-a018-47d5-b35a-f56a2d7278b9": <img src={CarIcon} style={{ backgroundColor: "#69bfff" }} key="1" alt="" />,
  "c71fa881-5de8-423f-a56a-8c4ef1d1a29b": (
    <img src={VacationIcon} style={{ backgroundColor: "#a0ed6e" }} key="2" alt="" />
  ),
  "2a569d52-ca4e-4398-9e6c-49d839389ef3": (
    <img src={ShoppingIcon} style={{ backgroundColor: "#99ecff" }} key="3" alt="" />
  ),
  "2bb8d1ba-323b-4885-9447-1bc252a0706b": <img src={FoodIcon} style={{ backgroundColor: "#ff9d5a" }} key="4" alt="" />,
  "1cc58956-d5ff-4ed6-8fdf-7b8723feb950": (
    <img src={MobileIcon} style={{ backgroundColor: "#ff8380" }} key="5" alt="" />
  ),
  "6d080b27-90a0-4ed6-88b4-bd2443f362b7": (
    <img src={ElectricityIcon} style={{ backgroundColor: "#fff048" }} key="6" alt="" />
  ),
  "fed6ce09-bf9a-4976-b76f-16ba4f67959b": <img src={HomeIcon} style={{ backgroundColor: "#eb8ff9" }} key="7" alt="" />,
};

function StepIcon(props: StepIconProps) {
  const { active } = props;
  return <StyledStepIcon ownerState={{ active }}>{icons[String(props.icon)]}</StyledStepIcon>;
}

const CO2StepperComponent = (props: CO2StepperProps): JSX.Element => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <StickyContainer
      item
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item maxWidth="100%" width={isXs ? "100%" : "auto"}>
        <StyledStepper connector={<TransparentConnector />} activeStep={props.activeStep} alternativeLabel>
          {props.steppedGroups.map((g, i) => (
            <StyledStep key={g.Id} onClick={() => props.onActiveStepChanged && props.onActiveStepChanged(i)}>
              <StepLabel
                StepIconComponent={(e) =>
                  StepIcon({
                    ...e,
                    icon: g.Id.toLowerCase(),
                  })
                }
              ></StepLabel>
            </StyledStep>
          ))}
        </StyledStepper>
      </Grid>
    </StickyContainer>
  );
};

export default CO2StepperComponent;

const TransparentConnector = styled(StepConnector)({
  "& .MuiStepConnector-line": {
    display: "none",
  },
});

const StyledStepper = styled(Stepper)(({ theme }) => ({
  alignItems: "center",
  justifyContent: "center",
  maxWidth: "100%",
  overflow: "hidden",
  [theme.breakpoints.up("md")]: {
    maxWidth: "unset",
  },
  "& .MuiStepLabel-alternativeLabel > div > img": {
    minWidth: "100%",
  },
}));

const StyledStep = styled(Step)(({ theme }) => ({
  [theme.breakpoints.only("xs")]: {
    paddingLeft: "4px",
    paddingRight: "4px",
  },
}));
