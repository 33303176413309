import React from "react";
import { Container } from "@mui/material";

import BaseDialog from "../BaseDialog";
import { AuthDialogChildrenProps } from "../AuthDialog";
import LoginForm from "./LoginForm";
import { useTranslation } from "react-i18next";

const LoginDialog = ({
  state,
  error,
  open,
  authorize,
  setStep,
  closeDialog,
  clearUserError,
}: AuthDialogChildrenProps): JSX.Element => {
  const { t } = useTranslation("translation");

  return (
    <BaseDialog titleComponent={t("Base.Dialog.LogInToMyhouse")} open={open} closeDialog={closeDialog}>
      <Container maxWidth="xs">
        <LoginForm
          setStep={setStep}
          state={state}
          error={error}
          authorize={authorize}
          clearUserError={clearUserError}
        />
      </Container>
    </BaseDialog>
  );
};

export default LoginDialog;
