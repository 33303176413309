import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {Box, Grid, Link, Button, TextField, Typography, styled} from "@mui/material";
import AuthModel from "../../../definitions/model/Auth";
import { Step } from "../AuthDialog";
// import UserService from "../../../services/user.service";
import { Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TextFieldConnector } from "../../Base/FormConnector";

const StyledButton = styled(Button)(({theme})=>({
  width: 400,
  "& .MuiButton-label": {
    justifyContent: "center",
  },
}))

type ForgotPasswordFormData = {
  Email?: string;
};
type ForgotPasswordFormProps = {
  state: AuthModel;
  setStep: (step: Step, state: AuthModel) => void;
};

const ForgotPasswordForm = ({
  state,
  setStep,
}: ForgotPasswordFormProps): JSX.Element => {
  const methods = useForm<ForgotPasswordFormData>({
    defaultValues: {
      Email: state.Email,
    },
    mode: "onChange",
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = methods;
  const [errorText, setErrorText] = useState("");
  const [isChanged, setIsChanged] = useState(false);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation("translation");
  const submit = (data: ForgotPasswordFormData) => {
    setErrorText("");
    setIsChanged(false);
    setLoading(true);
    // submit code here
    // if (data && data.Email)
    //   UserService.resetPassword(data.Email)
    //     .catch((error) => {
    //       setErrorText("statusText" in error ? error.statusText : error);
    //     })
    //     .then((result: any) => {
    //       if (result) setIsChanged(result.success);
    //     })
    //     .finally(() => {
    //       setLoading(false);
    //     });
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Box mb={1.5}>
        <Typography>{t("Base.Dialog.ForgotPasswordTypography")}</Typography>
      </Box>
      <Box mb={1.5}>
        <TextFieldConnector register={register("Email", { required: true })}>
          <TextField
            variant="outlined"
            fullWidth
            type="email"
            size="small"
            label={t("Base.Dialog.LoginEmailLabelPlaceholder")}
            style={{ maxWidth: 400 }}
            error={!!errors.Email}
          />
        </TextFieldConnector>
      </Box>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        spacing={2}
      >
        <Grid item container justifyContent="center">
          <StyledButton
            variant="contained"
            color="secondary"
            disabled={loading}
            type="submit"
          >
            Send
          </StyledButton>
        </Grid>
        {errorText && <Alert severity="error">{errorText}</Alert>}
        {isChanged && (
          <Alert severity="success">{t("Base.Dialog.AlertText")}</Alert>
        )}
      </Grid>
      <Box textAlign="center" mt={3}>
        <Link variant="body1" onClick={() => setStep("register", getValues())}>
          {t("Base.Dialog.SignUpText")}
        </Link>
      </Box>
    </form>
  );
};

export default ForgotPasswordForm;
