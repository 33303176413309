import React, { useEffect, useState, useContext } from "react";
import { Grid, Typography } from "@mui/material";
import { CO2EmissionSource } from "../../CO2Plan";
import CO2EmissionSourceComponent from "./CO2EmissionSourceComponent";
import CO2ValueComponent from "./CO2ValueComponent";
import CO2EmissionSourceOptionsComponent from "./CO2EmissionSourceOptionsComponent";
import { useTranslation } from "react-i18next";
import { CO2Context } from "../CO2ContextWrapper";

type CO2EmissionSourcesFlattenedProps = {
  highLevelSources: CO2EmissionSource[];
  personId: string | null;
  isFieldsInvalid?: boolean;
};

const CO2EmissionSourcesFlattenedComponent = (props: CO2EmissionSourcesFlattenedProps): JSX.Element => {
  type HierarchyLevel = {
    Question: string | null;
    Sources: CO2EmissionSource[];
  };
  const { t } = useTranslation("translation");
  const [subquestionsHierarchy, setHierarchy] = useState<HierarchyLevel[]>([]);
  const [combinedMaxAmount, setCombinedMaxAmount] = useState<number | null>(null);
  const { isMultiSelect } = useContext(CO2Context);
  const [sourceValue, setSourceValue] = useState<CO2EmissionSource>();

  useEffect(() => {
    const sources = props.highLevelSources;

    const hierarchy: HierarchyLevel[] = [];
    let selectedSource = sources.find((src) => src.IsSelected);
    while (selectedSource !== null && selectedSource?.HasChildSources) {
      const newLevel = {
        Question: selectedSource.Question,
        Sources: selectedSource.ChildSources,
      };

      hierarchy.push(newLevel);
      selectedSource = selectedSource.ChildSources.find((src) => src.IsSelected);
    }

    setHierarchy(hierarchy);
    calculateCombinedRemain();

    const selectedSources = sources.filter((s) => s.IsSelected);
    const highValueSource = {
      ...selectedSources.filter((s) => s.ThingEmission).sort((a, b) => (b.Value ?? 0) - (a.Value ?? 0))[0],
    };

    //fo multiselect mode sourceValue should have Question filed, "Cycling" don't have it
    //just copy from another source
    if (selectedSources.length > 1 && highValueSource && !highValueSource.Question)
      highValueSource.Question = sources.find((s) => s.Question)?.Question ?? "";
    setSourceValue(highValueSource);
  }, [props.highLevelSources]);

  const calculateCombinedRemain = () => {
    if (props.highLevelSources[0]?.CombinedMaxAmount != null) {
      let combinedAmount = 0;
      let combinedMaxAmount = 0;

      for (const source of props.highLevelSources) {
        if (source.CombinedMaxAmount) combinedMaxAmount = source.CombinedMaxAmount;

        if (source.IsSelected) {
          combinedAmount += source.CombinedValue ?? 0;
        }
      }

      const maxAmount = combinedMaxAmount - combinedAmount;
      setCombinedMaxAmount(maxAmount);
    }
  };

  return (
    <>
      {/* performance optimization: using hierarchy makes component blinking on render
      use sources from props directly if thre is only one hierarchy level */}
        <Grid item container direction="row" spacing={2} marginBottom={2}>
          {props.highLevelSources.map((source) => (
            <CO2EmissionSourceComponent
              source={source}
              value={sourceValue?.Value || null}
              personId={props.personId}
              combinedMaxAmount={combinedMaxAmount}
              calculateCombined={calculateCombinedRemain}
              key={source.Id}
              isMultiSelect={!!isMultiSelect}
              isFieldsInvalid={props.isFieldsInvalid}
            ></CO2EmissionSourceComponent>
          ))}
        </Grid>
      <Grid container item>
        {props.highLevelSources?.length > 0 && sourceValue && (
          <CO2ValueComponent
            key={sourceValue.Id}
            source={sourceValue}
            sources={props.highLevelSources}
            personId={props.personId}
          />
        )}
      </Grid>
      <Grid item container direction="row" spacing={2}>
        {props.highLevelSources.map((source) => (
          <CO2EmissionSourceOptionsComponent source={source} personId={props.personId} key={source.Id} />
        ))}
      </Grid>
      {subquestionsHierarchy.map((hierarchyLevel, levelIndex) => (
        <Grid item container direction="row" spacing={2} key={levelIndex.toString()}>
          {!!hierarchyLevel.Question && (
            <Grid item my={2} container justifyContent="center">
              <Typography align="center" variant="h6" paddingTop={1}>
                {t(hierarchyLevel.Question)}
              </Typography>
            </Grid>
          )}
            <Grid item container direction="row" spacing={2}>
              {hierarchyLevel.Sources.map((source) => (
                <CO2EmissionSourceComponent
                  source={source}
                  personId={props.personId}
                  combinedMaxAmount={null}
                  value={source.Value}
                  key={source.Id}
                  isMultiSelect={!!isMultiSelect}
                  isFieldsInvalid={props.isFieldsInvalid}
                ></CO2EmissionSourceComponent>
              ))}
            </Grid>
        </Grid>
      ))}
    </>
  );
};

export default CO2EmissionSourcesFlattenedComponent;
