import React from "react";
import { SvgIconProps, SvgIcon, useTheme, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface CheckboxCheckedIconProps extends SvgIconProps {
  CheckColor: string;
  CircleColor: string;
}

export function CheckboxCheckedIcon(props: SvgIconProps) {
  const theme = useTheme();
  return (
    <SvgIcon {...props} viewBox="0,2,24,24">
      <path d="M12.1 15.95C9.98 12.94 8.65 11.06 8.11 10.31C7.64 9.64 6.69 9.55 6.1 10.11C5.78 10.4 6.65 9.58 6.51 9.71C5.39 10.78 5.24 12.53 6.18 13.77C7.36 15.34 9.72 18.46 11.01 20.18C11.56 20.91 12.65 20.91 13.21 20.19C15.54 17.2 21.3 9.75 23.6 6.79C24.35 5.82 24.47 4.51 23.9 3.43C23.74 3.13 24.31 4.21 24.18 3.96C23.68 3.01 22.36 2.88 21.7 3.73C20.42 5.36 17.22 9.43 12.1 15.95Z"></path>
      <path
        fill={theme.palette.secondary.main}
        d="M15.03 4.86L16.27 5.29L17.42 5.87L18.48 6.58L19.45 7.43L20.29 8.39L21.01 9.45L21.59 10.61L22.02 11.84L22.28 13.14L22.38 14.5L22.28 15.86L22.02 17.16L21.59 18.39L21.01 19.55L20.29 20.61L19.45 21.57L18.48 22.42L17.42 23.13L16.27 23.71L15.03 24.14L13.73 24.41L12.38 24.5L11.02 24.41L9.72 24.14L8.48 23.71L7.33 23.13L6.27 22.42L5.31 21.57L4.46 20.61L3.74 19.55L3.16 18.39L2.73 17.16L2.47 15.86L2.38 14.5L2.47 13.14L2.73 11.84L3.16 10.61L3.74 9.45L4.46 8.39L5.31 7.43L6.27 6.58L7.33 5.87L8.48 5.29L9.72 4.86L11.02 4.59L12.38 4.5L13.73 4.59L15.03 4.86ZM9.85 6.91L8.7 7.39L7.65 8.04L6.72 8.84L5.92 9.77L5.27 10.82L4.78 11.97L4.48 13.2L4.38 14.5L4.48 15.8L4.78 17.03L5.27 18.18L5.92 19.23L6.72 20.16L7.65 20.96L8.7 21.61L9.85 22.09L11.08 22.4L12.38 22.5L13.67 22.4L14.9 22.09L16.05 21.61L17.1 20.96L18.03 20.16L18.83 19.23L19.48 18.18L19.97 17.03L20.27 15.8L20.38 14.5L20.27 13.2L19.97 11.97L19.48 10.82L18.83 9.77L18.03 8.84L17.1 8.04L16.05 7.39L14.9 6.91L13.67 6.6L12.38 6.5L11.08 6.6L9.85 6.91Z"
      ></path>
    </SvgIcon>
  );
}

export function CheckboxUnCheckedIcon({ color, ...other }: SvgIconProps) {
  const theme = useTheme();
  return (
    <SvgIcon {...other} viewBox="0,2,24,24">
      <path
        fill={
          color === "error"
            ? theme.palette.error.main
            : theme.palette.secondary.main
        }
        d="M15.03 4.86L16.27 5.29L17.42 5.87L18.48 6.58L19.45 7.43L20.29 8.39L21.01 9.45L21.59 10.61L22.02 11.84L22.28 13.14L22.38 14.5L22.28 15.86L22.02 17.16L21.59 18.39L21.01 19.55L20.29 20.61L19.45 21.57L18.48 22.42L17.42 23.13L16.27 23.71L15.03 24.14L13.73 24.41L12.38 24.5L11.02 24.41L9.72 24.14L8.48 23.71L7.33 23.13L6.27 22.42L5.31 21.57L4.46 20.61L3.74 19.55L3.16 18.39L2.73 17.16L2.47 15.86L2.38 14.5L2.47 13.14L2.73 11.84L3.16 10.61L3.74 9.45L4.46 8.39L5.31 7.43L6.27 6.58L7.33 5.87L8.48 5.29L9.72 4.86L11.02 4.59L12.38 4.5L13.73 4.59L15.03 4.86ZM9.85 6.91L8.7 7.39L7.65 8.04L6.72 8.84L5.92 9.77L5.27 10.82L4.78 11.97L4.48 13.2L4.38 14.5L4.48 15.8L4.78 17.03L5.27 18.18L5.92 19.23L6.72 20.16L7.65 20.96L8.7 21.61L9.85 22.09L11.08 22.4L12.38 22.5L13.67 22.4L14.9 22.09L16.05 21.61L17.1 20.96L18.03 20.16L18.83 19.23L19.48 18.18L19.97 17.03L20.27 15.8L20.38 14.5L20.27 13.2L19.97 11.97L19.48 10.82L18.83 9.77L18.03 8.84L17.1 8.04L16.05 7.39L14.9 6.91L13.67 6.6L12.38 6.5L11.08 6.6L9.85 6.91Z"
      ></path>
    </SvgIcon>
  );
}


export const OffIcon = styled(CloseIcon)(({ theme }) => ({
  width: 22,
  height: 22,
  color: theme.palette.grey[400],
  margin: theme.spacing(1),
  "&:hover": {
    cursor: "pointer",
  },
}));