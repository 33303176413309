import React from "react";
import { Typography, Grid, useMediaQuery, Card, CardContent, useTheme, styled } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { convertToCurency } from "../../../../../services/converter.service";

const Emission = styled("span")({
  whiteSpace: "nowrap",
  fontFamily: "CircularXX-Bold",
});

type CO2TargetResultsFinishComponentProps = {
  initialHouseholdEmission: number | null;
  targetHouseholdEmission: number | null;
};

const CO2TargetResultsFinishComponent = (props: CO2TargetResultsFinishComponentProps): JSX.Element => {
  const { t } = useTranslation("translation");
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const savedEmission = (props.initialHouseholdEmission ?? 0) - (props.targetHouseholdEmission ?? 0);

  return (
    <Grid item container justifyContent={"center"}>
      <Grid item xs={12}>
        <Card sx={{ margin: isXs ? "16px 0" : "16px 8px" }} variant="outlined" square={false}>
          <CardContent sx={{ padding: "20px" }}>
            <Grid item container direction="column" spacing={1}>
              <Typography variant="h3" paragraph>
                {" "}
                {savedEmission > 0 && t("ClimatePlan.TargetSurvey.WellDone")}
              </Typography>
              <Typography variant="h6" paragraph>
                <Trans
                  components={{ Emission: <Emission /> }}
                  i18nKey={savedEmission <= 0 ? "ClimatePlan.TargetSurvey.Paragraph1" : "ClimatePlan.TargetSurvey.Paragraph1_Good"}
                  values={{ savedEmission: savedEmission }}
                />
              </Typography>
              <Typography variant="h6" paragraph>
                {t("ClimatePlan.TargetSurvey.Paragraph3")}
              </Typography>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CO2TargetResultsFinishComponent;
