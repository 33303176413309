import Avatar from "@mui/material/Avatar";
import React from "react";
import User from "../../definitions/model/User";

const UserAvatar = ({
  user,
  className,
  variant
}: {
  user: User;
  className?: string;
  variant?: "square" | "rounded" | "circular" | undefined;
}): JSX.Element => {
  function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function getAvatar(user: User) {
    if (user.AvatarUrl) return { src: user.AvatarUrl };

    const name = user.Name
      ? user.Name.split(" ")[0][0].toLocaleUpperCase()
      : "A";
    return {
      sx: {
        bgcolor: stringToColor(user.Name || ""),
      },
      children: `${name}`,
    };
  }

  return (
    <>
      {user && (
        <Avatar variant={variant} alt={user.Name} className={className} {...getAvatar(user)} />
      )}
    </>
  );
};

export default UserAvatar;
