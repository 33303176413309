import React, { useContext, useEffect, useState } from "react";
import { Typography, Grid, Button, Card, CardContent, styled, useTheme, useMediaQuery } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useCO2 } from "../../co2plan.actions";
import { CO2Context } from "../CO2ContextWrapper";
import { getHouseBarometerValue } from "../../co2-helpers";

type CO2InitialResultsFinishComponentProps = {
  forth: () => void;
  emission: number;
};

const CO2InitialResultsFinishComponent = (props: CO2InitialResultsFinishComponentProps): JSX.Element => {
  const { t } = useTranslation("translation");
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("lg"));
  const [{ tempCO2PlanId }] = useCO2();
  const context = useContext(CO2Context);
  const [climateTheme, setClimateTheme] = useState(false);
  const [barometrRotation, setBarometrRotation] = useState(0);

  useEffect(() => {
    if (props.emission > 0) {
      const rotation = getHouseBarometerValue(
        context.defaultCO2Emission,
        context.governmentGoalCO2Emission,
        props.emission
      );
      setBarometrRotation(rotation);
    }
  }, [props.emission]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setClimateTheme((theme as any)["name"] === "communeTheme");
  }, [theme]);

  return (
    <Grid item container justifyContent={"center"}>
      {isMd && (
        <StyledButton variant="outlined" onClick={() => window.print()} size="small">
          {t("ClimatePlan.Buttons.PrintPage")}
        </StyledButton>
      )}
      {tempCO2PlanId && (
        <Grid item xs={12}>
          <StyledCard variant="outlined" square={false}>
            <StyledCardContent>
              <Grid item container direction="column" spacing={1}>
                <Grid item container justifyContent="flex-start" alignItems="center">
                  <Grid item>
                    <Typography variant="h3" paragraph>
                      {t("ClimatePlan.InitialSurvey.TempPlanReady")}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container justifyContent="flex-start">
                  <Typography variant="h6">
                    <Trans components={{ sub: <sub /> }} i18nKey="ClimatePlan.InitialSurvey.CalculatedTempCO2" />
                  </Typography>
                </Grid>
                {context.renderMoveinAutocomplete && (
                  <Grid item container>
                    <Grid item container>
                      <Typography variant="h6" paragraph>
                        {t("ClimatePlan.InitialSurvey.EnterAddressTempCO2")}
                      </Typography>
                    </Grid>
                    <Grid item container xs={12} sm={7} md={6} lg={5} xl={4}>
                      <Grid item container direction="column">
                        context.renderMoveinAutocomplete()
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </StyledCardContent>
          </StyledCard>
        </Grid>
      )}
      {!tempCO2PlanId && (
        <Grid item xs={12}>
          <StyledCard variant="outlined" square={false}>
            <StyledCardContent>
              <Grid item container direction="column" spacing={1}>
                {barometrRotation <= 25 && (
                  <>
                    <Grid item container justifyContent="flex-start" alignItems="center">
                      <Grid item>
                        <Typography variant="h3" paragraph>
                          {t("ClimatePlan.InitialSurvey.Congratulations")}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item container justifyContent="flex-start">
                      <Typography variant="h6">
                        <Trans components={{ sub: <sub /> }} i18nKey="ClimatePlan.InitialSurvey.CalculatedCO2_Green" />
                      </Typography>
                    </Grid>
                    <Grid item container direction="column" justifyContent="center" alignItems="flex-start" spacing={2}>
                      <Grid item>
                        <Typography variant="h6">{t("ClimatePlan.InitialSurvey.BecomeGreen_Green")}</Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
                {barometrRotation > 25 && (
                  <>
                    <Grid item container justifyContent="flex-start" alignItems="center">
                      <Grid item>
                        <Typography variant="h3" paragraph>
                          {" "}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item container justifyContent="flex-start">
                      <Typography variant="h6">
                        <Trans components={{ sub: <sub /> }} i18nKey="ClimatePlan.InitialSurvey.CalculatedCO2" />
                      </Typography>
                    </Grid>
                    <Grid item container direction="column" justifyContent="center" alignItems="flex-start">
                      <Grid item>
                        <Typography variant="h6">{t("ClimatePlan.InitialSurvey.BecomeGreen")}</Typography>
                      </Grid>
                    </Grid>
                  </>
                )}

                <Grid item container direction="column" justifyContent="center" alignItems="flex-start" spacing={2}>
                  <Grid item mt={1}>
                    {climateTheme ? (
                      <GlowingButton
                        size="small"
                        variant="contained"
                        color="success"
                        onClick={props.forth}
                        title={t("ClimatePlan.Buttons.GetStartedTips")}
                      >
                        {t("ClimatePlan.Buttons.GetStarted")}
                      </GlowingButton>
                    ) : (
                      <Button
                        size="small"
                        variant="contained"
                        color="success"
                        onClick={props.forth}
                        title={t("ClimatePlan.Buttons.GetStartedTips")}
                      >
                        {t("ClimatePlan.Buttons.GetStarted")}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </StyledCardContent>
          </StyledCard>
        </Grid>
      )}
    </Grid>
  );
};

export default CO2InitialResultsFinishComponent;

const StyledCard = styled(Card)(({ theme }) => ({
  margin: "0 8px 16px",
  [theme.breakpoints.down("sm")]: {
    margin: "8px",
  },
  [theme.breakpoints.only("xs")]: {
    margin: "8px 0",
  },
}));

const StyledCardContent = styled(CardContent)({
  padding: 20,
  "@media print": {
    display: "none",
  },
});

const StyledButton = styled(Button)(() => ({
  maxWidth: "150px",
  marginBottom: 8,
  marginRight: 8,
  marginLeft: "auto",
  "@media print": {
    display: "none",
  },
}));

const GlowingButton = styled(Button)({
  animation: "glow 1s ease-in-out infinite alternate",
  "@-webkit-keyframes glow": {
    from: {
      boxShadow: "0 0 5px #2ba805",
    },
    to: {
      backgroundColor: "#95fa53",
      boxShadow: "0 0 20px #49e819",
    },
  },
});
