import React from "react";
import { Grid, Typography, Button, styled } from "@mui/material";

import ClimateImage from "../../../assets/img/school-children.jpg";
import watermarkTransparent from "../../../assets/img/watermarkTransparent.png";
import CircleLabel from "./CircleLabel";
import {useTranslation } from "react-i18next";
import BarometersBlock from "./BarometersBlock";
import { NIL } from "uuid";
import LoadingOverlay from "../../../components/LoadingOverlay";
import { useUser } from "../../../actions";
import RegistrationBlock from "./RegistrationBlock";
import { LoginButton } from "../../../components/Layout/Buttons";

const Banner = styled(Grid)(({ theme }) => ({
  backgroundImage: `url(${ClimateImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center center",
  backgroundRepeat: "no-repeat",
  width: "100%",
  margin: 0,
  height: 160,
  position: "relative",
  borderRadius: 10,

  [theme.breakpoints.up("sm")]: {
    minHeight: 280,
  },
}));

const WateremarkImage = styled("img")(({ theme }) => ({
  position: "absolute",
  top: 8,
  right: 12,
  width: 50,
  [theme.breakpoints.up("md")]: {
    width: 80,
  },
}));

const WelcomePart = (): JSX.Element => {
  const { t } = useTranslation("translation");
  const [{user, loading }, {openLoginDialog}] = useUser();
  
  return (
    <Grid item container direction="column" justifyContent="flex-start" flex={1}>
      <Grid item container justifyContent="center" mt={12}>
        <Grid item container xs={11} md={8} xl={7} sx={{ margin: "20px 0 0" }}>
          <Grid item container direction="column" spacing={3} justifyContent="center" style={{ flexWrap: "nowrap" }}>
            <Grid item>
              <CircleLabel />
              <Typography variant="h1" paragraph>
                {t("CO2Calculator.MembersDialogHeader")}
              </Typography>
              {/* <Typography variant="h6">{t("CO2Calculator.NewPlanIntro2")}</Typography>
              <Typography variant="h6">{t("CO2Calculator.NewPlanIntro3")}</Typography>
              <Typography variant="h6">{t("CO2Calculator.NewPlanIntro4")}</Typography> */}
              {!user && (
                  <LoginButton onClick={openLoginDialog} color="secondary" variant="contained">
                    <Typography color="inherit" variant="body1">
                      {t("Menu.HomeownerLogin")}
                    </Typography>
                  </LoginButton>
                )}
            </Grid>
            {/* {!user && (
              <Grid item>
                <Typography variant="h6">
                  {t("CO2Calculator.HaveYouCompletedClimatePlan")}
                </Typography>
                <Typography variant="h6">{t("CO2Calculator.IfYouDoNotHaveClimatePlan")}</Typography>
              </Grid>
            )} */}
            <Grid item container>
              <Grid item sm={6} xs={12}>
                <RegistrationBlock
                  simpleRegistration={true}
                  registrationDestination="/co2calculator"
                  unitValue={user?.Unit && user.Unit.Id !== NIL ? user.Unit : undefined}
                  schoolValue={
                    user?.School && user.School.Id !== NIL ? user.School : undefined
                  }
                  withClassLabel
                  classLabel={user?.ClassLabel}
                  name={user?.Name}
                  email={user?.Email || ""}
                  // loading={unitLoading}
                >
                  <Grid my={2}>
                    <Button variant="contained" color="info" type="submit">
                      {t("CO2Calculator.LetsStarted")}
                    </Button>
                  </Grid>
                </RegistrationBlock>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <BarometersBlock />
      </Grid>

      <Grid item container justifyContent="center">
        <Banner item container xs={11} md={8} xl={7}>
          <WateremarkImage src={watermarkTransparent} alt="watermark" />
        </Banner>
      </Grid>
      <LoadingOverlay loading={loading} />
    </Grid>
  );
};

export default WelcomePart;
