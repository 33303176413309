import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useUser } from "../../actions";
import { LanguageType } from "../../definitions/Menu";
import LanguageChangeMenu from "./LanguageChangeMenu";

const LanguageChange = () => {
  const { i18n } = useTranslation();
  const [{ user, loading }, userActions] = useUser();
  const [languageId, setLanguageId] = React.useState<LanguageType>("da");

  useMemo(() => {    
    const existingLanguageId = user?.Language || userActions.getLanguage();
    setLanguageId(existingLanguageId);
    // if (existingLanguageId) {
    //   setLanguageId(existingLanguageId);
    // }
    return () => {};
  }, []);

  useEffect(() => {
    i18n.changeLanguage(languageId);    
    userActions.setLanguage(languageId);

    if (user && user.Language !== languageId) {
      userActions.updateUserLanguage(languageId);
    }
  }, [languageId]);

  return <LanguageChangeMenu loading={loading} languageId={languageId} setLanguageId={setLanguageId} />;
};

export default LanguageChange;