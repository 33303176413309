import { getSchools, getUnits } from "./../actions/autocomplete.actions";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../actions";
import { ApplicationState } from "../reducers/store";

export const useAutocompleteActions = () => {
  const dispatch = useAppDispatch();

  const actions = {
    getUnits: (
      filter: string,
      skip: number,
      take: number,
      sortField: string,
      descending: boolean,
      startFromBuildings: boolean,
      signal?: AbortSignal
    ) => dispatch(getUnits(filter, skip, take, sortField, descending, startFromBuildings, signal)),

    getSchools: (
      filter: string,
      skip: number,
      take: number,
      sortField: string,
      descending: boolean,
      commune: number,
      signal?: AbortSignal
    ) => dispatch(getSchools(filter, skip, take, sortField, descending, commune, signal)),
  };

  return { ...actions };
};

export const useUser = () => useSelector((state: ApplicationState) => state.user.user);
