import React from "react";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import BarometerKlimaplan from "../../ClimatePlan/shared/components/BarometerKlimaplan";

const BarometersBlock = (): JSX.Element => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation("translation");

  return (
    <Grid paddingBottom={3} item container justifyContent="center">
      <Grid item container xs={11} md={9} sx={{ margin: "20px 0" }}>
        <Grid
          item
          container
          direction="row"
          spacing={2}
          justifyContent="space-around"
        >
          <Grid
            xs={6}
            item
            container
            direction={"column"}
            alignItems={"flex-end"}
            justifyContent="center"
          >
            <Typography
              style={{ width: !isSm ? 290 : 90 }}
              align="center"
              paragraph
              variant="subtitle2"
            >
              {t("CO2Calculator.Before")}
            </Typography>
            <BarometerKlimaplan rotation={90} size={isSm ? "small" : "big"} />
          </Grid>
          <Grid
            xs={6}
            item
            container
            direction={"column"}
            alignItems={"flex-start"}
            justifyContent="center"
          >
            <Typography
              style={{ width: !isSm ? 290 : 90 }}
              align="center"
              paragraph
              variant="subtitle2"
            >
              {t("CO2Calculator.Now")}
            </Typography>
            <BarometerKlimaplan rotation={0} size={isSm ? "small" : "big"} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BarometersBlock;
