import { UnitDto } from "../models";

export interface AutocompleteItem {
  Type: AutocompleteResultType;
  Text: string;
  CursorPosition: number;  
}

export interface AutocompleteUnitItem extends AutocompleteItem {
  Unit: UnitDto;
}

export enum AutocompleteResultType {
  Street = 0,
  Building = 1,
  House = 2,
}
