import React, { useState, useContext, useEffect } from "react";
import { Grid, Typography, Link, Paper, useTheme, useMediaQuery, styled } from "@mui/material";
import { CO2EmissionSurvey, CO2EmissionSourceGroup, CO2PlanCustomQuestionsTarget } from "../../CO2Plan";
import CO2TargetESGComponent from "./CO2TargetESGComponent";
import CO2StepperComponent from "../CO2StepperComponent";
import { useTranslation } from "react-i18next";
import ButtonsComponent from "../ButtonsComponent";
import { CO2Context } from "../CO2ContextWrapper";
import CustomQuestionsComponent from "../InitialSurvey/CustomQuestionsComponent";
import { getIsQuestionsFilled } from "../InitialSurvey/CO2InitialSurveyComponent";
import { integrateCustomQuestionsIntoGroups, useCO2 } from "../../co2plan.actions";
import HandPlantIcon from "../img/handIcon.png";

const HandPlant = styled("img")(() => ({
  width: 25,
}));

type CO2TargetSurveyProps = {
  targetSurvey: CO2EmissionSurvey;
  initialSurvey: CO2EmissionSurvey;
  finishSurvey: () => Promise<void>;
  scrollToTop: () => void;
};

const CO2TargetSurveyComponent = (props: CO2TargetSurveyProps): JSX.Element => {
  const context = useContext(CO2Context);
  const [{ co2Plan }] = useCO2();
  const [currentGroup, setCurrentGroup] = useState<CO2EmissionSourceGroup | null>(null);
  const [isLastGroup, setIsLastGroup] = useState(false);
  const [isFirstGroup, setIsFirstGroup] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("translation");
  const [isQuestionsFilled, setIsQuestionsFilled] = useState(true);
  const [flatGroups, setFlatGroups] = useState<CO2EmissionSourceGroup[]>([]);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down("lg"));

  useEffect(() => {
    let groups = props.targetSurvey.Categories.flatMap((cat) => cat.EmissionSourceGroups);
    if (co2Plan?.CustomQuestions) {
      groups = integrateCustomQuestionsIntoGroups(
        co2Plan.CustomQuestions,
        groups,
        CO2PlanCustomQuestionsTarget.TargetSurvey
      );
    }

    setFlatGroups(groups);
  }, [props.targetSurvey, co2Plan?.CustomQuestions]);

  useEffect(() => {
    setIsLastGroup(false);
    const flattenedGroups = [...flatGroups];
    const activeGroup = flattenedGroups[props.targetSurvey.ActiveGroupIndex ?? 0];

    const isCustomQuestions = co2Plan?.CustomQuestions?.some(
      (question) => question.Target === CO2PlanCustomQuestionsTarget.TargetSurvey
    );

    setCurrentGroup(activeGroup);
    if (activeGroup?.Id !== currentGroup?.Id && !isCustomQuestions) {
      setIsFirstGroup(props.targetSurvey.ActiveGroupIndex === 0 || props.targetSurvey.ActiveGroupIndex === undefined);
      setIsLastGroup(props.targetSurvey.ActiveGroupIndex === flattenedGroups.length - 1);
    }

    if (props.targetSurvey.ActiveGroupIndex === flatGroups.length - 1) {
      setIsLastGroup(true);
    }
  }, [flatGroups]);

  useEffect(() => {
    if (currentGroup?.IsCustom) {
      setIsQuestionsFilled(getIsQuestionsFilled(currentGroup));
    }
  }, [currentGroup]);

  return (
    <Grid marginTop={isMd ? 3 : 0} container direction="column">
      <Grid item container justifyContent="center" sx={{ position: isMd ? "sticky" : "static", top: 0, zIndex: 2 }}>
        <Paper sx={{ padding: isXs ? "20px 8px" : "40px 80px", width: "100%" }}>
          <CO2StepperComponent
            withStyles="pointer"
            activeStep={props.targetSurvey.ActiveGroupIndex ?? 0}
            steppedGroups={flatGroups}
            onActiveStepChanged={(index) => context.setActiveGroupIndex(index)}
          ></CO2StepperComponent>
        </Paper>
      </Grid>
      {currentGroup && !currentGroup.IsCustom && (
        <Grid item container marginBottom={2} marginTop={-2}>
          <Paper sx={{ padding: isXs ? "20px 8px" : "10px 80px 40px", width: "100%" }}>
            <Grid item container justifyContent="center">
              <Typography variant="h4">{t(currentGroup.ShortName)} score</Typography>
            </Grid>
          </Paper>
        </Grid>
      )}
      {currentGroup && !currentGroup.IsCustom && (
        <CO2TargetESGComponent
          initialSurvey={props.initialSurvey}
          group={currentGroup}
          absoluteIndex={props.targetSurvey.ActiveGroupIndex ?? 0}
          showEconomyItems={context.showEconomyItems}
          planPersons={co2Plan?.PlanPersons || []}
        />
      )}

      {currentGroup && currentGroup.IsCustom && <CustomQuestionsComponent group={currentGroup} />}
      {currentGroup && !!currentGroup.ArticleLink && (
        <Grid item container justifyContent="center" alignItems="center" sx={{ marginBottom: 6 }}>
          <HandPlant src={HandPlantIcon} alt={""} />
          <Typography variant="h6">
            <Link color="inherit" href={currentGroup.ArticleLink} target="_blank" rel="noopener noreferrer">
              {t("ClimatePlan.EmissionSourceGroupArticleLink")}
            </Link>
          </Typography>
        </Grid>
      )}
      <ButtonsComponent
        back={
          props.targetSurvey.ActiveGroupIndex === 0
            ? null
            : () => context.setActiveGroupIndex((props.targetSurvey.ActiveGroupIndex ?? 0) - 1)
        }
        loading={loading}
        disabled={props.targetSurvey.HasInvalidInput || loading || !isQuestionsFilled}
        forth={
          !isLastGroup
            ? () => context.setActiveGroupIndex((props.targetSurvey.ActiveGroupIndex ?? 0) + 1)
            : async () => {
                setLoading(true);
                await context.saveEmissionSurvey(
                  co2Plan?.InitialSurvey || null,
                  co2Plan?.TargetSurvey || null,
                  co2Plan?.CustomQuestions || null,
                  co2Plan?.Id,
                  context.unit?.Id,
                  context.isMultiSelect
                );
                await props.finishSurvey();
              }
        }
      ></ButtonsComponent>
    </Grid>
  );
};

export default CO2TargetSurveyComponent;
