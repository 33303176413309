import { ThemeProvider } from "@emotion/react";
import { StyledEngineProvider, CssBaseline, Container, Grid } from "@mui/material";
import { useUser } from "../../actions";
import { ErrorBoundary } from "../../components/ErrorBoundary";
import Footer from "../../components/Layout/Footer";
import TopBar, { StyledMenuButton } from "../../components/Layout/TopBar";
import { theme } from "../../constants/theme";
import CookiesBlock from "./Components/CookiesBlock";
import WelcomePart from "./Components/WelcomePart";
import { UserRole } from "../../definitions/model/User";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const StartPage = (): JSX.Element => {
  const [{ user }] = useUser();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <TopBar hideAuth hideButtons openCreateDialog={false}>
          {user && user.UserRole !== UserRole.Manager && (
            <StyledMenuButton variant="contained" color="secondary" onClick={(e) => navigate("/statistics")}>
              {t("Menu.Statistics")}
            </StyledMenuButton>
          )}
        </TopBar>
        <Container
          sx={{
            overflow: "hidden",
            maxWidth: "100%",
            padding: 0,
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            style={{ minHeight: "100vh" }}
          >
            <ErrorBoundary>
              <WelcomePart />
              <CookiesBlock />
            </ErrorBoundary>
          </Grid>
        </Container>
        <Footer />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default StartPage;
