import { combineReducers } from "redux";

import user from "./user.reducer";
import admin from "./admin.reducer";
import co2 from "../pages/ClimatePlan/shared/co2.reducer";
import stats from "./stats.reducer";

const combinedReducer = combineReducers({
  user,
  admin,
  co2,
  stats
});

//TODO: make initial state if needed
const rootReducer = (state: any, action: any) => {
  return combinedReducer(state, action);
};

export default rootReducer;
