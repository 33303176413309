import React, { useState, useEffect } from "react";
import RegistrationForm from "./RegistrationForm";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material";

import { useNavigate } from "react-router";
import { NIL } from "uuid";
import { Step } from "../../../components/Dialogs/AuthDialog";
import { SchoolDto, UnitDto } from "../../../definitions/models";
import { useUser } from "../../../actions";
import { useAlertContext } from "../../../components/Base";
import { UserProfileDTO } from "../../../definitions/model/User";

type RegistrationBlockProps = {
  children?: React.ReactNode;
  simpleRegistration?: boolean;
  registrationDestination: string;
  registrationStep?: Step;
  unitValue?: UnitDto;
  schoolValue?: SchoolDto;
  classLabel?: string | null;
  email: string;
  name?: string;
  withClassLabel?: boolean;
  loading?: boolean;
  sx?: SxProps<Theme>;
  variant?: "outlined" | "filled" | "standard";
  color?: "primary" | "secondary" | "success" | "error" | "info" | "warning";
};

type unitsData = {
  unit: UnitDto | null;
  school: SchoolDto;
  classLabel?: string;
  email: string;
  name?: string;
  manager?: boolean;
};

const RegistrationBlock = (props: RegistrationBlockProps): JSX.Element => {
  const [unitData, setUnitData] = useState<unitsData | null>(null);
  const navigate = useNavigate();
  const [{ user }, actions] = useUser();

  const { showAlert } = useAlertContext();

  const setUnitsDataHandler = (
    unit: UnitDto | null,
    school: SchoolDto,
    email: string,
    classLabel?: string,
    name?: string,
    manager?: boolean
  ) => {
    setUnitData({
      unit,
      school,
      classLabel,
      email,
      name,
      manager,
    });

    actions.setRegistrationData(unit?.Id || null, school.Id || "", email, classLabel);

    if (user && user.Unit?.Id === unit?.Id && user.School?.Id === school.Id) {
      navigate(props.registrationDestination);
      return;
    }

    if (user && unit && school) {
      //update user data
      const data: UserProfileDTO = {
        Name: user.Name ?? "",
        ClassLabel: classLabel ?? "",
        UnitId: unit.Id ?? NIL,
        SchoolId: school.Id ?? NIL,
        Language: user.Language,
      };
      actions.updateUserProfile(data).then(() => navigate(props.registrationDestination));
    }
  };

  useEffect(() => {
    if (unitData) {
      if (user) {
        return;
      }

      const tryRegister = async () => {
        try {
          await actions.tryRegister({
            UnitId: unitData.unit?.Id || null,
            SchoolId: unitData.school.Id || "",
            Email: unitData.email,
            ClassLabel: unitData.classLabel,
            Name: unitData.name || unitData.email,
            Manager: unitData.manager,
            //This is the requirement for the simplified registration
            Password: unitData.email,
            Language: actions.getLanguage(),
          });
          navigate("/co2calculator");
        } catch (error) {
          showAlert({
            severity: "error",
            text: error as string,
            duration: 3000,
            position: {
              horizontal: "center",
              vertical: "bottom",
            },
          });
          actions.clearUserError();
        }
      };

      tryRegister();
    }
  }, [unitData]);

  return (
    <RegistrationForm
      setValueCallback={setUnitsDataHandler}
      loading={props.loading}
      variant={props.variant}
      color={props.color}
      unitValue={props.unitValue}
      schoolValue={props.schoolValue}
      classLabel={props.classLabel}
      withClassLabel={props.withClassLabel}
      email={props.email}
      name={props.name}
      showManagerCheckbox={!user}
    >
      {props.children && props.children}
    </RegistrationForm>
  );
};

export default RegistrationBlock;
