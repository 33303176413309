import React, { useCallback, useContext, useEffect } from "react";
import { Typography, Grid, Paper, useMediaQuery, useTheme } from "@mui/material";

import { CO2PersonEmissionSourceGroup } from "../../CO2Plan";
import CO2EmissionSourcesFlattenedComponent from "./CO2EmissionSourcesFlattenedComponent";
import { useTranslation } from "react-i18next";
import { CO2Context } from "../CO2ContextWrapper";

type CO2EmissionPersonProps = {
  person: CO2PersonEmissionSourceGroup;
  groupId: string;
};

const CO2EmissionPersonComponent = (
  props: CO2EmissionPersonProps
): JSX.Element => {
  const context = useContext(CO2Context);
  const { t } = useTranslation("translation");
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const isMissingInSurvey =
    !props.person.SelectedSourceId &&
    context.missingSourceGroups.filter(
      ([grId, personId]) =>
        grId === props.groupId && personId === props.person.PersonId
    ).length > 0;

  const isFieldsValid = useCallback(() => {
    const selectedChild = props.person.EmissionSources.find((src) => {
      return src.Id === props.person.SelectedSourceId;
    });
    if (selectedChild?.CombinedMaxAmount != null) {
      const selectedSources = props.person.EmissionSources.filter(
        (s) => s.IsSelected
      );
      if (selectedSources == null || selectedSources.length == 0) return false;

      const combinedAmount = selectedSources.reduce(
        (a, b) => a + (b.CombinedValue ?? 0),
        0
      );
      if (context.isMultiSelect && !combinedAmount) return false;
      if (combinedAmount > selectedChild.CombinedMaxAmount) return false;

      //check selection for the multiselect
      const errorChilds = selectedSources.filter(
        (s) => s.ChildSources?.filter((c) => c.IsSelected).length == 0
      );
      if (errorChilds?.length > 0) return false;

      return true;
    }

    return true;
  }, [props.person]);

  return (
    <Grid item container>
    <Paper sx={{padding: isXs ? "40px 8px" : "40px 80px", marginBottom: "8px", width: "100%"}}>
      {!context.IsSinglePersonPlan && (
        <Grid
          container
          item
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid
            item
            mb={2}
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item>
              <Typography variant="h5">{props.person.Name}, </Typography>
            </Grid>
            <Grid item>
              {" "}
              <Typography
                variant="h6"
                style={isMissingInSurvey ? { color: "red" } : {}}
              >
                {t("ClimatePlan.InitialSurvey.ChooseText")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      <CO2EmissionSourcesFlattenedComponent
        highLevelSources={props.person.EmissionSources}
        personId={props.person.PersonId}
        isFieldsInvalid={!isFieldsValid()}
      ></CO2EmissionSourcesFlattenedComponent>
    </Paper>
    </Grid>
  );
};

export default CO2EmissionPersonComponent;
