import { AnyAction } from "redux";
import { Commune, CountryRegion, StatisticsViewDto } from "../definitions/models";
import { StatsConstants } from "../constants/stats.constants";

export interface StatsStoreState {
  communes: Commune[];
  regions: CountryRegion[];
  loading: boolean;
  error: string | null;
  primary?: StatisticsViewDto;
  compared?: StatisticsViewDto;
}

const initialState: StatsStoreState = {
  loading: false,
  error: null,
  communes: [],
  regions: [],
};

const statsReducer = (state: StatsStoreState = initialState, action: AnyAction) => {
  switch (action.type) {
    // case AdminConstants.ADMIN_GET_USER:
    case StatsConstants.STATS_GET_COMMUNES:
    case StatsConstants.STATS_GET_REGIONS:
    case StatsConstants.STATS_GET:
      return { ...state, loading: true };
    case StatsConstants.STATS_GET_COMMUNES_SUCCEEDED:
      return { ...state, communes: action.payload, loading: false };
    case StatsConstants.STATS_GET_REGIONS_SUCCEEDED:
      return { ...state, regions: action.payload, loading: false };
    case StatsConstants.STATS_GET_PRIMARY_SUCCEDED:
      return { ...state, primary: action.payload, loading: false };
    case StatsConstants.STATS_GET_FOR_COMPARISON_SUCCEEDED:
      return { ...state, compared: action.payload, loading: false };
    case StatsConstants.STATS_RESET_COMPARISON:
        return { ...state, compared: undefined };
    case StatsConstants.STATS_OPERATION_FAILED:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default statsReducer;
