export const StatsConstants = {
    STATS_GET_COMMUNES: "STATS_GET_COMMUNES",
    STATS_GET_COMMUNES_SUCCEEDED: "STATS_GET_COMMUNES_SUCCEEDED",
    STATS_GET_REGIONS: "STATS_GET_REGIONS",
    STATS_GET_REGIONS_SUCCEEDED: "STATS_GET_REGIONS_SUCCEEDED",
    STATS_GET: "STATS_GET",
    STATS_GET_PRIMARY_SUCCEDED: "STATS_GET_PRIMARY_SUCCEDED",
    STATS_GET_FOR_COMPARISON_SUCCEEDED: "STATS_GET_FOR_COMPARISON_SUCCEEDED",
    STATS_RESET_COMPARISON: "STATS_RESET_COMPARISON",
    STATS_OPERATION_FAILED: "STATS_OPERATION_FAILED"
}