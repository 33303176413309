import React from "react";
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { convertToCurency } from "../../../../services/converter.service";
import { GroupToShow } from "./CO2EmissionChartComponent";

type PieData = {
  outerRadius: string | number | undefined;
  cy: string | undefined;
  isAnimationActive?: boolean;
  onAnimationEnd?: () => void;
};

type Props = {
  groups: GroupToShow[];
  withToolTip: boolean;
  pieData: PieData;
  height: string | number | undefined;
  width: string | number | undefined;
  radius: string | number | undefined;
};

const ExtendedPieChart = ({
  groups,
  pieData,
  withToolTip,
  height,
  width,
}: Props): JSX.Element => {
  // const RADIAN = Math.PI / 180;

  const toolTipFormater = (
    value: string | number | (string | number)[]
  ) => {
    return [`${convertToCurency(Number(value))} kg.`];
  };


  return (
    <ResponsiveContainer height={height} width={width}>
      <BarChart data={groups}>
        <Bar
          dataKey="Emission"
          fill="#8884d8"
          isAnimationActive={pieData.isAnimationActive}
          onAnimationEnd={pieData.onAnimationEnd}
        >
          {groups.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Bar>
        {withToolTip && (
          <Tooltip
            cursor={{ fill: "transparent" }}
            formatter={toolTipFormater as any}
          />
        )}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ExtendedPieChart;
