import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { NIL } from "uuid";
import { useAppDispatch, useUser } from "../../actions";
import TopBar, { StyledMenuButton } from "../../components/Layout/TopBar";
import { UnitConstants } from "../../constants";
import { ApplicationState } from "../../reducers/store";
import ClimatePlanPage, { ClimatPlanFamily, ClimatPlanUnit, ClimatPlanUser } from "./shared/ClimatePlanPage";
import { useTranslation } from "react-i18next";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "../../constants/theme";
import { useCO2 } from "./shared/co2plan.actions";

const SchoolsClimatePlan = (): JSX.Element => {
  const [{ user }, actions] = useUser();
  const [unit, setUnit] = useState<ClimatPlanUnit | null>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation("translation");
  const [{ co2Plan }, { loadPlan }] = useCO2();

  useEffect(() => {
    if (user) {
      console.log("user", user);
      const hasUserCO2Plan = user.PlanId != null && user.PlanId !== NIL;
      dispatch({ type: UnitConstants.GET_UNIT_SUCCEEDED, payload: { HasCO2Plan: hasUserCO2Plan } });

      // if (user.Id === NIL) {
      //   navigate("/");
      //   return;
      // }

      setUnit({
        Id: user.Unit?.Id ?? NIL,
        PlanId: user.PlanId ?? NIL,
        CommuneNumber: 0,
        UnitArea: 200,
      });

      if (hasUserCO2Plan && user.PlanId !== co2Plan?.Id) loadPlan(user.PlanId, user.Unit?.Id ?? NIL);
    }
  }, [user]);

  const { error } = useSelector((state: ApplicationState) => state.co2);

  const mainLoader = false; // unitState.loading;

  const fakeMainFamily: ClimatPlanFamily = {
    Members: [],
  };

  // const unit: ClimatPlanUnit | null = useMemo(() => {
  //   if (unitState.unit) {
  //     return {
  //       Id: unitState.unit.Id,
  //       CommuneNumber: unitState.unit.Address.Commune.CommuneNumber,
  //       UnitArea: unitState.unit.UnitData.UnitArea,
  //     };
  //   } else {
  //     return null;
  //   }
  // }, [unitState.unit]);

  const renderTopBar = () => {
    return (
      <TopBar>
        <StyledMenuButton variant="contained" color="secondary" onClick={(e) => navigate("/statistics")}>
          {t("Menu.Statistics")}
        </StyledMenuButton>
      </TopBar>
    );
  };

  const historyNavigate = (route: string) => {
    navigate(route);
  };

  return (
    <>
      {unit && (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ClimatePlanPage
            widgets={[]}
            loading={mainLoader}
            unit={unit}
            showPropertyDescription={false}
            user={user as ClimatPlanUser}
            family={fakeMainFamily}
            error={error}
            WidgetPage={5}
            showEconomyItems={false}
            historyNavigate={historyNavigate}
            onExitActions={() => historyNavigate("/statistics")}
            renderTopBar={renderTopBar}
            sendShortcutHandler={actions.sendShortcutEmail}
            withTopBarOnStartPage
            ignorePlanPersons
          />
        </ThemeProvider>
      )}
    </>
  );
};

export default SchoolsClimatePlan;
