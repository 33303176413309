import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
  Grid,
  IconButton,
  DialogActions,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Trans, useTranslation } from "react-i18next";

type RatingDialogProps = {
  open: boolean;
  handleClose: (confirmed?: boolean) => void;
};

const AddPersonToIgnoreListDialog = (props: RatingDialogProps): JSX.Element => {
  const { t } = useTranslation("translation");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      onClose={() => props.handleClose(false)}
      fullScreen={fullScreen}
      maxWidth="md"
      open={props.open}
      PaperProps={{
        style: { borderRadius: theme.shape.borderRadius }
      }}
    >
      <Grid container justifyContent="flex-end">
        <Grid item>
          <IconButton
            onClick={() => props.handleClose(false)}
            color="inherit"
            size="small"
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <DialogTitle textAlign="center">
        <Trans
          components={{ sub: <sub /> }}
          i18nKey="ClimatePlan.AddToIgnoreDialog.DialogTitle"
        />
      </DialogTitle>
      <DialogContent>
        <DialogActions>
          <Grid justifyContent="space-around" container>
            <Button
              onClick={() => props.handleClose(false)}
              variant="contained"
              color="secondary"
            >
              {t("ClimatePlan.Buttons.Undo")}
            </Button>
            <Button
              onClick={() => props.handleClose(true)}
              variant="contained"
              color="secondary"
            >
              {t("ClimatePlan.Buttons.Ok")}
            </Button>
          </Grid>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default AddPersonToIgnoreListDialog;
