import React, { cloneElement, ReactElement } from "react";
import { Controller, useFormContext, UseFormRegisterReturn } from "react-hook-form";

type TextFieldConnectorType = {
  register: UseFormRegisterReturn<any>;
  children: ReactElement;
};

type ControllerConnectorType = {
  name: string;
  rules?: any;
  type?: "checkbox" | "select"
  children: ReactElement;
  defaultValue?: boolean;
};

export const TextFieldConnector = ({
  register,
  children,
}: TextFieldConnectorType) => {
  const { onChange, onBlur, name, ref } = register;

  return (
    <>
      {cloneElement(children, {
        name: name,
        onChange: onChange,
        onBlur: onBlur,
        inputRef: ref,
      })}
    </>
  );
};

export const ControllerConnector = ({
  name,
  type,
  rules,
  children,
  defaultValue,
}: ControllerConnectorType) => {
  const { control } = useFormContext();

  return (
    <Controller
      render={({ field: { onChange, onBlur, name, ref, value } }) => {
        if (type === "checkbox") {
          return cloneElement(children, {
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked),
            checked: value
          })
        } else {
          return cloneElement(children, {
            name: name,
            onChange: onChange,
            onBlur: onBlur,
            inputRef: ref,
          })
        }
      }}
      defaultValue={defaultValue}
      name={name}
      control={control}
      rules={rules}
    />
  );
};