import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Grid, Link, TextField, Button, Typography, LinearProgress, Zoom, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import PasswordField from "../../Base/PasswordField";
import AuthModel from "../../../definitions/model/Auth";
import { Step } from "../AuthDialog";
import { useNavigate } from "react-router";
import User from "../../../definitions/model/User";
import { TextFieldConnector } from "../../Base/FormConnector";

const StyledButton = styled(Button)(() => ({
  "&.MuiButton-root": {
    minWidth: 200,
  },
  "& .MuiButton-label": {
    justifyContent: "center",
  },
}));

type LoginFormData = {
  Email: string;
  Password: string;
};

type LoginFormProps = {
  state: AuthModel;
  setStep: (step: Step, state: AuthModel) => void;
  authorize: (username: string, password: string) => Promise<User | undefined>;
  error: string | null;
  clearUserError: () => void;
};

const LoginForm = ({ state, setStep, authorize, error, clearUserError }: LoginFormProps): JSX.Element => {
  const methods = useForm<LoginFormData>({
    defaultValues: {
      Email: state.Email,
      Password: state.Password,
    },
  });

  const navigate = useNavigate();
  const { t } = useTranslation("translation");
  const [processing, setProcessing] = useState(false);
  const [anError, setAnError] = useState(error);
  const [showPwd, setShowPwd] = useState(false);

  useEffect(() => {
    setAnError(error);
  }, [error]);

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors },
    getValues,
  } = methods;

  const tryLogin = ({ Email, Password }: LoginFormData) => {
    setProcessing(true);
    authorize(Email, Password)
      .then((user) => {
        if (user) {
          clearUserError();
          navigate("/co2calculator");
        }
      })
      .finally(() => setProcessing(false));
  };

  return (
    <>
      <form onSubmit={handleSubmit(tryLogin)}>
        <Grid container spacing={1}>
          <Grid item xs={12} mt={1}>
            <TextFieldConnector register={register("Email", { required: true })}>
              <TextField
                variant="outlined"
                fullWidth
                type="email"
                size="small"
                label={t("Base.Dialog.LoginEmailLabelPlaceholder")}
                style={{ maxWidth: 400 }}
                error={!!errors.Email}
              />
            </TextFieldConnector>
          </Grid>
          {showPwd ? (
            <Grid item xs={12}>
              <TextFieldConnector register={register("Password", { required: true })}>
                <PasswordField
                  variant="outlined"
                  fullWidth
                  size="small"
                  label={t("Base.Dialog.PasswordPlaceholder")}
                  style={{ maxWidth: 400 }}
                  error={!!errors.Password}
                />
              </TextFieldConnector>
            </Grid>
          ) : (
            <Grid container item justifyContent="center" direction="column" alignItems="center">
              <Grid item>
                <Link underline="hover" onClick={() => setShowPwd(!showPwd)}>
                  <Typography variant="body2">{t("Authorization.Forms.LoginWithPassword")}</Typography>
                </Link>
              </Grid>
            </Grid>
          )}
          {anError ? (
            <Zoom in={!!anError}>
              <Grid item container justifyContent="center">
                <Typography color="coral">{t(anError)}</Typography>
              </Grid>
            </Zoom>
          ) : (
            <Grid item>&nbsp;</Grid>
          )}
          {/* <Grid item container justifyContent="center">
            <Grid item>
              <Link underline="hover" onClick={() => setStep("register", getValues())}>
                <Typography variant="body2"> {t("Authorization.Forms.NoAccount")}</Typography>
              </Link>
            </Grid>
          </Grid> */}
          <Grid item container direction="column" justifyContent="space-between" spacing={1}>
            <Grid container justifyContent="center" item>
              <StyledButton
                variant="contained"
                color="secondary"
                disabled={formState.isSubmitting || processing}
                type="submit"
              >
                {t("Authorization.Buttons.LogIn")}
              </StyledButton>
            </Grid>
            {/* <Grid container item justifyContent="center">
              <Typography variant="body1">{t("Base.Dialog.Or")}</Typography>
            </Grid> */}
          </Grid>
          {/* <Grid container item justifyContent="center" direction="column" alignItems="center">
            <Grid item>
              <Link underline="hover" onClick={() => setStep("forgotPassword", getValues())}>
                <Typography variant="body2">{t("Authorization.Forms.ForgetPassword")}</Typography>
              </Link>
            </Grid>
          </Grid> */}
        </Grid>
      </form>
      {processing ? <LinearProgress /> : <div style={{ minHeight: 4 }}></div>}
    </>
  );
};

export default LoginForm;
