import React, { useEffect } from "react";
import { Grid, styled, keyframes } from "@mui/material";

const WrappedContainer = styled(Grid)(() => ({
  position: "relative",
  width: "auto",
  minWidth: "auto",
  height: "auto",
  overflow: "hidden"
}));

const Dial = styled("svg", {
  shouldForwardProp: (propName: string) => propName !== "scale",
})<{ scale: number }>(({ theme, scale }) => ({
  width: 110 * scale,
  [theme.breakpoints.up("md")]: {
    width: 120 * scale,
  },
}));

const rotateAnimation = (startValue: number, endValue: number | null) => keyframes`
  from {
    transform: rotate(${startValue}deg);
  }
  to {
    transform: rotate(${endValue}deg);
  }
`;

const Arrow = styled("svg", {
  shouldForwardProp: (propName: string) =>
    propName !== "scale" && propName !== "startPosition" && propName !== "rotationValue",
})<{ scale: number; startPosition?: number; rotationValue: number | null }>(
  ({ theme, scale, startPosition = -90, rotationValue }) => ({
    position: "absolute",
    transition: "0.6s all ease-in-out",
    animation: rotationValue ? `${rotateAnimation(startPosition, rotationValue)} 0.5s linear 1` : "none",
    transform: `rotate(${rotationValue}deg)`,
    transformOrigin: "bottom center",
    minWidth: 130 * scale,
    marginBottom: 8,
    [theme.breakpoints.up("md")]: {
      minWidth: 156 * scale,
    },
  })
);

type Props = {
  rotation: number | null;
  size?: "small" | "big";
  startPosition?: number;
};

type StyleProps = {
  scale: number;
};

const BarometerKlimaplan = ({ rotation, size = "small", startPosition }: Props): JSX.Element => {

  // don't animate the arrow from the start position every time, animate it from the previous position
  const [previousCurrentRotation, setPreviousCurrentRotation] = React.useState<number | undefined>(undefined);

  const convertRotation = (val: number | null) => val === null ? null : (170 * val) / 100 - 85; // '-85' cause arrow have wrong default position

  const animationDuration = 600; // this should be the same as in the css animation
  useEffect(() => {
    const newPreviousCurrentRotation = convertRotation(rotation) ?? undefined;
    if(rotation){
      // timeout is needed to let the current animation finish when rotation changes in props
      setTimeout(() => {
          setPreviousCurrentRotation(newPreviousCurrentRotation);
        
      }, animationDuration);
    }
    
  }, [rotation]);

  const styleProps: StyleProps = {
    scale: size === "small" ? 0.8 : 2.4,
  };
  const currentRotation = convertRotation(rotation);

  

  return (
    <WrappedContainer container alignItems="flex-end" justifyContent="center">
      <Dial scale={styleProps.scale} viewBox="-8 -5 240 120">
        <path
          d=" M 10 110 A 100 100 0 0 1 39 39"
          stroke="#a0ed6e"
          fill="none"
          strokeWidth="30"
          transform="translate(-3, 2)"
        />
        <path
          d=" M 39 39 A 100 100 0 0 1 110 10"
          stroke="#fff048"
          fill="none"
          strokeWidth="30"
          transform="translate(-1, 0)"
        />
        <path
          d=" M 110 10 A 100 100 0 0 1 181 39"
          stroke="#ffba27"
          fill="none"
          strokeWidth="30"
          transform="translate(2, 0)"
        />
        <path
          d=" M 181 39 A 100 100 0 0 1 210 110"
          stroke="#ff8380"
          fill="none"
          strokeWidth="30"
          transform="translate(4, 2)"
        />
      </Dial>

      {/* we need !== null, because if currentRotation is 0, svg should be shown */}
      {currentRotation !== null && (
        <Arrow
          scale={styleProps.scale}
          viewBox="0 0 220 120"
          rotationValue={currentRotation}
          startPosition={startPosition ?? previousCurrentRotation}
        >
          <polygon points="110 60,105 115,115 115" fill="#141344" />
          <path d=" M 115 114 A 5 5 0 0 1 105 114" fill="#141344" />
        </Arrow>
      )}
    </WrappedContainer>
  );
};

export default BarometerKlimaplan;
