import React from "react";
import { Container, Typography, Link } from "@mui/material";
import { useTranslation, Trans } from "react-i18next";


const Policy = (): JSX.Element => {
  const { t } = useTranslation("translation");

  return (
    <Container maxWidth="md">
      <Typography variant="h6" paragraph>
        {t("Pages.Contact.MainContainerTitle")}
      </Typography>
      <Typography paragraph fontWeight={"bold"}>
        {t("CO2Calculator.ValidateDialog.Purpose")}
      </Typography>
      <Typography paragraph>
        <Trans
          components={{sub: <sub/>}}
          i18nKey="Pages.Contact.ClimatePlanFocus"
        />
      </Typography>

      <Typography paragraph>
        <Trans
          components={{ sub: <sub /> }}
          i18nKey="Pages.Contact.ClimatePlanService"
        />
      </Typography>
    
      <Typography paragraph>
        {t("Pages.Contact.ShareDataWithPartners")}{" "}
      </Typography>

      <Typography paragraph>
        {t("Pages.Contact.QuestionsAboutPersonalData")}{" "}
        <Link href="mailto:info@myhouse.dk">info@myhouse.dk</Link>
      </Typography>

      <Typography paragraph>
        {t("Pages.Contact.AddressesCanBeFound")}
      </Typography>
      <Typography paragraph fontWeight={"bold"}>
        {t("Pages.Contact.Definitions")}
      </Typography>
      <Typography paragraph>
        <Trans
          components={{span: <span style={{fontWeight: "bold"}}/>}}
          i18nKey="Pages.Contact.ServiceBuiltOnDigitalPlatform"
        />
        <Link href="https://myhouse.dk" target="_blank">
          www.myhouse.dk
        </Link>{" "}
        <Trans
          components={{span: <span style={{fontWeight: "bold"}}/>}}
          i18nKey="Pages.Contact.MyhouseApS"
        />{" "}
        <Link href="https://myhouse.dk" target="_blank">
          www.myhouse.dk
        </Link>{" "}
        <Trans
          components={{span: <span style={{fontWeight: "bold"}}/>}}
          i18nKey="Pages.Contact.CompanyWhichDevelopedSoftware"
        />
      </Typography>

      <Typography sx={{textDecoration: "underline"}}>
        {t("Pages.Contact.ContactInformation")}
      </Typography>
      <Typography paragraph>
        {t("Pages.AboutUs.AddressLine2")}
        <br/>
        {t("Pages.AboutUs.AddressLine3")}
        <br/>
        {t("Pages.AboutUs.Email")}{" "}
        <Link href="mailto:info@myhouse.dk">info@myhouse.dk</Link>
        <br/>
        {t("Pages.AboutUs.AddressLine4")}
        <br/>
        {t("Pages.AboutUs.AddressLine5")}
      </Typography>

      <Typography paragraph fontWeight={"bold"}>
        {t("Pages.Contact.PrivacyPolicyForMyhouse")}
      </Typography>

      <Typography paragraph>{t("Pages.Contact.PersonalDataPolicy")}</Typography>

      <Typography sx={{textDecoraion: "underline"}}>
        {" "}
        {t("Pages.Contact.DataResponsibility")}
      </Typography>

      <Typography paragraph>{t("Pages.Contact.YourDataProtection")}</Typography>
      <Typography paragraph>
        {t("Pages.Contact.InformUsersAboutPersonalData")}
      </Typography>
      <Typography paragraph>
        {t("Pages.Contact.PersonalDataRegulation")}{" "}
      </Typography>

      <Typography sx={{textDecoration: "underline"}}>
        {" "}
        {t("Pages.Contact.PersonalInformation")}
      </Typography>
      <Typography paragraph>
        {t("Pages.Contact.CollectPersonalData")}
      </Typography>
      <Typography sx={{textDecoration: "underline"}}>
        {t("Pages.Contact.WeProtectData")}
      </Typography>

      <Typography paragraph>{t("Pages.Contact.DataLossPrevention")}</Typography>
      <Typography sx={{textDecoration: "underline"}}>
        {t("Pages.Contact.RightsToCorrectedData")}
      </Typography>
      <Typography paragraph>
        {t("Pages.Contact.RightsToInsightIntoInformation")}
      </Typography>
      <Typography paragraph>
        {t("Pages.Contact.PossibilityToDeleteOwnData")}
      </Typography>
      <Typography paragraph>
        {t("Pages.Contact.PossibilityToCorrectPersonalData")}
      </Typography>
      <Typography sx={{textDecoration: "underline"}}>
        {t("Pages.Contact.StorageOfData")}
      </Typography>
      <Typography paragraph>{t("Pages.Contact.StoreYourData")}</Typography>

      <Typography sx={{textDecoration: "underline"}}>
        {t("Pages.Contact.Responsibility")}{" "}
      </Typography>
      <Typography paragraph>
        {t("Pages.Contact.InformationFromPubliclyAvailableSources")}
      </Typography>
      <Typography paragraph>
        {t("Pages.Contact.AboutErrorInInforamtion")}
      </Typography>

      <Typography paragraph>
        {t("Pages.Contact.AboutOptionToUploadDocuments")}
      </Typography>

      <Typography paragraph>
        {t("Pages.Contact.AboutResponsibilityForDataAccuracy")}
      </Typography>

      <Typography sx={{textDecoration: "underline"}}>
        {t("Pages.Contact.AboutErrorInRegisteredInformation")}
      </Typography>

      <Typography paragraph>
        {t("Pages.Contact.PossibilityCorrectInformation")}
      </Typography>

      <Typography paragraph>
        {t("Pages.Contact.RightsLodgeComplaint")}
      </Typography>

      <Typography sx={{textDecoration: "underline"}}>
        {t("Pages.Contact.NewsletterAndNotifications")}
      </Typography>

      <Typography paragraph>
        {t("Pages.Contact.SeparatelyRegisterNotifications")}
      </Typography>

      <Typography sx={{textDecoration: "underline"}}>
        {t("Pages.Contact.UsingGoogleAnalytics")}
      </Typography>

      <Typography paragraph>
        {t("Pages.Contact.AboutUsingCookiesFromGoogleAnalytics")}
      </Typography>
      <Typography paragraph>
        {t("Pages.Contact.PossibilityToUnsubscribe")}
      </Typography>
      <Typography paragraph>
        {t("Pages.Contact.PossibilityOfQuestionsOrSuggestions")}{" "}
        <Link href="mailto:info@myhouse.dk">info@myhouse.dk</Link>.
      </Typography>
      <Typography sx={{textDecoration: "underline"}}>
        {t("Pages.Contact.Cookies")}
      </Typography>
      <Typography paragraph>{t("Pages.Contact.AboutUsingCookies")}</Typography>
      <Typography sx={{textDecoration: "underline"}}>
        {t("Pages.Contact.WhatAreCookies")}
      </Typography>
      <Typography paragraph>{t("Pages.Contact.CookiesAreUsedBy")}</Typography>
      <Typography paragraph>
        {t("Pages.Contact.CookiesCollectInformation")}
      </Typography>
      <Typography paragraph>
        {t("Pages.Contact.UsingCookiesForDifferentPurposes")}
      </Typography>
      <Typography paragraph>
        {t("Pages.Contact.ConfirmationAboutUsingCookies")}
      </Typography>
      <Typography paragraph>{t("Pages.Contact.HowToAvoidCookies")}</Typography>
      <Typography paragraph variant="subtitle1">{t("Pages.Contact.GeneralDataValidation")}</Typography>
      <Typography variant="body1">
          {t("CO2Calculator.GeneralDescriptionText1")}
        </Typography>
        <Typography>{t("CO2Calculator.GeneralDescriptionText2")}</Typography>
        <Typography paragraph>
          {t("CO2Calculator.GeneralDescriptionText3")}
        </Typography>

        <Typography paragraph>
          {t("CO2Calculator.GeneralDescriptionText4")}
        </Typography>
        <Typography paragraph>
          {t("CO2Calculator.GeneralDescriptionText5")}
        </Typography>
    </Container>
  );
};
export default Policy;
