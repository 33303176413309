import React, { Suspense, useLayoutEffect, lazy, useEffect } from "react";
import { Routes, Route, useLocation, useNavigate, Outlet, Navigate } from "react-router-dom";
import StartPage from "../pages/StartPage/StartPage";
import LoadingOverlay from "../components/LoadingOverlay";
import { useUser } from "../actions";
import SchoolsClimatePlan from "../pages/ClimatePlan/SchoolsClimatePlan";
const AdminPanelLazy = lazy(() => import("../pages/AdminPanel/AdminPanel"));
const ContactPageLazy = lazy(() => import("../pages/Contact/ContactPage"));
const ProfilePageLazy = lazy(() => import("../pages/Profile/ProfilePage"));
const ClimatePlanInfoLazy = lazy(() => import("../pages/AboutUs/ClimatePlanInfo"));

const RoutesMap = (): JSX.Element => {
  const [{ user }, actions] = useUser();
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const beforeInstallPromptHandler = (e: Event) => {
    e.preventDefault();
    actions.setPwaOptions(e);
  };

  useEffect(() => {
    const authLocal = async () => {
      if (!(await actions.authorizeLocal())) {
        navigate("/");
        return;
      }
    };

   const user = localStorage.getItem("user");
    if (user){
      authLocal();
    }    

    window.addEventListener("beforeinstallprompt", beforeInstallPromptHandler);

    return () => {
      window.removeEventListener("beforeinstallprompt", beforeInstallPromptHandler);
    };
  }, []);

  useLayoutEffect(() => {
    window.scrollTo({
      behavior: "smooth",
      left: 0,
      top: 0,
    });
  }, [pathname]);

  return (
    <Suspense fallback={<LoadingOverlay loading />}>
      <Routes>
        <Route element={user && <Outlet />}>
          <Route path="/profile" element={<ProfilePageLazy />} />
          <Route path="/co2calculator" element={<SchoolsClimatePlan />} />
          <Route path="/*" element={<Navigate to="/" replace />} />
          <Route path="/admin/*" element={<AdminPanelLazy />} />
          <Route path="/statistics/*" element={<AdminPanelLazy />} />
        </Route>
        {/* <Route element={user && user.UserRole === UserRole.Admin && <Outlet />}>
          <Route path="/admin/*" element={<AdminPanelLazy />} />
        </Route> */}
        <Route path="/validation" element={<ClimatePlanInfoLazy />} />
        <Route index element={<StartPage />} />
        <Route path="/contact" element={<ContactPageLazy />} />
        <Route path="/privacy" element={<ContactPageLazy />} />
      </Routes>
    </Suspense>
  );
};

export default RoutesMap;
