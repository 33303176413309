export const AdminConstants = {
    ADMIN_GET_USERS: "ADMIN_GET_USERS",
    ADMIN_GET_USERS_SUCCEEDED: "ADMIN_GET_USERS_SUCCEEDED",
    ADMIN_GET_USER: "ADMIN_GET_USER",
    ADMIN_GET_USER_SUCCEEDED: "ADMIN_GET_USER_SUCCEEDED",
    ADMIN_REMOVE_USER: "ADMIN_REMOVE_USER",
    ADMIN_REMOVE_USER_SUCCEEDED: "ADMIN_REMOVE_USER_SUCCEEDED",
    ADMIN_ADD_ROLE: "ADMIN_ADD_ROLE",
    ADMIN_REMOVE_ROLE:"ADMIN_REMOVE_ROLE",
    ADMIN_ROLE_OPERATION_SUCCEEDED: "ADMIN_ROLE_OPERATION_SUCCEEDED",
    ADMIN_RESET_PASSWORD: "ADMIN_RESET_PASSWORD",
    ADMIN_RESET_PASSWORD_SUCCEEDED: "ADMIN_RESET_PASSWORD_SUCCEEDED",
    ADMIN_GET_CO2PLAN: "ADMIN_GET_CO2PLAN",
    ADMIN_GET_CO2PLAN_SUCCEEDED: "ADMIN_GET_CO2PLAN_SUCCEEDED",
    ADMIN_OPERATION_FAILED: "ADMIN_OPERATION_FAILED"
}