import { LanguageType } from "../Menu";
import { UserDto } from "../models";

export enum UserState {
  Active = 0,
  Deactivated = 1,
  Removed = 2,
  All = 3,
}

export type UserRating = 0 | 1 | 2 | 3 | 4 | 5;

export default interface User extends UserDto {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
  Language: LanguageType;
  AccessToken: string;
}

// export interface UserInterestModel {
//   LastUpdateTime: Date | string;
//   Interests: UserInterest[];
// }

export interface UserRegistrationDTO {
  Name: string;
  Email: string;
  FKey: string;
  Phone: string | undefined;
  SendNotifications: boolean;
  ReceiveCalls: boolean;
  SendFacebookNotifications: boolean;
  Rating: UserRating;
}

export interface UserProfileDTO {
  Name: string;
  SchoolId: string;
  UnitId: string;
  ClassLabel: string | null;
  Language: LanguageType;
}

export enum UserRole {
  Student = "Student",
  Manager = "Manager",
  Admin = "Administrator",
}
