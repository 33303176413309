import React from "react";
import { Container } from "@mui/material";
import BaseDialog from "../BaseDialog";
import { AuthDialogChildrenProps } from "../AuthDialog";
import RegisterForm from "./RegisterForm";
import { useTranslation } from "react-i18next";

const RegisterDialog = ({
  state,
  open,
  error,
  user,
  showPhoneField,
  showCO2MailField,
  registerUser,
  closeDialog,
  setStep,
  onRegistrationComplete,
  clearUserError,
  getLanguage
}: AuthDialogChildrenProps): JSX.Element => {
  const { t } = useTranslation("translation");

  return (
    <BaseDialog
      titleComponent={t("Base.Dialog.RegisterAtMyhouse")}
      open={open}
      closeDialog={closeDialog}
    >
      <Container maxWidth="xs">
        <RegisterForm
          state={state}
          error={error}
          registerUser={registerUser}
          user={user}
          setStep={setStep}
          showPhoneField={showPhoneField}
          showCO2MailField={showCO2MailField}
          onRegistrationComplete={onRegistrationComplete}
          closeDialog={closeDialog}
          clearUserError={clearUserError}
          getLanguage={getLanguage}
        />
      </Container>
    </BaseDialog>
  );
};

export default RegisterDialog;
