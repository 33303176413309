import { createStore, applyMiddleware, Middleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from ".";
import {
  actionLogger,
} from "./middlewares";
import { UserStoreState } from "./user.reducer";
import { CO2InitialState, CO2StoreState } from "../pages/ClimatePlan/shared/co2.reducer";
import { AdminStoreState } from "./admin.reducer";
import { StatsStoreState } from "./stats.reducer";

export interface ApplicationState {
  user: UserStoreState;
  admin: AdminStoreState;
  co2: CO2StoreState;
  stats: StatsStoreState;
}

const initialState: any = {
  user: {
    user: null,//UserService.getCurrentUser(),
  },
  co2: CO2InitialState,
};

// eslint-disable-next-line @typescript-eslint/ban-types
const middlewares: Middleware<{}, ApplicationState>[] = [
  thunk,
  // signalrConnector,
];

//adding logger for the dev build
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  middlewares.push(actionLogger);
}

const store = createStore(
  rootReducer,
  initialState,
  // (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__(),
  composeWithDevTools(applyMiddleware(...middlewares))
);

export default store;
