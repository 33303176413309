import React, { useEffect, useState } from "react";
import { Grid, List, ListItem, styled, useMediaQuery, useTheme } from "@mui/material";
import { CO2EmissionSourceGroup, CO2EmissionSurvey } from "../CO2Plan";
import ExtendedPieChart from "./ExtendedPieChart";
import { convertToCurency } from "../../../../services/converter.service";
import { GetColor, GetIcon } from "../co2-helpers";
import CarbonFootprintIcon from "./img/klima-icons/Сarbon-footprint.svg";
import { useTranslation } from "react-i18next";

type CO2EmissionChartProps = {
  survey: CO2EmissionSurvey;
  animate: boolean;
  onAnimationEnd: () => void;
  unchangeableBasicEmission: number;
  small?: boolean;
  showLegend?: boolean;
};

export type GroupToShow = {
  Emission: number;
  ShortName: string;
  Tooltip: string;
  Id: string;
  index?: number;
  color?: string;
  legendIcon?: string;
};

const CO2EmissionChartComponent = (props: CO2EmissionChartProps): JSX.Element => {
  const { t } = useTranslation("translation");
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const [groups, setGroups] = useState<GroupToShow[]>([]);

  const GetEmission = (group: CO2EmissionSourceGroup): number => {
    return (
      group.IsPerPerson
        ? // if at least one user selected something
          group.PersonEmissionSources.map((person) => person.SelectedSourceId).join("") !== ""
        : group.SelectedSourceId !== null
    )
      ? group.CO2Emission
      : group.GoalCO2Emission;
  };

  useEffect(() => {
    const flattenedGroups = props.survey.Categories.flatMap((cat) => cat.EmissionSourceGroups);

    const emisionsToUse = flattenedGroups.map((group) => ({
      Id: group.Id,
      ShortName: group.ShortName,
      color: GetColor(group.ShortName),
      legendIcon: GetIcon(group.ShortName),
      Emission: GetEmission(group),
      Tooltip: "",
    }));
    emisionsToUse.push({
      Id: "",
      ShortName: "Unchangeable Basic Emission",
      color: "#cebaff",
      legendIcon: CarbonFootprintIcon,
      Emission: props.unchangeableBasicEmission,
      Tooltip: "ClimatePlan.CO2CalculatorDB.EmissionSourceGroup.BasicPersonEmissionTooltip",
    });
    setGroups(emisionsToUse);
  }, [props.survey, props.unchangeableBasicEmission]);

  return (
    <Grid item container direction="column">
      <Grid item>
        <Grid item style={{ width: 260 }}>
          <ExtendedPieChart
            groups={groups}
            radius={isXs ? 60 : 80}
            withToolTip
            height={isXs ? 200 + groups.length * 10 : 300}
            width="100%"
            pieData={{
              outerRadius: "50%",
              cy: "50%",
            }}
          />
        </Grid>
      </Grid>
      <Grid item container justifyContent="flex-start">
        <StyledList>
          {groups
            .sort((a, b) => a.Emission - b.Emission)
            .reverse()
            .map((item, index) => (
              <ListItem key={item.Id} style={{ width: "max-content", paddingLeft: 0 }}>
                <img
                  src={item.legendIcon}
                  style={{
                    minWidth: 30,
                    width: 30,
                    marginRight: 5,
                    backgroundColor: `${item.color}`,
                    borderRadius: 10,
                  }}
                  key={index}
                  alt={t(item.Tooltip)}
                  title={t(item.Tooltip)}
                />
                {convertToCurency(Number(item.Emission))} kg.
              </ListItem>
            ))}
        </StyledList>
      </Grid>
    </Grid>
  );
};

export default CO2EmissionChartComponent;

const StyledList = styled(List)(() => ({
  display: "flex",
  flexDirection: "column",
  height: 200,
  flexWrap: "wrap",
  minWidth: 260,
}));
