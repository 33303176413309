import React, { useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  styled,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Button,
} from "@mui/material";
import "swiper/css";
import { CO2EmissionSourceGroup } from "../../CO2Plan";
import { useTranslation } from "react-i18next";
import CO2InitialSurveyUpdateGroupComponent from "./CO2InitialSurveyUpdateGroupComponent";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { GetColor, GetIcon } from "../../co2-helpers";
import CheckIcon from "@mui/icons-material/Check";
import { IsGroupFilled } from "../../co2-helpers";
import { useCO2 } from "../../co2plan.actions";

const ButtonStyledItem = styled(Button)(({theme}) => ({
  width: "145px",
  display: "flex",
  justifyContent: "center",
  margin: 4,
  padding: 4,
  minHeight: "auto",
  height: 40,
  color: theme.palette.primary.contrastText,
  border: "1px solid" + theme.palette.secondary.main
    }));

const StyledCheckIcon = styled(CheckIcon)(() => ({
  marginLeft: 4,
  marginBottom: 4,
}));
const StyledCardContent = styled(CardContent)({
  "@media print": {
    display: "none",
  },
});

const StyledStepIcon = styled("div")({
  width: 20,
  minWidth: 20,
  height: 20,
  marginRight: 8,
  display: "flex",
  borderRadius: 4,
  overflow: "hidden",
  justifyContent: "center",
  alignItems: "center",
});

const StyledImg = styled("img")({
  height: "inherit", // needed for safari, it takes height and width from image metadata if "inherit" is not set
  width: "inherit",
});

const StyledArrowButton = styled(Button)({
  padding: "5px",
  minWidth: 40,
  minHeight: 40,
  margin: 5,
});

type CO2InitialSurveyUpdateProps = {
  groups: CO2EmissionSourceGroup[];
  isInitialSurveyOutdated: boolean;
  onSave: () => void;
  isAlertOpen: boolean;
  setIsAlertOpen: React.Dispatch<React.SetStateAction<boolean>>;
  loading?: boolean;
};

const CO2InitialSurveyUpdateComponent = (props: CO2InitialSurveyUpdateProps): JSX.Element => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation("translation");
  const [activeGroupId, setActiveGroupId] = useState<string | null>(null);
  const [reviewedOptions, setReviewedOptions] = useState<string[]>([]);
  const [{ co2Plan }] = useCO2();

  const reviewOption = (groupId: string) => {
    if (!reviewedOptions.includes(groupId)) {
      setReviewedOptions([...reviewedOptions, groupId]);
    }

    setActiveGroupId(groupId);
  };

  const Save = () => {
    const throwAlert = () => {
      if (!props.isAlertOpen) {
        props.setIsAlertOpen(true);
      }
    };

    const activeGroup = props.groups.find((g) => g.Id === activeGroupId);

    if (co2Plan && activeGroup && !IsGroupFilled(activeGroup, co2Plan.PlanPersons, co2Plan.IsMultiSelect)) {
      throwAlert();
      return;
    }

    props.setIsAlertOpen(false);
    activeGroupId ? setActiveGroupId(null) : props.onSave();
  };
  return (
    <>
      <Card variant="outlined" square={false} sx={{ marginBottom: 2 }}>
        <StyledCardContent>
          {!activeGroupId && (
            <Grid container direction="column" mt={2} sx={{ flexWrap: "nowrap" }}>             
                <Typography align="center" variant="h4" paragraph>
                  {t("ClimatePlan.InitialSurveyUpdate.PleaseReviewAnswers")}
                </Typography>
              {props.groups.map((group) => (
                <>
                  {!group.IsCustom && (
                    <Grid container justifyContent={"center"} mb={1} mt={isXs ? 1 : 2} key={group.Id}>
                      <Grid item container xs={12} sm={4} md={3} lg={2} alignItems="center" sx={{ flexWrap: "nowrap" }}>
                        <Typography sx={{ display: "flex", flexWrap: "nowrap" }} variant={isXs ? "body1" : "h6"}>
                          <StyledStepIcon
                            sx={{ backgroundColor: GetColor(group.ShortName), marginTop: isXs ? 0 : 0.5 }}
                          >
                            <StyledImg alt="" src={GetIcon(group.ShortName)} />
                          </StyledStepIcon>
                          {t(group.ShortName)}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        justifyContent={isXs ? "center" : "flex-end"}
                        alignItems="center"
                        xs={12}
                        sm={8}
                        lg={6}
                        xl={4}
                      >
                        <Grid item>
                          <ButtonStyledItem variant="outlined">
                            <Typography sx={{ display: "flex", alignItems: "center" }} variant="body2">
                              {t("ClimatePlan.InitialSurveyUpdate.NothingChanged")}
                              {!reviewedOptions.includes(group.Id) && <StyledCheckIcon color="success" />}
                            </Typography>
                          </ButtonStyledItem>
                        </Grid>
                        <Grid item>
                          <ButtonStyledItem variant="outlined" color="secondary" onClick={() => reviewOption(group.Id)}>
                            <Typography sx={{ display: "flex", alignItems: "center" }} variant="body2">
                              {reviewedOptions.includes(group.Id)
                                ? t("ClimatePlan.InitialSurveyUpdate.OptionsReviewed")
                                : t("ClimatePlan.InitialSurveyUpdate.ReviewOptions")}
                              {reviewedOptions.includes(group.Id) && <StyledCheckIcon color="success" />}
                            </Typography>
                          </ButtonStyledItem>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </>
              ))}
            </Grid>
          )}
          {activeGroupId && (
            <CO2InitialSurveyUpdateGroupComponent
              group={props.groups.find((g) => g.Id === activeGroupId) as CO2EmissionSourceGroup}
              planPersons={co2Plan?.PlanPersons ?? []}
              onFinishEditing={Save}
            />
          )}
        </StyledCardContent>
      </Card>
      <Grid item container justifyContent="center">
        <StyledArrowButton variant="contained" color="success" onClick={Save}>
          {props.loading ? (
            <CircularProgress size={16} sx={{ color: theme.palette.primary.contrastText }} />
          ) : (
            <ArrowForwardIosIcon />
          )}
        </StyledArrowButton>
      </Grid>
    </>
  );
};

export default CO2InitialSurveyUpdateComponent;
