import React from "react";
import { Button, ButtonProps } from "@mui/material";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";

const StyledButton = styled(Button, {
  shouldForwardProp: (props) =>
    props !== "strictWidth" && props !== "inProgress",
})<{
  strictWidth?: boolean;
}>(({ theme, strictWidth }) => ({
  transition: "width 0.5 ease",
  minWidth: strictWidth ? "78px" : "auto",
  maxWidth: strictWidth ? "78px" : "auto",
  height: strictWidth ? "27px" : "auto",

  "& .span": {
    padding: "auto 10px",
  },
}));

const ButtonTypoghraphy = styled("p", {
  shouldForwardProp: (props) => props !== "loading" && props !== "strictWidth",
})<{ loading?: boolean; strictWidth?: boolean }>(
  ({ theme, loading, strictWidth }) => ({
    margin: 0,
    paddingRight: 0,
    whiteSpace: "nowrap",
    marginLeft: strictWidth && loading ? "-12px" : "0",
    transform: strictWidth && loading ? "scale(0.7, 1)" : "none",
  })
);

interface CircularProgressButtonProps extends ButtonProps {
  inProgress: boolean;
  strictWidth?: boolean;
  circularProgressProps?: CircularProgressProps;
}

const CircularProgressButton = (
  props: CircularProgressButtonProps
): JSX.Element => {
  return (
    <>
      <StyledButton
        disabled={props.inProgress}
        variant="contained"
        strictWidth={props.strictWidth}
        {...props}
      >
        <ButtonTypoghraphy
          loading={props.inProgress}
          strictWidth={props.strictWidth}
        >
          {props.children}
        </ButtonTypoghraphy>
        {props.inProgress && (
          <CircularProgress
            size="small"
            sx={{
              width: props.strictWidth ? "15px" : "20px",
              height: props.strictWidth ? "15px" : "20px",
              marginLeft: props.strictWidth ? "-5px" : "15px",
              marginRight: props.strictWidth ? "-5px" : "0",
            }}
            {...props.circularProgressProps}
          />
        )}
      </StyledButton>
    </>
  );
};
export default CircularProgressButton;
